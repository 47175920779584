define("discourse/plugins/discourse-global-communities/discourse/controllers/tv-tag", ["exports", "@ember/controller", "discourse/lib/decorators", "discourse/models/topic", "discourse-i18n"], function (_exports, _controller, _decorators, _topic, _discourseI18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class TvTagController extends _controller.default {
    topics(unprocessedTopics) {
      return unprocessedTopics.map(topic => {
        return _topic.default.create(topic);
      });
    }
    static #_ = (() => dt7948.n(this.prototype, "topics", [(0, _decorators.default)("model.topics")]))();
    title(label, showingNew) {
      return showingNew ? (0, _discourseI18n.i18n)("agc.tv.new_and_unwatched") : label;
    }
    static #_2 = (() => dt7948.n(this.prototype, "title", [(0, _decorators.default)("model.label", "model.new")]))();
  }
  _exports.default = TvTagController;
});