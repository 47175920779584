define("discourse/plugins/discourse-global-communities/discourse/lib/helpers/is-homepage", ["exports", "discourse/lib/utilities"], function (_exports, _utilities) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.isHomepage = void 0;
  /**
   * Return true if either the URL matches homepage URL, or the route matches defaultHomepage
   *
   * defaultHomepage uses <meta name='discourse_current_homepage'> to get the default homepage route.
   * For AGC instances, it is currently set to 'categories'.
   *
   * @param {Object} router
   * @returns {boolean}
   */
  const isHomepage = router => {
    // Setting a fallback that will be ignored if defaultHomepage returns a value
    const defaultRoute = (0, _utilities.defaultHomepage)() || "categories";
    const isRouteMatched = router?.currentRoute?.localName === defaultRoute;
    const isUrlMatched = router?.currentURL?.split("?")[0] === "/";
    return isRouteMatched || isUrlMatched;
  };
  _exports.isHomepage = isHomepage;
});