define("discourse/plugins/discourse-global-communities/discourse/routes/tv-index", ["exports", "discourse/lib/ajax", "discourse/models/topic", "discourse/routes/discourse"], function (_exports, _ajax, _topic, _discourse) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class TvIndex extends _discourse.default {
    model() {
      return (0, _ajax.ajax)("/tv.json").then(result => {
        if (result.featured_topic) {
          result.featured_topic = _topic.default.create(result.featured_topic);
        }
        return result;
      });
    }
    titleToken() {
      return "TV";
    }
    setupController(controller, model) {
      super.setupController(controller, model);
    }
  }
  _exports.default = TvIndex;
});