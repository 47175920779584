define("discourse/plugins/discourse-global-communities/discourse/components/agc/ai-summary/sidebar", ["exports", "@glimmer/component", "@ember/service", "discourse/components/d-button", "discourse-i18n", "discourse/plugins/discourse-global-communities/discourse/components/agc/ai-summary/content", "@ember/component", "@ember/template-factory"], function (_exports, _component, _service, _dButton, _discourseI18n, _content, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class Sidebar extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "agcAiSummary", [_service.service]))();
    #agcAiSummary = (() => (dt7948.i(this, "agcAiSummary"), void 0))();
    static #_2 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <div id="ai-summary-sidebar">
          <div class="ai-summary-sidebar__header">
            <h3>{{i18n "agc.ai.post_summary"}}</h3>
            <DButton
              @icon="xmark"
              @action={{this.agcAiSummary.closeSheet}}
              class="no-text btn-icon btn-transparent close-button"
            />
          </div>
          <div class="ai-summary-sidebar__container">
            <Content
              @summary={{@summary}}
              @summaryNotAvailable={{@summaryNotAvailable}}
            />
          </div>
        </div>
      
    */
    {
      "id": "C7NY5EOx",
      "block": "[[[1,\"\\n    \"],[10,0],[14,1,\"ai-summary-sidebar\"],[12],[1,\"\\n      \"],[10,0],[14,0,\"ai-summary-sidebar__header\"],[12],[1,\"\\n        \"],[10,\"h3\"],[12],[1,[28,[32,0],[\"agc.ai.post_summary\"],null]],[13],[1,\"\\n        \"],[8,[32,1],[[24,0,\"no-text btn-icon btn-transparent close-button\"]],[[\"@icon\",\"@action\"],[\"xmark\",[30,0,[\"agcAiSummary\",\"closeSheet\"]]]],null],[1,\"\\n      \"],[13],[1,\"\\n      \"],[10,0],[14,0,\"ai-summary-sidebar__container\"],[12],[1,\"\\n        \"],[8,[32,2],null,[[\"@summary\",\"@summaryNotAvailable\"],[[30,1],[30,2]]],null],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[\"@summary\",\"@summaryNotAvailable\"],false,[]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-global-communities/discourse/components/agc/ai-summary/sidebar.js",
      "scope": () => [_discourseI18n.i18n, _dButton.default, _content.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = Sidebar;
});