define("discourse/plugins/discourse-global-communities/discourse/initializers/agc", ["exports", "@ember/runloop", "@ember/service", "@ember/string", "jquery", "discourse/lib/decorators", "discourse/lib/get-url", "discourse/lib/notification-levels", "discourse/lib/plugin-api", "discourse/lib/render-tag", "discourse/models/nav-item", "discourse-i18n", "discourse/plugins/discourse-global-communities/discourse/helpers/sort-user-dropdown", "discourse/plugins/discourse-global-communities/discourse/helpers/urlstrip", "discourse/plugins/discourse-global-communities/discourse/lib/agc-search"], function (_exports, _runloop, _service, _string, _jquery, _decorators, _getUrl, _notificationLevels, _pluginApi, _renderTag, _navItem, _discourseI18n, _sortUserDropdown, _urlstrip, _agcSearch) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    name: "agc-base",
    _urlToCustomCategory(router, category, params, excludedParams) {
      let queryParams = router.currentRoute.queryParams;
      (excludedParams || []).forEach(param => {
        delete queryParams[param];
      });
      queryParams = Object.assign(queryParams, params);
      return router.urlFor(router.currentRouteName, category, {
        queryParams
      });
    },
    initialize(container) {
      const htmlEl = document.documentElement;
      htmlEl.classList.add("not-amzl");

      // Prevent right-click menu on videos
      (0, _jquery.default)("body").on("contextmenu", "video", function () {
        return false;
      });
      const site = container.lookup("service:site");
      if (site && site.mobileView) {
        window.addEventListener("beforeinstallprompt", e => {
          // Prevent mini-infobar from showing on mobile
          // Ignite uses an app prompt instead
          e.preventDefault();
        });
      }
      (0, _renderTag.replaceTagRenderer)((tag, params) => {
        let defaultTag = (0, _renderTag.defaultRenderTag)(tag, params);
        if (params.includeAgcAnalyticsRef) {
          const tmp = document.createElement("div");
          tmp.innerHTML = defaultTag;
          const tagElement = tmp.querySelector("a");
          const tagName = tagElement.dataset.tagName.toLowerCase();
          tagElement.setAttribute("data-agc-analytics-ref", `nav_sidebar_popular_tag_${tagName}`);
          defaultTag = tmp.innerHTML;
        }
        return defaultTag;
      });
      (0, _pluginApi.withPluginApi)("0.8.31", api => {
        (0, _navItem.customNavItemHref)(navItem => {
          const name = navItem.name;
          if (name === "top" || name === "latest") {
            const router = container.lookup("service:router");
            const currentRoute = router.currentRoute;
            const category = currentRoute.attributes.category;
            if (category) {
              const queryParams = currentRoute.queryParams;
              queryParams.solved = null;
              queryParams.state = null;
              return router.urlFor(`discovery.${name}Category`, category, {
                queryParams
              });
            }
          }
          return null;
        });

        // Strip urls from suggested topics on mobile
        api.modifyClass("component:basic-topic-list", Superclass => class extends Superclass {
          static #_ = (() => dt7948.g(this.prototype, "messageBus", [_service.service]))();
          #messageBus = (() => (dt7948.i(this, "messageBus"), void 0))();
          didInsertElement() {
            super.didInsertElement(...arguments);
            this.topics.forEach(topic => {
              const includeUnreadIndicator = typeof topic.unread_by_group_member !== "undefined";
              if (includeUnreadIndicator) {
                const unreadIndicatorChannel = `/private-messages/unread-indicator/${topic.id}`;
                this.messageBus.subscribe(unreadIndicatorChannel, data => {
                  const nodeClassList = document.querySelector(`.indicator-topic-${data.topic_id}`).classList;
                  nodeClassList.toggle("read", !data.show_indicator);
                });
              }
              if (topic.excerpt?.length) {
                let stripped = (0, _urlstrip.strip)(topic.excerpt);
                if (stripped.string?.length) {
                  topic.set("excerpt", stripped.string);
                }
              }
            });
          }
        });
        api.modifyClass("component:search-result-entry", Superclass => class extends Superclass {
          contentTypeSearchLink(gcContentType) {
            const searchTerm = (0, _agcSearch.contentTypeToSearchFilter)(gcContentType);
            return `/search?q=${searchTerm}`;
          }
          static #_ = (() => dt7948.n(this.prototype, "contentTypeSearchLink", [(0, _decorators.default)("post.topic.gc_content_type")]))();
        });
        api.modifyClass("controller:user", Superclass => class extends Superclass {
          pronoun() {
            const field = this.publicUserFields && this.publicUserFields.find(row => row.field.name.toLowerCase() === "pronoun");
            return field ? field.value : null;
          }
          static #_ = (() => dt7948.n(this.prototype, "pronoun", [(0, _decorators.default)("model.user_fields.@each.value")]))();
          usernameWithPronoun(username, pronoun) {
            if (pronoun) {
              return `${username} (${pronoun})`;
            }
            return username;
          }
          static #_2 = (() => dt7948.n(this.prototype, "usernameWithPronoun", [(0, _decorators.default)("model.username", "pronoun")]))();
          nameWithPronoun(name, pronoun) {
            if (pronoun) {
              return `${name} (${pronoun})`;
            }
            return name;
          }
          static #_3 = (() => dt7948.n(this.prototype, "nameWithPronoun", [(0, _decorators.default)("model.name", "pronoun")]))();
        });
        api.modifyClass("component:categories-topic-list", Superclass => class extends Superclass {
          static #_ = (() => dt7948.g(this.prototype, "session", [_service.service]))();
          #session = (() => (dt7948.i(this, "session"), void 0))();
          didInsertElement() {
            super.didInsertElement(...arguments);
            const debouncedScroll = () => (0, _runloop.debounce)(this, this.saveScrollPosition, 100);
            (0, _jquery.default)(window).bind("scroll.categories-topic-list", debouncedScroll);
            const scrollTo = this.session.get("categoryTopicListScrollPosition");
            if (scrollTo && scrollTo >= 0) {
              (0, _runloop.schedule)("afterRender", () => (0, _jquery.default)(window).scrollTop(scrollTo));
            }
          }
          willDestroyElement() {
            super.willDestroyElement(...arguments);
            (0, _jquery.default)(window).unbind("scroll.categories-topic-list");
          }
          saveScrollPosition() {
            this.session.set("categoryTopicListScrollPosition", (0, _jquery.default)(window).scrollTop());
          }
        });
        api.modifyClass("component:user-fields/dropdown", Superclass => class extends Superclass {
          sortedOptions(opts) {
            if (this.field.name === "DSP Since") {
              return opts.reverse();
            }

            // sort options for Station Location and Station custom user fields
            if (!["Station Location", "Station"].includes(this.field.name)) {
              return this.field.options;
            }
            return (0, _sortUserDropdown.default)(opts);
          }
          static #_ = (() => dt7948.n(this.prototype, "sortedOptions", [(0, _decorators.default)("field.options")]))();
          translatedName(name) {
            const scope = `agc.user_fields.${(0, _string.underscore)(name)}.name`;
            if (_discourseI18n.default.lookup(scope)) {
              return (0, _discourseI18n.i18n)(scope);
            } else {
              return name;
            }
          }
          static #_2 = (() => dt7948.n(this.prototype, "translatedName", [(0, _decorators.default)("field.name")]))();
          translatedDescription(name, description) {
            const scope = `agc.user_fields.${(0, _string.underscore)(name)}.description`;
            if (_discourseI18n.default.lookup(scope)) {
              return (0, _discourseI18n.i18n)(scope, {
                basePath: (0, _getUrl.default)("")
              });
            } else {
              return description;
            }
          }
          static #_3 = (() => dt7948.n(this.prototype, "translatedDescription", [(0, _decorators.default)("field.name", "field.description")]))();
        });
        api.modifyClass("component:user-fields/text", Superclass => class extends Superclass {
          translatedDescription(name, description) {
            const scope = `agc.user_fields.${(0, _string.underscore)(name)}.description`;
            if (_discourseI18n.default.lookup(scope)) {
              return (0, _discourseI18n.i18n)(scope, {
                basePath: (0, _getUrl.default)("")
              });
            } else {
              return description;
            }
          }
          static #_ = (() => dt7948.n(this.prototype, "translatedDescription", [(0, _decorators.default)("field.name", "field.description")]))();
          translatedName(name) {
            const scope = `agc.user_fields.${(0, _string.underscore)(name)}.name`;
            if (_discourseI18n.default.lookup(scope)) {
              return (0, _discourseI18n.i18n)(scope);
            } else {
              return name;
            }
          }
          static #_2 = (() => dt7948.n(this.prototype, "translatedName", [(0, _decorators.default)("field.name")]))();
        });
        api.modifyClass("component:user-fields/confirm", Superclass => class extends Superclass {
          translatedDescription(name, description) {
            const scope = `agc.user_fields.${(0, _string.underscore)(name)}.description`;
            if (_discourseI18n.default.lookup(scope)) {
              return (0, _discourseI18n.i18n)(scope, {
                basePath: (0, _getUrl.default)("")
              });
            } else {
              return description;
            }
          }
          static #_ = (() => dt7948.n(this.prototype, "translatedDescription", [(0, _decorators.default)("field.name", "field.description")]))();
          translatedName(name) {
            const scope = `agc.user_fields.${(0, _string.underscore)(name)}.name`;
            if (_discourseI18n.default.lookup(scope)) {
              return (0, _discourseI18n.i18n)(scope);
            } else {
              return name;
            }
          }
          static #_2 = (() => dt7948.n(this.prototype, "translatedName", [(0, _decorators.default)("field.name")]))();
        });

        // move watching_first_post to top of the list
        api.modifyClass("component:notifications-button", Superclass => class extends Superclass {
          static #_ = (() => dt7948.g(this.prototype, "agcTopicListWatching", [_service.service]))();
          #agcTopicListWatching = (() => (dt7948.i(this, "agcTopicListWatching"), void 0))();
          static #_2 = (() => dt7948.g(this.prototype, "currentUser", [_service.service]))();
          #currentUser = (() => (dt7948.i(this, "currentUser"), void 0))();
          static #_3 = (() => dt7948.g(this.prototype, "siteSettings", [_service.service]))();
          #siteSettings = (() => (dt7948.i(this, "siteSettings"), void 0))();
          didInsertElement() {
            super.didInsertElement(...arguments);

            // We want to check if the current user is the author of the first post and if so,
            // we add a class to the dropdown to hide it. It's a hacky way to hide, but works.
            if (this.currentUser?.id === this.topic?.user_id) {
              this.element.classList.add("hidden");
            }
            const reorderedContent = this.content.sort(a => {
              if (a.id === _notificationLevels.NotificationLevels.WATCHING_FIRST_POST) {
                return -1;
              }
              return 0;
            });
            this.set("content", reorderedContent);
          }
          onOpen() {
            // We show a button with bell icon and either
            // `Notify` or `Notified`, depending on topic tracking state. Clicking toggles
            // between regular and watching states
            const watchingId = this.content.find(notification => notification.key === "watching").id;
            const regularId = this.content.find(notification => notification.key === "regular").id;
            const newOption = this.value !== watchingId ? watchingId : regularId;
            this.agcWatchingService.showToast({
              notified: newOption === watchingId
            });
            return this._onChangeWrapper(newOption, this.content[newOption]);
          }
        });
        api.modifyClass("component:user-card-contents", Superclass => class extends Superclass {
          static #_ = (() => dt7948.g(this.prototype, "appEvents", [_service.service]))();
          #appEvents = (() => (dt7948.i(this, "appEvents"), void 0))();
          _show() {
            const result = super._show(...arguments);
            if (result === undefined) {
              localStorage.lastAgcAnalyticsRef = "user_avatar";
            }
            return result;
          }
          _showCallback(username) {
            this.appEvents.trigger("enhanced_user_analytics:event", "user_avatar_click", {
              username
            }, {
              clickRef: "user_avatar"
            });
            return super._showCallback(...arguments);
          }
        });
        api.modifyClass("controller:preferences/tracking", Superclass => class extends Superclass {
          static #_ = (() => dt7948.g(this.prototype, "currentUser", [_service.service]))();
          #currentUser = (() => (dt7948.i(this, "currentUser"), void 0))();
          static #_2 = (() => dt7948.g(this.prototype, "siteSettings", [_service.service]))();
          #siteSettings = (() => (dt7948.i(this, "siteSettings"), void 0))();
          init() {
            super.init(...arguments);
            return this.notificationLevelsForReplying = [{
              name: (0, _discourseI18n.i18n)("notification_levels.normal"),
              value: _notificationLevels.NotificationLevels.REGULAR
            }, {
              name: (0, _discourseI18n.i18n)("topic.notifications.tracking.title"),
              value: _notificationLevels.NotificationLevels.TRACKING
            }];
          }
        });
        api.modifyClass("component:category-notifications-button", Superclass => class extends Superclass {
          modifySelection(content) {
            content = super.modifySelection(...arguments);
            content.label = content.key === "tracking" ? (0, _discourseI18n.i18n)("agc.unfollow") : (0, _discourseI18n.i18n)("agc.follow");
            return content;
          }
        });
        api.modifyClass("component:tag-notifications-button", Superclass => class extends Superclass {
          modifySelection(content) {
            content = super.modifySelection(...arguments);
            content.label = content.key === "tracking" ? (0, _discourseI18n.i18n)("agc.unfollow") : (0, _discourseI18n.i18n)("agc.follow");
            return content;
          }
        });
        api.onPageChange(() => {
          let $navBar = (0, _jquery.default)("#navigation-bar");
          if (!$navBar) {
            return;
          }
          $navBar.find("a").each(function () {
            let $this = (0, _jquery.default)(this);
            $this.attr("data-agc-analytics-ref", "nav_topic_list_tab_" + $this.attr("href").replace("/", ""));
          });
        });
      });
    }
  };
});