define("discourse/plugins/discourse-global-communities/discourse/services/agc-ai-summary", ["exports", "@glimmer/tracking", "@ember/object", "@ember/runloop", "@ember/service", "discourse/plugins/discourse-global-communities/discourse/components/agc/ai-summary/disclaimer-modal"], function (_exports, _tracking, _object, _runloop, _service, _disclaimerModal) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const AI_SUMMARY_SHEET_OPEN_CLASS = "agc-ai-summary-sheet-wrapper-open";
  const AI_SUMMARY_SHEET_LAST_TOPIC_ID = "agc-ai-summary-sheet-last-topic-id";
  const AI_SUMMARY_DISCLAIMER_CONFIRMED = "agc-ai-summary-disclaimer-confirmed"; // if you change this, change the disclaimer modal too
  // see snapHeights below for snap points
  const DEFAULT_SHEET_HEIGHT = "50%";
  const MIN_HEIGHT_RATIO = 0.15;
  const MAX_HEIGHT_RATIO = 0.8;
  class AgcAiSummaryService extends _service.default {
    static #_ = (() => dt7948.g(this.prototype, "site", [_service.service]))();
    #site = (() => (dt7948.i(this, "site"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "modal", [_service.service]))();
    #modal = (() => (dt7948.i(this, "modal"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "lastTopicId", [_tracking.tracked], function () {
      return parseInt(localStorage.getItem(AI_SUMMARY_SHEET_LAST_TOPIC_ID), 10);
    }))();
    #lastTopicId = (() => (dt7948.i(this, "lastTopicId"), void 0))();
    static #_4 = (() => dt7948.g(this.prototype, "open", [_tracking.tracked], function () {
      return this.lastTopicId;
    }))();
    #open = (() => (dt7948.i(this, "open"), void 0))();
    static #_5 = (() => dt7948.g(this.prototype, "bottomSheet", [_tracking.tracked]))();
    #bottomSheet = (() => (dt7948.i(this, "bottomSheet"), void 0))(); // default to open the sheet if there is a last topic id
    static #_6 = (() => dt7948.g(this.prototype, "sheetHeight", [_tracking.tracked], function () {
      return localStorage.getItem("agc-ai-summary-sheet-height") || DEFAULT_SHEET_HEIGHT;
    }))();
    #sheetHeight = (() => (dt7948.i(this, "sheetHeight"), void 0))();
    constructor() {
      super(...arguments);
      if (this.open) {
        document.body.classList.add(AI_SUMMARY_SHEET_OPEN_CLASS);

        // only set bottomSheet height if mobile view
        if (this.site.mobileView) {
          this.fetchAndSetBottomSheetHeight();
        }
      }
    }
    fetchAndSetBottomSheetHeight() {
      // Wait for the sheet to render before setting the height
      (0, _runloop.schedule)("afterRender", () => {
        this.bottomSheet = document.getElementById("agc-ai-summary-bottom-sheet");
        this.bottomSheet.style.height = this.sheetHeight;
      });
    }
    static #_7 = (() => dt7948.n(this.prototype, "fetchAndSetBottomSheetHeight", [_object.action]))();
    openAiSummarySheet(topicId) {
      // Show disclaimer modal on first open
      if (localStorage.getItem(AI_SUMMARY_DISCLAIMER_CONFIRMED) !== "true") {
        this.modal.show(_disclaimerModal.default);
      }
      this.open = true;
      this.lastTopicId = topicId;
      localStorage.setItem(AI_SUMMARY_SHEET_LAST_TOPIC_ID, topicId);
      document.body.classList.add(AI_SUMMARY_SHEET_OPEN_CLASS);

      // only set bottomSheet height if mobile view
      if (this.site.mobileView) {
        this.fetchAndSetBottomSheetHeight();
      }
    }
    static #_8 = (() => dt7948.n(this.prototype, "openAiSummarySheet", [_object.action]))();
    closeSheet() {
      this.open = false;
      this.lastTopicId = null;
      localStorage.setItem(AI_SUMMARY_SHEET_LAST_TOPIC_ID, null);
      document.body.classList.remove(AI_SUMMARY_SHEET_OPEN_CLASS);
      if (this.site.mobileView) {
        this.bottomSheet = null;
      }
    }
    static #_9 = (() => dt7948.n(this.prototype, "closeSheet", [_object.action]))();
    dragMove(e) {
      // Get the current window height
      const windowHeight = window.innerHeight;
      const minHeight = windowHeight * MIN_HEIGHT_RATIO;
      const maxHeight = windowHeight * MAX_HEIGHT_RATIO;

      // Get clientY from mouse or touch event
      const clientY = e.touches ? e.touches[0].clientY : e.clientY;

      // Calculate new height (from bottom of screen to touch point)
      const newHeight = windowHeight - clientY;

      // Constrain height between min and max values
      const constrainedHeight = Math.max(minHeight, Math.min(maxHeight, newHeight));

      // Apply the new height
      this.bottomSheet.style.height = `${constrainedHeight}px`;
    }
    static #_10 = (() => dt7948.n(this.prototype, "dragMove", [_object.action]))();
    didEndDrag() {
      // Get current height
      const currentHeight = parseFloat(this.bottomSheet.style.height);
      const windowHeight = window.innerHeight;

      // Get the header height to use as minimum snap point
      const headerElement = this.bottomSheet.querySelector(".bottom-sheet__header");
      const headerHeight = headerElement ? headerElement.offsetHeight : windowHeight * MIN_HEIGHT_RATIO;

      // Get header offset so that on 100% height, we don't cover the header
      const headerOffset = parseFloat(getComputedStyle(document.documentElement).getPropertyValue("--header-offset")) || 0;

      // Calculate snap heights with header height as minimum
      const snapHeights = [headerHeight,
      // Minimum snap is header height
      windowHeight * 0.5,
      // Middle snap at 50%
      windowHeight - headerOffset // Full height snap
      ];

      // Find the closest snap point
      let closestSnapHeight = snapHeights[0];
      let minDistance = Math.abs(currentHeight - snapHeights[0]);
      for (let i = 1; i < snapHeights.length; i++) {
        const distance = Math.abs(currentHeight - snapHeights[i]);
        if (distance < minDistance) {
          minDistance = distance;
          closestSnapHeight = snapHeights[i];
        }
      }

      // Apply the snap height
      this.bottomSheet.style.height = `${closestSnapHeight}px`;

      // Save the current height to localStorage and tracked property
      this.sheetHeight = this.bottomSheet.style.height;
      localStorage.setItem("agc-ai-summary-sheet-height", this.sheetHeight);
    }
    static #_11 = (() => dt7948.n(this.prototype, "didEndDrag", [_object.action]))();
  }
  _exports.default = AgcAiSummaryService;
});