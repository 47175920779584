define("discourse/plugins/discourse-global-communities/discourse/feature-flags/agc-ai-summary", ["exports", "discourse/widgets/render-glimmer", "discourse/plugins/discourse-global-communities/discourse/components/agc/ai-summary/sheet-wrapper", "discourse/plugins/discourse-global-communities/discourse/components/agc/ai-summary/summarize-button", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _renderGlimmer, _sheetWrapper, _summarizeButton, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AgcAiSummaryFeatureFlag {
    static enabledKey = "agc_ai_summary_enabled";
    static className = "agc-ai-summary-enabled";
    static afterEnabled(api) {
      const currentUser = api.getCurrentUser();
      if (!currentUser?.can_summarize) {
        return;
      }
      api.registerValueTransformer("post-menu-buttons", _ref => {
        let {
          value: dag,
          context: {
            post,
            firstButtonKey
          }
        } = _ref;
        if (post.firstPost) {
          dag.add("agc-ai-summarization", _summarizeButton.default, {
            before: firstButtonKey
          });
        }
      });
      api.decorateWidget("post-stream:after", helper => {
        return new _renderGlimmer.default(helper.widget, "div.post-stream-ai-summary-wrapper", (0, _component.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
        /*
          
                  <SummarizeButton @topic={{@data.topic}} @position="end-of-post" />
                
        */
        {
          "id": "Kx1oXBh9",
          "block": "[[[1,\"\\n          \"],[8,[32,0],null,[[\"@topic\",\"@position\"],[[30,1,[\"topic\"]],\"end-of-post\"]],null],[1,\"\\n        \"]],[\"@data\"],false,[]]",
          "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-global-communities/discourse/feature-flags/agc-ai-summary.js",
          "scope": () => [_summarizeButton.default],
          "isStrictMode": true
        }), (0, _templateOnly.default)(undefined, undefined)), {
          // we have two different ways to get the topic model
          // the first is for desktop, the second is for mobile
          topic: helper.attrs.posts.posts?.[0]?.topic || helper.attrs.posts[0].topic
        });
      });
      const topicController = api.container.lookup("controller:topic");
      const site = api.container.lookup("service:site");
      const agcAiSummary = api.container.lookup("service:agc-ai-summary");
      const outletKey = site.mobileView ? "above-footer" : "before-main-outlet";
      api.renderInOutlet(outletKey, (0, _component.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
      /*
        
            {{#if topicController.model}}
              <SheetWrapper @topic={{topicController.model}} />
            {{/if}}
          
      */
      {
        "id": "/thLvDQG",
        "block": "[[[1,\"\\n\"],[41,[32,0,[\"model\"]],[[[1,\"        \"],[8,[32,1],null,[[\"@topic\"],[[32,0,[\"model\"]]]],null],[1,\"\\n\"]],[]],null],[1,\"    \"]],[],false,[\"if\"]]",
        "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-global-communities/discourse/feature-flags/agc-ai-summary.js",
        "scope": () => [topicController, _sheetWrapper.default],
        "isStrictMode": true
      }), (0, _templateOnly.default)(undefined, undefined)));
      api.onPageChange(() => {
        if (agcAiSummary.open && topicController.model?.id !== agcAiSummary.lastTopicId) {
          agcAiSummary.closeSheet();
        }
      });
      // Attach analytics to links inside summary
      const agcAnalyticsApi = api.agcAnalytics;
      agcAnalyticsApi.registerClickAnalytics(".generated-summary a", {
        eventType: "ai-summary:content-link-clicked",
        eventData: element => {
          return {
            href: element.href,
            text: element.text,
            topic_id: agcAiSummary.lastTopicId,
            user_id: currentUser.id
          };
        }
      });
      const appEvents = api.container.lookup("service:app-events");
      // close the sheet when the composer is opened
      appEvents.on("composer:will-open", () => {
        agcAiSummary.closeSheet();
      });
    }
  }
  _exports.default = AgcAiSummaryFeatureFlag;
});