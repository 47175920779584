define("discourse/plugins/discourse-global-communities/discourse/feature-flags/escalation-notes", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class EscalationNotesFeatureFlag {
    static enabledKey = "escalation_notes_enabled";
    static afterEnabled(api) {
      const agcAnalyticsApi = api.agcAnalytics;
      if (agcAnalyticsApi) {
        agcAnalyticsApi.registerClickAnalytics(".view-escalation-link", {
          eventType: "escalations:view-link-clicked",
          eventData: element => {
            const wrapper = element.closest(".escalations");
            if (wrapper) {
              const topicId = wrapper.dataset.topicId;
              return {
                topic_id: topicId,
                escalation_href: element.href
              };
            }
          }
        });
      }
    }
  }
  _exports.default = EscalationNotesFeatureFlag;
});