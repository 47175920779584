define("discourse/plugins/discourse-global-communities/discourse/templates/connectors/homepage-topic-list-navigation/feed-toggle", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if this.shouldRender}}
    <FeedToggle @outletArgs={{this.outletArgs}} />
  {{/if}}
  */
  {
    "id": "v9bTjckS",
    "block": "[[[41,[30,0,[\"shouldRender\"]],[[[1,\"  \"],[8,[39,1],null,[[\"@outletArgs\"],[[30,0,[\"outletArgs\"]]]],null],[1,\"\\n\"]],[]],null]],[],false,[\"if\",\"feed-toggle\"]]",
    "moduleName": "discourse/plugins/discourse-global-communities/discourse/templates/connectors/homepage-topic-list-navigation/feed-toggle.hbs",
    "isStrictMode": false
  });
});