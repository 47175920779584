define("discourse/plugins/discourse-global-communities/discourse/services/agc-search-page-analytics", ["exports", "@glimmer/tracking", "@ember/service", "discourse/plugins/discourse-global-communities/discourse/lib/agc-user-session"], function (_exports, _tracking, _service, _agcUserSession) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // This service is used to store analytics values that an initializer uses. It's a lil' cache.
  class AgcSearchPageAnalyticsService extends _service.default {
    static #_ = (() => dt7948.g(this.prototype, "currentUser", [_service.service]))();
    #currentUser = (() => (dt7948.i(this, "currentUser"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "viewSequence", [_tracking.tracked], function () {
      return 0;
    }))();
    #viewSequence = (() => (dt7948.i(this, "viewSequence"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "lastClickedIndex", [_tracking.tracked], function () {
      return null;
    }))();
    #lastClickedIndex = (() => (dt7948.i(this, "lastClickedIndex"), void 0))();
    static #_4 = (() => dt7948.g(this.prototype, "lastClickedResultType", [_tracking.tracked], function () {
      return "REGULAR";
    }))();
    #lastClickedResultType = (() => (dt7948.i(this, "lastClickedResultType"), void 0))();
    static #_5 = (() => dt7948.g(this.prototype, "aiResultToggled", [_tracking.tracked], function () {
      return false;
    }))();
    #aiResultToggled = (() => (dt7948.i(this, "aiResultToggled"), void 0))();
    static #_6 = (() => dt7948.g(this.prototype, "searchSessionId", [_tracking.tracked]))();
    #searchSessionId = (() => (dt7948.i(this, "searchSessionId"), void 0))();
    constructor() {
      super(...arguments);
      this.resetSearchSession();
    }
    get searchSessionValue() {
      const agcUserSession = _agcUserSession.default.current();
      return `${agcUserSession._sessionId()}-${this.searchSessionId}`;
    }
    resetSearchSession() {
      const currentId = parseInt(localStorage.getItem("agc_search_session_id"), 10) || 0;
      this.searchSessionId = currentId + 1;
      localStorage.setItem("agc_search_session_id", this.searchSessionId);
      this.viewSequence = 0;
    }
    incrementViewSequence() {
      this.viewSequence++;
    }
    resetWhenClickRecord() {
      this.lastClickedResultType = "REGULAR";
    }
    recordAiResultWhenClick() {
      this.lastClickedResultType = "AI";
    }
  }
  _exports.default = AgcSearchPageAnalyticsService;
});