define("discourse/plugins/discourse-global-communities/discourse/components/feed-toggle", ["exports", "@ember/component", "@glimmer/component", "@glimmer/tracking", "@ember/object", "@ember/service", "discourse/plugins/discourse-global-communities/discourse/config/app-events-config", "discourse/plugins/discourse-global-communities/discourse/lib/helpers/analytics/analytics-annotator", "discourse/plugins/discourse-global-communities/discourse/lib/helpers/config/feed-toggle-config", "@ember/template-factory"], function (_exports, _component, _component2, _tracking, _object, _service, _appEventsConfig, _analyticsAnnotator, _feedToggleConfig, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <AgcToggle
    @choice={{this.choice}}
    @options={{this.options}}
    @toggleChangeHandler={{this.toggleChangeHandler}}
  />
  */
  {
    "id": "7kH32U5z",
    "block": "[[[8,[39,0],null,[[\"@choice\",\"@options\",\"@toggleChangeHandler\"],[[30,0,[\"choice\"]],[30,0,[\"options\"]],[30,0,[\"toggleChangeHandler\"]]]],null]],[],false,[\"agc-toggle\"]]",
    "moduleName": "discourse/plugins/discourse-global-communities/discourse/components/feed-toggle.hbs",
    "isStrictMode": false
  });
  /* global cwr */

  class FeedToggle extends _component2.default {
    static #_ = (() => dt7948.g(this.prototype, "appEvents", [_service.service]))();
    #appEvents = (() => (dt7948.i(this, "appEvents"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "choice", [_tracking.tracked]))();
    #choice = (() => (dt7948.i(this, "choice"), void 0))();
    options = (() => _feedToggleConfig.FEED_TOGGLE_CONFIG.options)();
    onNavigate;
    constructor(owner, args) {
      super(owner, args);
      this.onNavigate = args.outletArgs.onNavigate;
      const activeFilter = args.outletArgs.activeFilter;
      if (_feedToggleConfig.FEED_TOGGLE_CONFIG.options?.some(option => option.id === activeFilter)) {
        this.choice = activeFilter;
      } else {
        this.choice = _feedToggleConfig.FEED_TOGGLE_CONFIG.ids.TOP;
      }
      this.appEvents.on(_appEventsConfig.FEED_SORT_UPDATE, this, this.updateSelection);
    }
    willDestroy() {
      super.willDestroy(...arguments);
      this.appEvents.off(_appEventsConfig.FEED_SORT_UPDATE, this, this.updateSelection);
    }
    async toggleChangeHandler(event) {
      const selection = event.target.id;
      await this.updateSelection({
        eventId: event.target.id
      });
      const startOnNavigate = Date.now();
      await this.onNavigate(selection);
      const endOnNavigate = Date.now();
      const feedLoad = endOnNavigate - startOnNavigate;
      cwr("recordEvent", {
        type: "feed_sort_click",
        data: {
          sort: selection,
          feedLoad
        }
      });
      (0, _analyticsAnnotator.triggerFeedToggleAnalytics)(selection, feedLoad, this.appEvents);
    }
    static #_3 = (() => dt7948.n(this.prototype, "toggleChangeHandler", [_object.action]))();
    async updateSelection(args) {
      this.choice = args.eventId;
    }
    static #_4 = (() => dt7948.n(this.prototype, "updateSelection", [_object.action]))();
  }
  _exports.default = FeedToggle;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, FeedToggle);
});