define("discourse/plugins/discourse-global-communities/discourse/components/default-feed-sort", ["exports", "@ember/component", "@glimmer/component", "@glimmer/tracking", "@ember/object", "@ember/service", "discourse/models/user", "discourse-i18n", "discourse/plugins/discourse-global-communities/discourse/config/app-events-config", "discourse/plugins/discourse-global-communities/discourse/lib/helpers/analytics/analytics-annotator", "discourse/plugins/discourse-global-communities/discourse/lib/helpers/config/feed-toggle-config", "@ember/template-factory"], function (_exports, _component, _component2, _tracking, _object, _service, _user, _discourseI18n, _appEventsConfig, _analyticsAnnotator, _feedToggleConfig, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{! template-lint-disable no-duplicate-id  }}
  <div class="agc-dropdown">
    <DButton
      @action={{action this.toggleDefaultSortModal}}
      class="agc-dropdown-button"
    />
    {{#if this.show}}
      <div class="agc-dropdown-modal" id="agc-dropdown-modal">
        <h3 class="agc-dropdown-modal-title" id="agc-dropdown-modal-title">
          {{this.modalTitle}}
        </h3>
        <div class="agc-toggle-container" id="agc-dropdown-toggle-container">
          {{#each this.options as |option|}}
            <div
              class="agc-radio-button-group"
              id="agc-dropdown-radio-button-group"
            >
              <input
                type="radio"
                id={{option.id}}
                value={{option.label}}
                checked={{eq this.defaultFeedSort option}}
                {{on "click" this.updateDefaultSort}}
              />
              <div
                class="agc-radio-button"
                id={{option.id}}
                value={{option.label}}
                checked={{eq this.defaultFeedSort option}}
                {{on "click" this.updateDefaultSort}}
                selected={{eq this.defaultFeedSort option}}
                role="button"
              ></div>
              <div>
                <label
                  for={{option.id}}
                  class="agc-radio-button-label"
                  id="{{option.id}}-radio-button-label"
                >{{option.label}}</label>
              </div>
            </div>
          {{/each}}
        </div>
      </div>
    {{/if}}
  
    <HomepageAdminMenu />
  </div>
  */
  {
    "id": "0979f4+Y",
    "block": "[[[10,0],[14,0,\"agc-dropdown\"],[12],[1,\"\\n  \"],[8,[39,1],[[24,0,\"agc-dropdown-button\"]],[[\"@action\"],[[28,[37,2],[[30,0],[30,0,[\"toggleDefaultSortModal\"]]],null]]],null],[1,\"\\n\"],[41,[30,0,[\"show\"]],[[[1,\"    \"],[10,0],[14,0,\"agc-dropdown-modal\"],[14,1,\"agc-dropdown-modal\"],[12],[1,\"\\n      \"],[10,\"h3\"],[14,0,\"agc-dropdown-modal-title\"],[14,1,\"agc-dropdown-modal-title\"],[12],[1,\"\\n        \"],[1,[30,0,[\"modalTitle\"]]],[1,\"\\n      \"],[13],[1,\"\\n      \"],[10,0],[14,0,\"agc-toggle-container\"],[14,1,\"agc-dropdown-toggle-container\"],[12],[1,\"\\n\"],[42,[28,[37,6],[[28,[37,6],[[30,0,[\"options\"]]],null]],null],null,[[[1,\"          \"],[10,0],[14,0,\"agc-radio-button-group\"],[14,1,\"agc-dropdown-radio-button-group\"],[12],[1,\"\\n            \"],[11,\"input\"],[16,1,[30,1,[\"id\"]]],[16,2,[30,1,[\"label\"]]],[16,\"checked\",[28,[37,8],[[30,0,[\"defaultFeedSort\"]],[30,1]],null]],[24,4,\"radio\"],[4,[38,9],[\"click\",[30,0,[\"updateDefaultSort\"]]],null],[12],[13],[1,\"\\n            \"],[11,0],[24,0,\"agc-radio-button\"],[16,1,[30,1,[\"id\"]]],[16,2,[30,1,[\"label\"]]],[16,\"checked\",[28,[37,8],[[30,0,[\"defaultFeedSort\"]],[30,1]],null]],[16,\"selected\",[28,[37,8],[[30,0,[\"defaultFeedSort\"]],[30,1]],null]],[24,\"role\",\"button\"],[4,[38,9],[\"click\",[30,0,[\"updateDefaultSort\"]]],null],[12],[13],[1,\"\\n            \"],[10,0],[12],[1,\"\\n              \"],[10,\"label\"],[15,\"for\",[30,1,[\"id\"]]],[14,0,\"agc-radio-button-label\"],[15,1,[29,[[30,1,[\"id\"]],\"-radio-button-label\"]]],[12],[1,[30,1,[\"label\"]]],[13],[1,\"\\n            \"],[13],[1,\"\\n          \"],[13],[1,\"\\n\"]],[1]],null],[1,\"      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],null],[1,\"\\n  \"],[8,[39,11],null,null,null],[1,\"\\n\"],[13]],[\"option\"],false,[\"div\",\"d-button\",\"action\",\"if\",\"h3\",\"each\",\"-track-array\",\"input\",\"eq\",\"on\",\"label\",\"homepage-admin-menu\"]]",
    "moduleName": "discourse/plugins/discourse-global-communities/discourse/components/default-feed-sort.hbs",
    "isStrictMode": false
  });
  /* global cwr */

  class DefaultFeedSort extends _component2.default {
    static #_ = (() => dt7948.g(this.prototype, "appEvents", [_service.service]))();
    #appEvents = (() => (dt7948.i(this, "appEvents"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "agcApi", [_service.service]))();
    #agcApi = (() => (dt7948.i(this, "agcApi"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "siteSettings", [_service.service]))();
    #siteSettings = (() => (dt7948.i(this, "siteSettings"), void 0))();
    static #_4 = (() => dt7948.g(this.prototype, "defaultFeedSort", [_tracking.tracked]))();
    #defaultFeedSort = (() => (dt7948.i(this, "defaultFeedSort"), void 0))();
    static #_5 = (() => dt7948.g(this.prototype, "show", [_tracking.tracked]))();
    #show = (() => (dt7948.i(this, "show"), void 0))();
    options = (() => _feedToggleConfig.FEED_TOGGLE_CONFIG.options)();
    onNavigate;
    modalTitle;
    constructor(owner, args) {
      super(owner, args);
      this.onNavigate = args.outletArgs.onNavigate;
      this.show = false;
      this.modalTitle = (0, _discourseI18n.i18n)("agc.default-feed-sort-dropdown.title");

      // Get the user's current default feed sort if they've chosen it. If not, use the site default.
      const userDefault = _user.default.current().user_option.agc_default_home_feed_sorting;
      if (userDefault) {
        this.defaultFeedSort = this.options?.filter(option => option.id === userDefault).pop();
      } else {
        const communityDefault = this.siteSettings.desktop_category_page_style;
        this.defaultFeedSort = this.options?.filter(option => option.setting === communityDefault).pop();
      }
    }
    willDestroy() {
      super.willDestroy(...arguments);
      document.removeEventListener("click", this.unFocus);
    }
    unFocus(event) {
      const dropdownModal = document.getElementById("agc-dropdown-modal");
      if (!dropdownModal) {
        this.show = false;
        document.removeEventListener("click", this.unFocus);
      } else if (!event.target.id || !dropdownModal.contains(document.getElementById(event.target.id))) {
        this.show = false;
        document.removeEventListener("click", this.unFocus);
      }
    }
    static #_6 = (() => dt7948.n(this.prototype, "unFocus", [_object.action]))();
    toggleDefaultSortModal() {
      if (this.show === false) {
        //send analytics when opening the modal
        (0, _analyticsAnnotator.triggerDefaultFeedOpenAnalytics)(this.appEvents);

        // Add an event listener to see if the customer clicks outside the dropdown to close it
        document.addEventListener("click", this.unFocus);
      } else {
        document.removeEventListener("click", this.unFocus);
      }
      this.show = !this.show;
    }
    static #_7 = (() => dt7948.n(this.prototype, "toggleDefaultSortModal", [_object.action]))();
    async updateDefaultSort(event) {
      const id = event.target.getAttribute("id");

      //exit early if it's the current option
      if (this.defaultFeedSort.id === id) {
        this.show = false;
        return;
      }
      this.defaultFeedSort = this.options?.filter(option => option.id === id).pop();
      const startTime = Date.now();
      await this.agcApi.saveCurrentUserDefaultHomeFeedSorting(id, "analytics-origin");
      const endTime = Date.now();
      this.show = false;
      this.onNavigate(id);

      //update the feed sort toggle
      this.appEvents.trigger(_appEventsConfig.FEED_SORT_UPDATE, {
        eventId: id
      });
      cwr("recordEvent", {
        type: "default_feed_sort_update",
        data: {
          sort: id,
          settingUpdateTime: endTime - startTime
        }
      });
    }
    static #_8 = (() => dt7948.n(this.prototype, "updateDefaultSort", [_object.action]))();
  }
  _exports.default = DefaultFeedSort;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, DefaultFeedSort);
});