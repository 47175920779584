define("discourse/plugins/discourse-global-communities/discourse/components/agc-expander", ["exports", "@ember/component", "@glimmer/component", "@glimmer/tracking", "@ember/object", "@ember/template-factory"], function (_exports, _component, _component2, _tracking, _object, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="agc-expander">
    <div
      class="agc-expander-toggler"
      role="button"
      {{action this.toggleIsExpanded}}
    >
      <div class="chevron-svg {{if this.isExpanded 'rotate'}}"></div>
      {{#if this.isExpanded}}
        <h2>Hide {{@label}}</h2>
      {{else}}
        <h2>Show {{@label}}</h2>
      {{/if}}
    </div>
  
    <div
      class="agc-expander-content post-stream {{if this.isExpanded 'visible'}}"
    >
      {{yield}}
    </div>
  </div>
  */
  {
    "id": "3/IdgQPW",
    "block": "[[[10,0],[14,0,\"agc-expander\"],[12],[1,\"\\n  \"],[11,0],[24,0,\"agc-expander-toggler\"],[24,\"role\",\"button\"],[4,[38,1],[[30,0],[30,0,[\"toggleIsExpanded\"]]],null],[12],[1,\"\\n    \"],[10,0],[15,0,[29,[\"chevron-svg \",[52,[30,0,[\"isExpanded\"]],\"rotate\"]]]],[12],[13],[1,\"\\n\"],[41,[30,0,[\"isExpanded\"]],[[[1,\"      \"],[10,\"h2\"],[12],[1,\"Hide \"],[1,[30,1]],[13],[1,\"\\n\"]],[]],[[[1,\"      \"],[10,\"h2\"],[12],[1,\"Show \"],[1,[30,1]],[13],[1,\"\\n\"]],[]]],[1,\"  \"],[13],[1,\"\\n\\n  \"],[10,0],[15,0,[29,[\"agc-expander-content post-stream \",[52,[30,0,[\"isExpanded\"]],\"visible\"]]]],[12],[1,\"\\n    \"],[18,2,null],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[\"@label\",\"&default\"],false,[\"div\",\"action\",\"if\",\"h2\",\"yield\"]]",
    "moduleName": "discourse/plugins/discourse-global-communities/discourse/components/agc-expander.hbs",
    "isStrictMode": false
  });
  class AGCExpander extends _component2.default {
    static #_ = (() => dt7948.g(this.prototype, "isExpanded", [_tracking.tracked], function () {
      return false;
    }))();
    #isExpanded = (() => (dt7948.i(this, "isExpanded"), void 0))();
    toggleIsExpanded() {
      this.isExpanded = !this.isExpanded;
    }
    static #_2 = (() => dt7948.n(this.prototype, "toggleIsExpanded", [_object.action]))();
  }
  _exports.default = AGCExpander;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, AGCExpander);
});