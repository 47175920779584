define("discourse/plugins/discourse-global-communities/discourse/components/agc-toggle", ["exports", "@ember/component", "@ember-decorators/component", "@ember/template-factory"], function (_exports, _component, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="agc-toggle">
    <div class="agc-toggle-container">
      {{#each @options as |option|}}
        <input
          class="hidden"
          type="radio"
          id={{option.id}}
          name="agc-toggle-input"
          checked={{eq @choice option.id}}
          {{on "click" @toggleChangeHandler}}
        />
        <label for={{option.id}}><span
            class="checkmark"
          ></span>{{option.label}}</label>
      {{/each}}
    </div>
  </div>
  */
  {
    "id": "18AhU5Cc",
    "block": "[[[10,0],[14,0,\"agc-toggle\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"agc-toggle-container\"],[12],[1,\"\\n\"],[42,[28,[37,2],[[28,[37,2],[[30,1]],null]],null],null,[[[1,\"      \"],[11,\"input\"],[24,0,\"hidden\"],[16,1,[30,2,[\"id\"]]],[24,3,\"agc-toggle-input\"],[16,\"checked\",[28,[37,4],[[30,3],[30,2,[\"id\"]]],null]],[24,4,\"radio\"],[4,[38,5],[\"click\",[30,4]],null],[12],[13],[1,\"\\n      \"],[10,\"label\"],[15,\"for\",[30,2,[\"id\"]]],[12],[10,1],[14,0,\"checkmark\"],[12],[13],[1,[30,2,[\"label\"]]],[13],[1,\"\\n\"]],[2]],null],[1,\"  \"],[13],[1,\"\\n\"],[13]],[\"@options\",\"option\",\"@choice\",\"@toggleChangeHandler\"],false,[\"div\",\"each\",\"-track-array\",\"input\",\"eq\",\"on\",\"label\",\"span\"]]",
    "moduleName": "discourse/plugins/discourse-global-communities/discourse/components/agc-toggle.hbs",
    "isStrictMode": false
  });
  const AgcToggle = dt7948.c(class AgcToggle extends _component.default {}, [(0, _component2.classNames)("agc-toggle")]);
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, AgcToggle);
});