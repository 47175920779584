define("discourse/plugins/discourse-global-communities/discourse/templates/connectors/user-menu-items-list-empty-state/no-notifications-empty-state", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <NoNotificationsEmptyState />
  */
  {
    "id": "ex4g5CXR",
    "block": "[[[8,[39,0],null,null,null]],[],false,[\"no-notifications-empty-state\"]]",
    "moduleName": "discourse/plugins/discourse-global-communities/discourse/templates/connectors/user-menu-items-list-empty-state/no-notifications-empty-state.hbs",
    "isStrictMode": false
  });
});