define("discourse/plugins/discourse-global-communities/discourse/controllers/idf-library", ["exports", "@ember/controller", "@ember/object", "@ember/runloop", "discourse/lib/ajax", "discourse/lib/decorators", "discourse/models/topic", "discourse/plugins/discourse-global-communities/discourse/lib/idf-events"], function (_exports, _controller, _object, _runloop, _ajax, _decorators, _topic, _idfEvents) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class IdfLibraryController extends _controller.default {
    queryParams = ["category", "type", "topic"];
    type = null;
    topic = null;
    term = null;
    category = null;
    searchTerm = null;
    currentEventTopic(topic) {
      if (topic) {
        return _topic.default.create(topic);
      }
    }
    static #_ = (() => dt7948.n(this.prototype, "currentEventTopic", [(0, _decorators.default)("model.current_event_topic")]))();
    _loadNextEvent() {
      this.set("loadingModel", true);
      (0, _ajax.ajax)("/idf/library.json").then(result => {
        if (result && result.current_event) {
          const currentEvent = result.current_event;
          this.set("model.current_event", currentEvent);
          if ((0, _idfEvents.isEventActive)(currentEvent)) {
            this.set("model.current_event_topic", result.current_event_topic);
          }
        }
      }).finally(() => this.set("loadingModel", false));
    }
    tick() {
      if (this.loadingModel) {
        return;
      }
      if (this.model.current_event) {
        if ((0, _idfEvents.isEventActive)(this.model.current_event)) {
          // once our event becomes active, fetch the topic
          if (!this.model.current_event_topic) {
            this._loadNextEvent();
          }
        } else {
          this.set("model.current_event", null);
          this.set("model.current_event_topic", null);
        }
      } else {
        this._loadNextEvent();
      }
    }
    static #_2 = (() => dt7948.n(this.prototype, "tick", [_object.action]))();
    heroTopic() {
      const topics = this.model.hero.topics;
      if (topics.length > 0) {
        return _topic.default.create(topics[0]);
      }
    }
    static #_3 = (() => dt7948.n(this.prototype, "heroTopic", [(0, _decorators.default)()]))();
    parseOptions(setting) {
      const options = [];
      setting.split("|").forEach(typeString => {
        const type = typeString.split(",", 2);
        options.push({
          id: type[0],
          name: type[1]
        });
      });
      return options;
    }
    typeOptions() {
      return this.parseOptions(this.siteSettings.idf_library_session_types);
    }
    static #_4 = (() => dt7948.n(this.prototype, "typeOptions", [(0, _decorators.default)()]))();
    topicOptions() {
      return this.parseOptions(this.siteSettings.idf_library_topics);
    }
    static #_5 = (() => dt7948.n(this.prototype, "topicOptions", [(0, _decorators.default)()]))();
    categoryOptions() {
      const options = [];
      this.siteSettings.idf_category.split("|").forEach(categoryId => {
        const category = this.site.categories.find(cat => cat.id === parseInt(categoryId, 10));
        if (category) {
          options.push({
            id: categoryId,
            name: category.name
          });
        }
      });
      return options;
    }
    static #_6 = (() => dt7948.n(this.prototype, "categoryOptions", [(0, _decorators.default)()]))();
    displayCategoryFilter(options) {
      return options.length > 1;
    }
    static #_7 = (() => dt7948.n(this.prototype, "displayCategoryFilter", [(0, _decorators.default)("categoryOptions")]))();
    sessions(topics) {
      const typeTagsAndNames = {};
      this.typeOptions.forEach(type => {
        typeTagsAndNames[type.id] = type.name;
      });
      return topics.map(topic => {
        topic.tags.forEach(tag => {
          if (typeTagsAndNames[tag]) {
            topic.type = typeTagsAndNames[tag];
          }
        });
        return _topic.default.create(topic);
      });
    }
    static #_8 = (() => dt7948.n(this.prototype, "sessions", [(0, _decorators.default)("model.topics")]))();
    primarySessions(topics) {
      const primaryTag = this.siteSettings.idf_library_highlight_tag;
      return topics.filter(topic => topic.tags.includes(primaryTag)).sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
    }
    static #_9 = (() => dt7948.n(this.prototype, "primarySessions", [(0, _decorators.default)("sessions")]))();
    allSecondarySessions(categoryId, topics, topicTag, typeTag) {
      const topicsByTags = {};
      topics.forEach(topic => {
        topic.tags.forEach(tag => {
          if (!topicsByTags[tag]) {
            topicsByTags[tag] = [];
          }

          // if only have a topicTag just match on it
          if (topicTag && !typeTag) {
            if (tag !== topicTag) {
              return;
            }
          }

          // if we have both match on topicTag first and then
          // match typeTag with the list of tags of the currently iterated topic
          if (typeTag && topicTag) {
            if (tag !== typeTag && tag !== topicTag) {
              return;
            }
            if (!topic.tags.includes(tag)) {
              return;
            }
          }

          // we only display a row for each topic option so if we only filled on typeTag
          // we would endup with a tag we don't show, so if the typeTag matches
          // we check if the topic tags one of the topic options and add it to this tag
          if (typeTag && !topicTag) {
            if (tag !== typeTag) {
              return;
            }
            this.topicOptions.forEach(option => {
              if (topic.tags.includes(option.id)) {
                if (!topicsByTags[option.id]) {
                  topicsByTags[option.id] = [];
                }
                topicsByTags[option.id].push(topic);
                return;
              }
            });
          }
          topicsByTags[tag].push(topic);
        });
      });
      const secondarySessions = [];
      this.topicOptions.forEach(topicOption => {
        const tagTopics = topicsByTags[topicOption.id] || [];
        if (tagTopics.length) {
          secondarySessions.push({
            title: topicOption.name,
            sessions: tagTopics.sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
          });
        }
      });
      return secondarySessions;
    }
    static #_10 = (() => dt7948.n(this.prototype, "allSecondarySessions", [(0, _decorators.default)("category", "sessions", "topic", "type")]))();
    updateTerm() {
      this.set("term", this.searchTerm ? this.searchTerm : null);
      return (0, _ajax.ajax)("/idf/library.json", {
        data: {
          category: this.category,
          type: this.type,
          topic: this.topic,
          term: this.searchTerm
        }
      }).then(model => {
        this.set("model.topics", model.topics);
        return model;
      });
    }
    categoryChanged(category) {
      this.set("category", category);
    }
    static #_11 = (() => dt7948.n(this.prototype, "categoryChanged", [_object.action]))();
    typeChanged(type) {
      if (type) {
        const ref = `idf_library_type_${type.replace(/-/g, "_")}`;
        localStorage.lastAgcAnalyticsRef = ref;
      }
      this.set("type", type);
    }
    static #_12 = (() => dt7948.n(this.prototype, "typeChanged", [_object.action]))();
    topicChanged(topic) {
      if (topic) {
        const ref = `idf_library_topic_${topic.replace(/-/g, "_")}`;
        localStorage.lastAgcAnalyticsRef = ref;
      }
      this.set("topic", topic);
    }
    static #_13 = (() => dt7948.n(this.prototype, "topicChanged", [_object.action]))();
    searchTermChanged() {
      (0, _runloop.debounce)(this, this.updateTerm, 500);
    }
    static #_14 = (() => dt7948.n(this.prototype, "searchTermChanged", [_object.action]))();
  }
  _exports.default = IdfLibraryController;
});