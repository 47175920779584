define("discourse/plugins/discourse-global-communities/discourse/components/agc-combo-box-with-description", ["exports", "select-kit/components/combo-box", "select-kit/components/select-kit", "discourse/plugins/discourse-global-communities/discourse/components/agc-combo-box-with-description-row"], function (_exports, _comboBox, _selectKit, _agcComboBoxWithDescriptionRow) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const AgcComboBoxWithDescription = dt7948.c(class AgcComboBoxWithDescription extends _comboBox.default {
    modifyComponentForRow() {
      return _agcComboBoxWithDescriptionRow.default;
    }
  }, [(0, _selectKit.pluginApiIdentifiers)("agc-combo-box-with-description")]);
  var _default = _exports.default = AgcComboBoxWithDescription;
});