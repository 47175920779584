define("discourse/plugins/discourse-global-communities/discourse/templates/components/escalation-modal-footer", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="escalation-modal-footer">
    <div class="escalation-modal-footer-actions">
      <DButton
        @action={{action this.secondaryButtonHandler}}
        @translatedLabel={{this.secondaryButtonLabel}}
        class="escalation-modal-btn-secondary"
      />
      <DButton
        @action={{action this.primaryButtonHandler}}
        @translatedLabel={{this.primaryButtonLabel}}
        @disabled={{this.disablePrimaryButton}}
        class="escalation-modal-btn-primary"
      />
    </div>
  </div>
  */
  {
    "id": "2MUegsYQ",
    "block": "[[[10,0],[14,0,\"escalation-modal-footer\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"escalation-modal-footer-actions\"],[12],[1,\"\\n    \"],[8,[39,1],[[24,0,\"escalation-modal-btn-secondary\"]],[[\"@action\",\"@translatedLabel\"],[[28,[37,2],[[30,0],[30,0,[\"secondaryButtonHandler\"]]],null],[30,0,[\"secondaryButtonLabel\"]]]],null],[1,\"\\n    \"],[8,[39,1],[[24,0,\"escalation-modal-btn-primary\"]],[[\"@action\",\"@translatedLabel\",\"@disabled\"],[[28,[37,2],[[30,0],[30,0,[\"primaryButtonHandler\"]]],null],[30,0,[\"primaryButtonLabel\"]],[30,0,[\"disablePrimaryButton\"]]]],null],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[],false,[\"div\",\"d-button\",\"action\"]]",
    "moduleName": "discourse/plugins/discourse-global-communities/discourse/templates/components/escalation-modal-footer.hbs",
    "isStrictMode": false
  });
});