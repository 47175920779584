define("discourse/plugins/discourse-global-communities/discourse/connectors/homepage-topic-list-navigation/feed-toggle", ["exports", "discourse/plugins/discourse-global-communities/discourse/lib/helpers/is-homepage"], function (_exports, _isHomepage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    setupComponent(args, component) {
      component.reopen({
        async _render() {
          if (_shouldRender()) {
            this.set("shouldRender", true);
          }
        }
      });
      function _shouldRender() {
        const router = component.store.register.lookup("service:router");
        return (0, _isHomepage.isHomepage)(router);
      }
      this._render();
    }
  };
});