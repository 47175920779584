define("discourse/plugins/discourse-global-communities/discourse/lib/helpers/url-helpers", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.getTopicInfoFromPath = void 0;
  /**
   * Should only be used for components that are in a topic page
   *
   * @returns {String}
   */
  const getTopicInfoFromPath = () => {
    const pathnames = window.location.pathname.split("/");
    return {
      topicId: pathnames.at(-1),
      topicTitle: pathnames.at(-2)
    };
  };
  _exports.getTopicInfoFromPath = getTopicInfoFromPath;
});