define("discourse/plugins/discourse-global-communities/discourse/lib/helpers/admin/users/remove-user-from-group", ["exports", "discourse/lib/ajax"], function (_exports, _ajax) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.removeUserFromGroup = void 0;
  /**
   * Remove a user from a group
   *
   * @param {string} userId
   * @param {string} removeGroupId
   * @returns {Promise<string>}
   */
  const removeUserFromGroup = async (userId, removeGroupId) => {
    if (!userId || !removeGroupId) {
      throw new Error("userId and removeGroupId are required.");
    }
    return await (0, _ajax.ajax)(`/admin/users/${userId}/groups/${removeGroupId}`, {
      type: "DELETE"
    });
  };
  _exports.removeUserFromGroup = removeUserFromGroup;
});