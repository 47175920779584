define("discourse/plugins/discourse-global-communities/discourse/components/change-ownership-chooser", ["exports", "@glimmer/component", "@glimmer/tracking", "@ember/helper", "@ember/object", "@ember/service", "discourse-i18n", "select-kit/components/user-chooser", "@ember/component", "@ember/template-factory"], function (_exports, _component, _tracking, _helper, _object, _service, _discourseI18n, _userChooser, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class ChangeOwnershipChooser extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "composer", [_service.service]))();
    #composer = (() => (dt7948.i(this, "composer"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "currentUser", [_service.service]))();
    #currentUser = (() => (dt7948.i(this, "currentUser"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "value", [_tracking.tracked], function () {
      return null;
    }))();
    #value = (() => (dt7948.i(this, "value"), void 0))();
    get shouldRender() {
      // admin only
      if (!this.currentUser.admin) {
        return false;
      }
      // add if creating post
      if (!this.args.model.creatingTopic && !this.args.model.replyingToTopic) {
        return false;
      }
      return this.currentUser.agcFeatureFlags.agc_change_ownership_enabled;
    }
    setUser(usernames, users) {
      // Set the user ID we will post as in the composer model
      // we are using the first user in the list, as we are limiting the user chooser to 1
      this.composer.model.set("agcChangeOwnershipUserId", users[0]?.id);
      this.composer.model.set("agcChangeOwnershipAdminId", this.currentUser.id);
      this.value = usernames[0] || [];
    }
    static #_4 = (() => dt7948.n(this.prototype, "setUser", [_object.action]))();
    static #_5 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        {{#if this.shouldRender}}
          <div class="agc-change-ownership-chooser">
            <UserChooser
              @onChange={{this.setUser}}
              @options={{hash
                maximum=1
                translatedNone=(i18n "agc.composer.change_ownership.none")
                excludeCurrentUser=true
              }}
              @value={{this.value}}
            />
          </div>
        {{/if}}
      
    */
    {
      "id": "UUdCNkfl",
      "block": "[[[1,\"\\n\"],[41,[30,0,[\"shouldRender\"]],[[[1,\"      \"],[10,0],[14,0,\"agc-change-ownership-chooser\"],[12],[1,\"\\n        \"],[8,[32,0],null,[[\"@onChange\",\"@options\",\"@value\"],[[30,0,[\"setUser\"]],[28,[32,1],null,[[\"maximum\",\"translatedNone\",\"excludeCurrentUser\"],[1,[28,[32,2],[\"agc.composer.change_ownership.none\"],null],true]]],[30,0,[\"value\"]]]],null],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]],null],[1,\"  \"]],[],false,[\"if\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-global-communities/discourse/components/change-ownership-chooser.js",
      "scope": () => [_userChooser.default, _helper.hash, _discourseI18n.i18n],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = ChangeOwnershipChooser;
});