define("discourse/plugins/discourse-global-communities/discourse/components/post-intent-chooser", ["exports", "@glimmer/component", "@glimmer/tracking", "@ember/helper", "@ember/object", "@ember/service", "discourse/components/popup-input-tip", "discourse-i18n", "discourse/plugins/discourse-global-communities/discourse/components/agc-combo-box-with-description", "@ember/component", "@ember/template-factory"], function (_exports, _component, _tracking, _helper, _object, _service, _popupInputTip, _discourseI18n, _agcComboBoxWithDescription, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class PostIntentSelector extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "agcPostIntent", [_service.service]))();
    #agcPostIntent = (() => (dt7948.i(this, "agcPostIntent"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "composer", [_service.service]))();
    #composer = (() => (dt7948.i(this, "composer"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "siteSettings", [_service.service]))();
    #siteSettings = (() => (dt7948.i(this, "siteSettings"), void 0))();
    static #_4 = (() => dt7948.g(this.prototype, "value", [_tracking.tracked], function () {
      return this.args.value;
    }))();
    #value = (() => (dt7948.i(this, "value"), void 0))();
    get content() {
      const intents = this.siteSettings.agc_post_intents.split("|");
      // we have the expectation that the translations are going to have the keys
      // `POST_INTENT_title` and `POST_INTENT_description` where `POST_INTENT` is a value
      // from the `agc_post_intents` site setting
      return intents.map(intent => {
        return {
          value: intent,
          title: (0, _discourseI18n.i18n)(`${intent}_title`),
          description: (0, _discourseI18n.i18n)(`${intent}_description`)
        };
      });
    }
    get placeholderText() {
      return (0, _discourseI18n.i18n)("agc.post-intent.none");
    }
    setValue(value) {
      this.args.setPostIntent(value);
      this.value = value;
    }
    static #_5 = (() => dt7948.n(this.prototype, "setValue", [_object.action]))();
    static #_6 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <div class="post-intent-chooser">
          <AgcComboBoxWithDescription
            @id="agc-post-intent"
            @content={{this.content}}
            @valueProperty="value"
            @nameProperty="title"
            @value={{this.value}}
            @onChange={{this.setValue}}
            @options={{hash
              translatedNone=(if this.value null this.placeholderText)
            }}
          />
    
          <PopupInputTip @validation={{this.agcPostIntent.postIntentValidation}} />
        </div>
      
    */
    {
      "id": "T39mlRXV",
      "block": "[[[1,\"\\n    \"],[10,0],[14,0,\"post-intent-chooser\"],[12],[1,\"\\n      \"],[8,[32,0],null,[[\"@id\",\"@content\",\"@valueProperty\",\"@nameProperty\",\"@value\",\"@onChange\",\"@options\"],[\"agc-post-intent\",[30,0,[\"content\"]],\"value\",\"title\",[30,0,[\"value\"]],[30,0,[\"setValue\"]],[28,[32,1],null,[[\"translatedNone\"],[[52,[30,0,[\"value\"]],null,[30,0,[\"placeholderText\"]]]]]]]],null],[1,\"\\n\\n      \"],[8,[32,2],null,[[\"@validation\"],[[30,0,[\"agcPostIntent\",\"postIntentValidation\"]]]],null],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[],false,[\"if\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-global-communities/discourse/components/post-intent-chooser.js",
      "scope": () => [_agcComboBoxWithDescription.default, _helper.hash, _popupInputTip.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = PostIntentSelector;
});