define("discourse/plugins/discourse-global-communities/discourse/routes/members", ["exports", "@ember/object", "@ember/routing/route", "discourse/lib/ajax", "discourse/mixins/scroll-top", "discourse/models/composer"], function (_exports, _object, _route, _ajax, _scrollTop, _composer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class MembersRoute extends _route.default {
    model(params) {
      params = params || {};
      let data = {};
      if (params.station) {
        data.stations = [params.station];
        data.filters = {
          station: params.station
        };
      }
      const countryField = this.site.get("user_fields").find(user_field => user_field.name === "Country");
      if (!params.station && countryField) {
        const country = this.currentUser.custom_fields[`user_field_${countryField.id}`];
        if (country) {
          data.filters = {
            country
          };
        }
      }
      return (0, _ajax.ajax)("/members.json", {
        data
      });
    }
    setupController(controller) {
      super.setupController(...arguments);
      controller.set("page", 0);
    }
    message(username) {
      this.controllerFor("composer").open({
        action: _composer.PRIVATE_MESSAGE,
        usernames: username,
        archetypeId: "private_message",
        draftKey: "new_private_message"
      });
    }
    static #_ = (() => dt7948.n(this.prototype, "message", [_object.action]))();
    didTransition() {
      (0, _scrollTop.scrollTop)();
    }
    static #_2 = (() => dt7948.n(this.prototype, "didTransition", [_object.action]))();
  }
  _exports.default = MembersRoute;
});