define("discourse/plugins/discourse-global-communities/discourse/lib/helpers/analytics/analytics-annotator", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.triggerDefaultFeedOpenAnalytics = triggerDefaultFeedOpenAnalytics;
  _exports.triggerFeedToggleAnalytics = triggerFeedToggleAnalytics;
  function triggerFeedToggleAnalytics(feedSelection, feedLoad, appEvents) {
    const eventData = {
      sort: feedSelection,
      feedLoad
    };
    appEvents.trigger("agc-custom:feed_sort_click", eventData);
  }
  function triggerDefaultFeedOpenAnalytics(appEvents) {
    appEvents.trigger("agc-custom:default_feed_dropdown_open");
  }
});