define("discourse/plugins/discourse-global-communities/discourse/components/bulk-admin-csv", ["exports", "@ember/component", "@glimmer/tracking", "@ember/object", "discourse/lib/decorators", "discourse/plugins/discourse-global-communities/discourse/helpers/config/communityStage", "discourse/plugins/discourse-global-communities/discourse/lib/helpers/admin/bulk-admin-actions/constants/bulk-admin-constants", "discourse/plugins/discourse-global-communities/discourse/lib/helpers/admin/users/constants/user-attributes", "discourse/plugins/discourse-global-communities/discourse/lib/helpers/csv-parse", "@ember/template-factory"], function (_exports, _component, _tracking, _object, _decorators, _communityStage, _bulkAdminConstants, _userAttributes, _csvParse, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{! template-lint-disable no-invalid-interactive  }}
  <h2> Upload a CSV file *</h2>
  <h3>Only one file per submission. Max size of 500kb.</h3>
  
  <div
    id="drop_zone"
    ondrop={{action "dropHandler"}}
    ondragover={{action "dragOverHandler"}}
    ondragleave={{action "dragLeaveHandler"}}
    class="upload-module {{if this.isDraggedOver 'dragging' ''}}"
  >
    <div class="module-text">
      {{#if this.isDraggedOver}}
        <p>Drop file here!</p>
      {{else if this.bulkAdminAcceptFile}}
        <input
          id="fileInput"
          type="file"
          onchange={{action "fileOnChange"}}
          accept=".csv"
        />
        <p>or Drag files here!</p>
      {{else}}
        <p>{{this.bulkAdminFile.name}} ({{this.bulkAdminFileSize}})</p>
        <button type="button" {{action "removeFile"}}>
          remove
        </button>
      {{/if}}
    </div>
  
  </div>
  {{#if this.showSubmit}}
    {{agc-custom-button type="primary" label="Submit" onClick=(action "submit")}}
  {{/if}}
  */
  {
    "id": "8pKPTZZn",
    "block": "[[[10,\"h2\"],[12],[1,\" Upload a CSV file *\"],[13],[1,\"\\n\"],[10,\"h3\"],[12],[1,\"Only one file per submission. Max size of 500kb.\"],[13],[1,\"\\n\\n\"],[10,0],[14,1,\"drop_zone\"],[15,\"ondrop\",[28,[37,3],[[30,0],\"dropHandler\"],null]],[15,\"ondragover\",[28,[37,3],[[30,0],\"dragOverHandler\"],null]],[15,\"ondragleave\",[28,[37,3],[[30,0],\"dragLeaveHandler\"],null]],[15,0,[29,[\"upload-module \",[52,[30,0,[\"isDraggedOver\"]],\"dragging\",\"\"]]]],[12],[1,\"\\n  \"],[10,0],[14,0,\"module-text\"],[12],[1,\"\\n\"],[41,[30,0,[\"isDraggedOver\"]],[[[1,\"      \"],[10,2],[12],[1,\"Drop file here!\"],[13],[1,\"\\n\"]],[]],[[[41,[30,0,[\"bulkAdminAcceptFile\"]],[[[1,\"      \"],[10,\"input\"],[14,1,\"fileInput\"],[15,\"onchange\",[28,[37,3],[[30,0],\"fileOnChange\"],null]],[14,\"accept\",\".csv\"],[14,4,\"file\"],[12],[13],[1,\"\\n      \"],[10,2],[12],[1,\"or Drag files here!\"],[13],[1,\"\\n\"]],[]],[[[1,\"      \"],[10,2],[12],[1,[30,0,[\"bulkAdminFile\",\"name\"]]],[1,\" (\"],[1,[30,0,[\"bulkAdminFileSize\"]]],[1,\")\"],[13],[1,\"\\n      \"],[11,\"button\"],[24,4,\"button\"],[4,[38,3],[[30,0],\"removeFile\"],null],[12],[1,\"\\n        remove\\n      \"],[13],[1,\"\\n    \"]],[]]]],[]]],[1,\"  \"],[13],[1,\"\\n\\n\"],[13],[1,\"\\n\"],[41,[30,0,[\"showSubmit\"]],[[[1,\"  \"],[1,[28,[35,8],null,[[\"type\",\"label\",\"onClick\"],[\"primary\",\"Submit\",[28,[37,3],[[30,0],\"submit\"],null]]]]],[1,\"\\n\"]],[]],null]],[],false,[\"h2\",\"h3\",\"div\",\"action\",\"if\",\"p\",\"input\",\"button\",\"agc-custom-button\"]]",
    "moduleName": "discourse/plugins/discourse-global-communities/discourse/components/bulk-admin-csv.hbs",
    "isStrictMode": false
  });
  class BulkAdminCsv extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "bulkAdminFile", [_tracking.tracked], function () {
      return null;
    }))();
    #bulkAdminFile = (() => (dt7948.i(this, "bulkAdminFile"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "isDraggedOver", [_tracking.tracked], function () {
      return false;
    }))();
    #isDraggedOver = (() => (dt7948.i(this, "isDraggedOver"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "parseSucceed", [_tracking.tracked], function () {
      return false;
    }))();
    #parseSucceed = (() => (dt7948.i(this, "parseSucceed"), void 0))();
    static #_4 = (() => dt7948.g(this.prototype, "showSubmit", [_tracking.tracked], function () {
      return false;
    }))();
    #showSubmit = (() => (dt7948.i(this, "showSubmit"), void 0))();
    bulkAdminParsedData = null;

    // For grabbing and processing the csv file
    _getCSV() {
      const file = this.bulkAdminFile;
      const reader = new FileReader();
      if (file.size > _bulkAdminConstants.MAX_FILE_SIZE) {
        this._triggerErrorEvent(`File size too large. Maximum size is 500kb`);
        return;
      }
      if (!this.get("actionType")) {
        this._triggerErrorEvent("Please select an action item");
        return;
      }
      // for converting type of action to column required
      const columns = _bulkAdminConstants.ACTION_TO_COLUMNS_MAP[this.get("actionType")];
      reader.onload = function (event) {
        let csvToText = event.target.result;
        this.set("bulkAdminParsedData", this._CSVtoJSON(csvToText, columns));
      }.bind(this);
      reader.readAsText(file);
    }
    _CSVtoJSON(rawCSV, columns) {
      const csvToText = (0, _csvParse.cleanCSVData)(rawCSV);
      let json = null;
      const headers = csvToText[_bulkAdminConstants.HEADER_LINE_IDX].split(",");
      this.appEvents.trigger("bulk-admin:parsedCSVInputHeaders", {
        headers
      });
      let isCSVValid = this.get("actionType") === _bulkAdminConstants.BULK_ADMIN_ACTIONS_IDS.UPDATE_USER_ATTRIBUTES ? this._isCSVAttributeValid(headers) : this._isCSVColumnsValid(headers, columns);
      if (isCSVValid) {
        json = (0, _csvParse.bulkAdminListToJSON)(csvToText, this.appEvents);
      }
      this.set("parseSucceed", !!json);
      this.set("showSubmit", !!json);
      return json;
    }
    _isCSVAttributeValid(headers) {
      const community = (0, _communityStage.getCommunityName)();
      const stage = (0, _communityStage.getStageName)();
      if (headers[_bulkAdminConstants.USERNAME_COLUMN_IDX] === "username") {
        const communityUserFields = _userAttributes.communityToUserFieldsMap[community]?.[stage];
        if (!communityUserFields) {
          throw new Error(`Community stage [${community} ${stage}] is not supported.`);
        }
        if (communityUserFields.hasOwnProperty(headers[_bulkAdminConstants.ATTRIBUTE_TO_UPDATE_COLUMN_IDX])) {
          return true;
        }
        this._triggerErrorEvent(`Attributes: '${headers[_bulkAdminConstants.ATTRIBUTE_TO_UPDATE_COLUMN_IDX]}' not found in accepted columns: ${Object.keys(communityUserFields)}`);
      } else {
        this._triggerErrorEvent(`Column: "username" not found`);
      }
      return false;
    }
    _trimList(list) {
      return list.map(element => {
        return element.trim();
      });
    }
    _isCSVColumnsValid(headers, columns) {
      /**
       * sorting to ignore order
       *
       * Need to use the spread operator here because js sort() sorts in place
       *   which means that original columns array will be altered and results in
       *   bugs when referencing actionsToColumnsMap elsewhere
       */
      if ([...columns].sort().toString() === [...headers].sort().toString()) {
        return true;
      } else {
        this._triggerErrorEvent(`Expected columns: "${columns}" Doesn't match received columns: "${headers}"`);
        this.set("bulkAdminParsedData", null);
        return false;
      }
    }
    _triggerErrorEvent(content) {
      this.appEvents.trigger("bulk-admin:action", {
        action: "parsingError",
        userAction: this.get("actionType"),
        content
      });
    }
    dragOverHandler(ev) {
      // Prevent default behavior (Prevent file from being opened)
      ev.preventDefault();
      this.set("isDraggedOver", true);
    }
    static #_5 = (() => dt7948.n(this.prototype, "dragOverHandler", [_object.action]))();
    dragLeaveHandler(ev) {
      ev.preventDefault();
      this.set("isDraggedOver", false);
    }
    static #_6 = (() => dt7948.n(this.prototype, "dragLeaveHandler", [_object.action]))();
    fileOnChange() {
      const file = document.getElementById("fileInput").files[0];
      this._setFileState(file);
      this._getCSV();
    }
    static #_7 = (() => dt7948.n(this.prototype, "fileOnChange", [_object.action]))();
    _setFileState(file) {
      this.set("bulkAdminFile", file);
    }
    _isCsvFile(fileName) {
      return this._getFileExtension(fileName) === "csv";
    }
    _getFileExtension(fileName) {
      return fileName.split(".").pop();
    }
    dropHandler(ev) {
      // Prevent default behavior (Prevent file from being opened)
      ev.preventDefault();
      this.set("isDraggedOver", false);
      if (ev.dataTransfer.items) {
        for (const item of ev.dataTransfer.items) {
          const file = item.getAsFile();
          if (!this._isCsvFile(file.name) || item.kind !== "file" || !file.name.includes(".")) {
            this._triggerErrorEvent(`Only .csv files allowed, not ${this._getFileExtension(file.name)}`);
            return;
          }
          this._setFileState(file);
          this.appEvents.trigger("bulk-admin:action", {
            action: "hideResults"
          });
          this._getCSV();
        }
      }
    }

    // converts number of bytes to kb, mb, gb
    static #_8 = (() => dt7948.n(this.prototype, "dropHandler", [_object.action]))();
    _formatBytes(fileSize) {
      let precision = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 2;
      // +fileSize converts string to num

      if (!+fileSize) {
        return "0 Bytes";
      }
      const roundedPrecision = 0 > precision ? 0 : precision,
        bytes = Math.floor(Math.log(fileSize) / Math.log(1024));
      return `${parseFloat((fileSize / Math.pow(1024, bytes)).toFixed(roundedPrecision))} ${["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"][bytes]}`;
    }
    bulkAdminFileSize(bulkAdminFile) {
      return this._formatBytes(bulkAdminFile?.size);
    }
    static #_9 = (() => dt7948.n(this.prototype, "bulkAdminFileSize", [(0, _decorators.default)("bulkAdminFile")]))();
    bulkAdminAcceptFile(bulkAdminFile) {
      return !bulkAdminFile;
    }
    static #_10 = (() => dt7948.n(this.prototype, "bulkAdminAcceptFile", [(0, _decorators.default)("bulkAdminFile")]))();
    removeFile() {
      this.set("bulkAdminFile", null);
      this.set("bulkAdminParsedData", null);
      this.appEvents.trigger("bulk-admin:action", {
        action: "hideResults",
        content: ""
      });
    }
    static #_11 = (() => dt7948.n(this.prototype, "removeFile", [_object.action]))();
    submit() {
      if (this.get("bulkAdminParsedData")) {
        this.appEvents.trigger("bulk-admin:action", {
          action: "parsingResults",
          content: this.get("bulkAdminParsedData")
        });
        this.set("bulkAdminFile", null);
        this.set("bulkAdminParsedData", null);
        this.set("showSubmit", false);
      } else {
        this._triggerErrorEvent("Error parsing data");
      }
    }
    static #_12 = (() => dt7948.n(this.prototype, "submit", [_object.action]))();
  }
  _exports.default = BulkAdminCsv;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, BulkAdminCsv);
});