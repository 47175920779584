define("discourse/plugins/discourse-global-communities/discourse/connectors/user-notifications-empty-state/no-notifications-empty-state", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    shouldRender(_, container) {
      const isFeatureEnabled = container.currentUser?.agcFeatureFlags?.enhanced_notifications_enabled;
      return isFeatureEnabled;
    }
  };
});