define("discourse/plugins/discourse-global-communities/discourse/components/escalation-form", ["exports", "@glimmer/component", "@glimmer/tracking", "@ember/object", "@ember/service", "discourse-i18n", "discourse/plugins/discourse-global-communities/discourse/config/app-events-config", "discourse/plugins/discourse-global-communities/discourse/helpers/config/communityStage", "discourse/plugins/discourse-global-communities/discourse/config/escalation-form-config"], function (_exports, _component, _tracking, _object, _service, _discourseI18n, _appEventsConfig, _communityStage, _escalationFormConfig) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /* eslint-disable sort-class-members/sort-class-members */

  class EscalationForm extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "appEvents", [_service.service]))();
    #appEvents = (() => (dt7948.i(this, "appEvents"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "siteSettings", [_service.service]))();
    #siteSettings = (() => (dt7948.i(this, "siteSettings"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "isProcessingRequest", [_tracking.tracked], function () {
      return false;
    }))();
    #isProcessingRequest = (() => (dt7948.i(this, "isProcessingRequest"), void 0))();
    // Values for <Input /> components
    smeLabel;
    static #_4 = (() => dt7948.g(this.prototype, "smeValue", [_tracking.tracked], function () {
      return "";
    }))();
    #smeValue = (() => (dt7948.i(this, "smeValue"), void 0))();
    leaderLabel;
    static #_5 = (() => dt7948.g(this.prototype, "leaderValue", [_tracking.tracked], function () {
      return "";
    }))();
    #leaderValue = (() => (dt7948.i(this, "leaderValue"), void 0))();
    static #_6 = (() => dt7948.g(this.prototype, "severitySelection", [_tracking.tracked], function () {
      return "";
    }))();
    #severitySelection = (() => (dt7948.i(this, "severitySelection"), void 0))(); // Values for <ComboBox /> components
    severityOptions;
    static #_7 = (() => dt7948.g(this.prototype, "focusAreaSelection", [_tracking.tracked], function () {
      return "";
    }))();
    #focusAreaSelection = (() => (dt7948.i(this, "focusAreaSelection"), void 0))();
    focusAreaOptions;

    // Value for <ExpandingTextArea /> component
    static #_8 = (() => dt7948.g(this.prototype, "summaryValue", [_tracking.tracked], function () {
      return "";
    }))();
    #summaryValue = (() => (dt7948.i(this, "summaryValue"), void 0))();
    summaryMinLength;
    summaryMaxLength;
    currentLocale = (() => _discourseI18n.default.currentLocale())();
    charLimitString;
    constructor() {
      super(...arguments);
      const communityEscalationConfig = _escalationFormConfig.ESCALATION_CONFIG[(0, _communityStage.getCommunityName)()];
      this.smeLabel = communityEscalationConfig.smeLabel;
      this.leaderLabel = communityEscalationConfig.leaderLabel;
      this.severityOptions = communityEscalationConfig.severityOptions;
      this.summaryMinLength = communityEscalationConfig.summaryMinLength;
      this.summaryMaxLength = communityEscalationConfig.summaryMaxLength;
      this.charLimitString = this.summaryMaxLength.toLocaleString(this.currentLocale);
      this.focusAreaOptions = this._formatFocusAreaOptions();
      this._populateForm(this.args.formData);
      this.appEvents.on(_appEventsConfig.ESCALATION_MODAL_CLOSE, this, this.onCancelHandler);
    }
    willDestroy() {
      super.willDestroy(...arguments);
      this.appEvents.off(_appEventsConfig.ESCALATION_MODAL_CLOSE, this, this.onCancelHandler);
    }
    _formatFocusAreaOptions() {
      let escalation_focus_area_options;
      switch ((0, _communityStage.getCommunityName)()) {
        case _communityStage.IGNITE_COMMUNITY:
          escalation_focus_area_options = this.siteSettings.ignite_escalation_focus_area_options;
          break;
        case _communityStage.UPSHIFT_COMMUNITY:
          escalation_focus_area_options = this.siteSettings.upshift_escalation_focus_area_options;
          break;
        default:
          return [];
      }
      return escalation_focus_area_options.split("|").map(entry => {
        return {
          id: entry,
          name: entry
        };
      });
    }
    _populateForm(formData) {
      if (formData) {
        this.smeValue = formData.sme;
        this.leaderValue = formData.leader;
        this.severitySelection = formData.severity;
        this.focusAreaSelection = formData.focusArea;
        this.summaryValue = formData.summary;
      }
    }
    _clearForm() {
      this.smeValue = "";
      this.leaderValue = "";
      this.severitySelection = "";
      this.focusAreaSelection = "";
      this.summaryValue = "";
    }
    get isSummaryTooShort() {
      return this.summaryValue.trim().length < this.summaryMinLength;
    }
    get isSummaryTooLong() {
      return this.summaryValue.length > this.summaryMaxLength;
    }
    get summaryCharCounter() {
      return this.summaryValue.length.toLocaleString(this.currentLocale);
    }
    get textAreaErrorClass() {
      return this.isSummaryTooLong ? "escalation-textarea-error" : "";
    }
    get disableSubmit() {
      return !this.smeValue || !this.leaderValue || !this.severitySelection || !this.focusAreaSelection || !this.summaryValue || this.isSummaryTooShort || this.isSummaryTooLong || this.args.isLoading;
    }
    onTextChangeHandler(state, event) {
      const value = event.target.value;
      this[state] = value;
    }
    static #_9 = (() => dt7948.n(this.prototype, "onTextChangeHandler", [_object.action]))();
    onSelectChangeHandler(state, value) {
      this[state] = value;
    }
    static #_10 = (() => dt7948.n(this.prototype, "onSelectChangeHandler", [_object.action]))();
    async onSubmitHandler() {
      const clearForm = await this.args.primaryButtonHandler({
        sme: this.smeValue,
        leader: this.leaderValue,
        severity: this.severitySelection,
        focusArea: this.focusAreaSelection,
        summary: this.summaryValue
      });
      if (clearForm) {
        this._clearForm();
      }
    }
    static #_11 = (() => dt7948.n(this.prototype, "onSubmitHandler", [_object.action]))();
    async onCancelHandler() {
      await this.args.secondaryButtonHandler({
        sme: this.smeValue,
        leader: this.leaderValue,
        severity: this.severitySelection,
        focusArea: this.focusAreaSelection,
        summary: this.summaryValue
      });
    }
    static #_12 = (() => dt7948.n(this.prototype, "onCancelHandler", [_object.action]))();
  }
  _exports.default = EscalationForm;
});