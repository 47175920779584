define("discourse/plugins/discourse-global-communities/discourse/lib/helpers/config/errors", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.ErrorType = void 0;
  /**
   * Error types for different error codes received when calling a TPX-owned service.
   *
   * @type {{ErrorName: string}}
   */
  const ErrorType = _exports.ErrorType = {
    // Client error code for when an invalid alias is provided
    InvalidAlias: "TPX_CLIENT_A006"
  };
});