define("discourse/plugins/discourse-global-communities/discourse/initializers/discourse-agc-analytics", ["exports", "@ember/object", "discourse/lib/plugin-api", "discourse/plugins/discourse-global-communities/discourse/lib/agc-user-session", "discourse/plugins/discourse-global-communities/discourse/lib/analytics-collector"], function (_exports, _object, _pluginApi, _agcUserSession, _analyticsCollector) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function initializeDiscourseAgcAnalytics(api, site, siteSettings, messageBus, router, appRootElement, agcSeeMoreTracking) {
    if (siteSettings.agc_analytics_enabled && api.getCurrentUser()) {
      let analyticsCollector;
      _agcUserSession.default.current().setup(siteSettings, api).then(() => {
        analyticsCollector = new _analyticsCollector.default({
          pluginApi: api,
          site,
          siteSettings,
          router,
          appRootElement,
          agcSeeMoreTracking
        });
        // Make sure we collect analytics on page load. We need to "save" here so that we capture
        // users who log in, but close the browser without clicking on anything.
        analyticsCollector.startTracking().then(() => {
          analyticsCollector.logPageLoad();
        });
      });

      // make sure to clear local session id and transit id on logout so we
      // get new ones next time we try to log analytics
      messageBus.subscribe("/logout", function () {
        _agcUserSession.default.current().clear();
      });

      // d-button calls preventDefault on click, which prevents the link from being tracked
      api.modifyClass("component:d-button", Superclass => class extends Superclass {
        click(event) {
          const clickResult = this._triggerAction(event);
          if (analyticsCollector) {
            analyticsCollector._interceptClick(event);
          }
          return clickResult;
        }
        static #_ = (() => dt7948.n(this.prototype, "click", [_object.action]))();
      });
    }
  }
  var _default = _exports.default = {
    name: "discourse-agc-analytics",
    initialize(container, app) {
      const site = container.lookup("service:site");
      const siteSettings = container.lookup("service:site-settings");
      const messageBus = container.lookup("service:message-bus");
      const router = container.lookup("service:router");
      const agcSeeMoreTracking = container.lookup("service:agc-see-more-tracking");
      (0, _pluginApi.withPluginApi)("0.8.31", api => initializeDiscourseAgcAnalytics(api, site, siteSettings, messageBus, router, app.rootElement, agcSeeMoreTracking));
    }
  };
});