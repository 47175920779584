define("discourse/plugins/discourse-global-communities/discourse/components/gc-combo-box", ["exports", "@ember-decorators/component", "select-kit/components/combo-box"], function (_exports, _component, _comboBox) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const GcComboBox = dt7948.c(class GcComboBox extends _comboBox.default {
    headerComponent = "gc-combo-box-header";
  }, [(0, _component.classNames)("gc-combo-box")]);
  var _default = _exports.default = GcComboBox;
});