define("discourse/plugins/discourse-global-communities/discourse/components/tags-tab", ["exports", "@ember/component", "@glimmer/component", "@glimmer/tracking", "@ember/object", "@ember/service", "discourse/plugins/discourse-global-communities/discourse/config/app-events-config", "discourse/plugins/discourse-global-communities/discourse/helpers/improved-search-helpers", "discourse/plugins/discourse-global-communities/discourse/services/getTags", "@ember/template-factory"], function (_exports, _component, _component2, _tracking, _object, _service, _appEventsConfig, _improvedSearchHelpers, _getTags, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="tags-results" {{did-update this.getNewTags @searchTerm}}>
    {{#each this.tags as |tag|}}
      <div class="tag-item" id="tag-result-{{tag.id}}" data-tag-name={{tag.name}}>
        <a class="tag-hyperlink" href="/tag/{{tag.id}}">
          {{tag.name}}
        </a>
      </div>
    {{/each}}
  </div>
  */
  {
    "id": "Xjlcvv5f",
    "block": "[[[11,0],[24,0,\"tags-results\"],[4,[38,1],[[30,0,[\"getNewTags\"]],[30,1]],null],[12],[1,\"\\n\"],[42,[28,[37,3],[[28,[37,3],[[30,0,[\"tags\"]]],null]],null],null,[[[1,\"    \"],[10,0],[14,0,\"tag-item\"],[15,1,[29,[\"tag-result-\",[30,2,[\"id\"]]]]],[15,\"data-tag-name\",[30,2,[\"name\"]]],[12],[1,\"\\n      \"],[10,3],[14,0,\"tag-hyperlink\"],[15,6,[29,[\"/tag/\",[30,2,[\"id\"]]]]],[12],[1,\"\\n        \"],[1,[30,2,[\"name\"]]],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n\"]],[2]],null],[13]],[\"@searchTerm\",\"tag\"],false,[\"div\",\"did-update\",\"each\",\"-track-array\",\"a\"]]",
    "moduleName": "discourse/plugins/discourse-global-communities/discourse/components/tags-tab.hbs",
    "isStrictMode": false
  });
  class TagsTab extends _component2.default {
    static #_ = (() => dt7948.g(this.prototype, "appEvents", [_service.service]))();
    #appEvents = (() => (dt7948.i(this, "appEvents"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "siteSettings", [_service.service]))();
    #siteSettings = (() => (dt7948.i(this, "siteSettings"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "search", [_service.service]))();
    #search = (() => (dt7948.i(this, "search"), void 0))();
    static #_4 = (() => dt7948.g(this.prototype, "tags", [_tracking.tracked], function () {
      return [];
    }))();
    #tags = (() => (dt7948.i(this, "tags"), void 0))();
    tabName;
    pageNumber;
    hasReachedLastPage;
    constructor(owner, args) {
      super(owner, args);
      this.tabName = args.tabName;
      const resultsCache = args.resultsCache;
      this.pageNumber = resultsCache.pageNumber;
      this.hasReachedLastPage = resultsCache.hasReachedLastPage;
      if (resultsCache.results) {
        this.tags = resultsCache.results;
      } else {
        this.getAllTags();
      }
      this.appEvents.on(_appEventsConfig.SEARCH_LOAD_MORE, this, this.getNextPage);
    }
    willDestroy() {
      super.willDestroy(...arguments);
      this.appEvents.off(_appEventsConfig.SEARCH_LOAD_MORE, this, this.getNextPage);
    }
    getNewTags() {
      this.tags = [];
      this.pageNumber = 0;
      this.hasReachedLastPage = false;
      this.getAllTags();
    }
    static #_5 = (() => dt7948.n(this.prototype, "getNewTags", [_object.action]))();
    getAllTags() {
      const args = {
        searchTerm: this.search.activeGlobalSearchTerm,
        limit: this.siteSettings.improved_search_page_size * (this.pageNumber + 1)
      };
      const startRequest = Date.now();
      (0, _getTags.getTags)(args).then(result => {
        const endRequest = Date.now();
        const requestLatency = endRequest - startRequest;
        (0, _improvedSearchHelpers.sendRequestLatencyToRum)(requestLatency, this.tabName);
        this.tags = result?.results;
        this.appEvents.trigger(_appEventsConfig.TAB_CONTENTS, {
          action: "results",
          value: this.tags,
          tab: this.tabName,
          pageNumber: this.pageNumber,
          hasReachedLastPage: result?.results.length < (this.pageNumber + 1) * this.siteSettings.improved_search_page_size,
          extractAnalyticsKeys: tag => tag.id
        });
      }).catch(e => {
        this.appEvents.trigger(_appEventsConfig.TAB_CONTENTS_ERROR, {
          error: e,
          tab: this.tabName
        });
      });
    }
    static #_6 = (() => dt7948.n(this.prototype, "getAllTags", [_object.action]))();
    getNextPage(args) {
      if (args.tab === this.tabName) {
        this.pageNumber++;
        this.getAllTags();
      }
    }
  }
  _exports.default = TagsTab;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, TagsTab);
});