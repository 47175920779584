define("discourse/plugins/discourse-global-communities/discourse/lib/helpers/admin/bulk-admin-actions/conduct-update-user-attributes", ["exports", "rsvp", "discourse/plugins/discourse-global-communities/discourse/helpers/config/communityStage", "discourse/plugins/discourse-global-communities/discourse/lib/helpers/bulk-admin-helpers", "discourse/plugins/discourse-global-communities/discourse/lib/helpers/admin/users/update-user-attributes", "discourse/plugins/discourse-global-communities/discourse/lib/helpers/admin/bulk-admin-actions/constants/bulk-admin-constants"], function (_exports, _rsvp, _communityStage, _bulkAdminHelpers, _updateUserAttributes, _bulkAdminConstants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.conductUpdateUserAttributes = void 0;
  /**
   * Method to bulk call updateUserAttributes API from a given CSV file
   *
   * interface Args {
   *   errorMessage: string;
   *   parsedSuccessfully: boolean;
   *   username: string;
   *   rawCSV: string;
   *   ...{AttributeName}: string;
   * }
   *
   * interface Response {
   *   successCount: number;
   *   failureCount: number;
   *   failureList: String[];
   *   failureListCsv: String[],
   * }
   * @param {Args} parsedCsvFile
   * @returns {Response}
   */
  const conductUpdateUserAttributes = async parsedCsvFile => {
    const updateUserAttributeResults = await parsedCsvFile.reduce(async (prev, entry, index) => {
      const rowNumber = (0, _bulkAdminHelpers.getRowIteratorOffset)(index);
      const result = await prev;
      if (!entry.parsedSuccessfully) {
        result.failureCount++;
        result.failureList.push((0, _bulkAdminHelpers.getRowMessageFromError)(rowNumber, entry.rawCSV, entry.errorMessage));
        result.failureListCsv.push((0, _bulkAdminHelpers.getCsvRowWithErrorColumn)(entry.rawCSV, rowNumber, entry.errorMessage));
        return result;
      }
      let commonParsedColumns = Object.values(_bulkAdminConstants.BULK_ADMIN_ACTION_PARSED_KEY_MAP);
      let userAttributesToUpdate = {};
      Object.entries(entry).forEach(_ref => {
        let [key, value] = _ref;
        if (!commonParsedColumns.includes(key)) {
          userAttributesToUpdate[key] = value;
        }
      });
      try {
        const updateUserAttributeResult = await (0, _updateUserAttributes.updateUserAttributes)((0, _communityStage.getCommunityName)(), (0, _communityStage.getStageName)(), entry.username, userAttributesToUpdate);
        if (updateUserAttributeResult) {
          result.successCount++;
        }
      } catch (error) {
        result.failureCount++;
        result.failureList.push((0, _bulkAdminHelpers.getRowMessageFromError)(rowNumber, entry.rawCSV, error));
        result.failureListCsv.push((0, _bulkAdminHelpers.getCsvRowWithErrorColumn)(entry.rawCSV, rowNumber, error));
        return result;
      }
      return result;
    }, _rsvp.Promise.resolve({
      successCount: 0,
      failureCount: 0,
      failureList: [],
      failureListCsv: []
    }));
    return updateUserAttributeResults;
  };
  _exports.conductUpdateUserAttributes = conductUpdateUserAttributes;
});