define("discourse/plugins/discourse-global-communities/discourse/templates/connectors/topic-navigation-bottom/desktop-suggested-posts", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if this.shouldRender}}
    <SuggestedPosts />
  {{/if}}
  */
  {
    "id": "invB+NJQ",
    "block": "[[[41,[30,0,[\"shouldRender\"]],[[[1,\"  \"],[8,[39,1],null,null,null],[1,\"\\n\"]],[]],null]],[],false,[\"if\",\"suggested-posts\"]]",
    "moduleName": "discourse/plugins/discourse-global-communities/discourse/templates/connectors/topic-navigation-bottom/desktop-suggested-posts.hbs",
    "isStrictMode": false
  });
});