define("discourse/plugins/discourse-global-communities/discourse/components/post-intent-chooser-wrapper", ["exports", "@glimmer/component", "@ember/object", "@ember/service", "discourse/plugins/discourse-global-communities/discourse/components/post-intent-chooser", "@ember/component", "@ember/template-factory"], function (_exports, _component, _object, _service, _postIntentChooser, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class PostIntentChooserWrapper extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "composer", [_service.service]))();
    #composer = (() => (dt7948.i(this, "composer"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "appEvents", [_service.service]))();
    #appEvents = (() => (dt7948.i(this, "appEvents"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "currentUser", [_service.service]))();
    #currentUser = (() => (dt7948.i(this, "currentUser"), void 0))();
    get shouldRender() {
      // Return early and don't render if we are not creating a new topic or editing first post
      if (!this.args.model.editingFirstPost && !this.args.model.creatingTopic) {
        return false;
      }
      return this.currentUser.agcFeatureFlags.agc_post_intent_enabled;
    }
    setPostIntent(value) {
      // Set the post intent on the composer model so that it can be accessed in the PostIntent PluginInitializer
      // to set/update the DiscourseGlobalCommunity::POST_INTENT custom field value
      this.composer.model.set("agcPostIntent", value);
      // we need to re-trigger the composer-service:last-validated-at-updated event to remove the validation error if it
      // should no longer exist
      this.appEvents.trigger("composer-service:last-validated-at-updated", {
        model: this.composer.model
      });
    }
    static #_4 = (() => dt7948.n(this.prototype, "setPostIntent", [_object.action]))();
    static #_5 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        {{#if this.shouldRender}}
          <PostIntentChooser
            @value={{@model.topic.agc_post_intent}}
            @setPostIntent={{this.setPostIntent}}
          />
        {{/if}}
      
    */
    {
      "id": "UZSNiUhy",
      "block": "[[[1,\"\\n\"],[41,[30,0,[\"shouldRender\"]],[[[1,\"      \"],[8,[32,0],null,[[\"@value\",\"@setPostIntent\"],[[30,1,[\"topic\",\"agc_post_intent\"]],[30,0,[\"setPostIntent\"]]]],null],[1,\"\\n\"]],[]],null],[1,\"  \"]],[\"@model\"],false,[\"if\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-global-communities/discourse/components/post-intent-chooser-wrapper.js",
      "scope": () => [_postIntentChooser.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = PostIntentChooserWrapper;
});