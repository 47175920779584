define("discourse/plugins/discourse-global-communities/discourse/components/agc/ai-summary/content", ["exports", "@glimmer/component", "@ember/service", "discourse/components/cook-text", "discourse-i18n", "@ember/component", "@ember/template-factory"], function (_exports, _component, _service, _cookText, _discourseI18n, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class Content extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "agcAiSummary", [_service.service]))();
    #agcAiSummary = (() => (dt7948.i(this, "agcAiSummary"), void 0))();
    static #_2 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <div>
          {{#if @summaryNotAvailable}}
            <div class="content-section">
              {{i18n "agc.ai.ai_summary_not_available"}}
            </div>
          {{else}}
            {{#if @summary}}
              <div class="generated-summary cooked">
                <CookText @rawText={{@summary}} />
              </div>
            {{else}}
              TODO: No summary generated
            {{/if}}
          {{/if}}
        </div>
      
    */
    {
      "id": "0PMNLjGx",
      "block": "[[[1,\"\\n    \"],[10,0],[12],[1,\"\\n\"],[41,[30,1],[[[1,\"        \"],[10,0],[14,0,\"content-section\"],[12],[1,\"\\n          \"],[1,[28,[32,0],[\"agc.ai.ai_summary_not_available\"],null]],[1,\"\\n        \"],[13],[1,\"\\n\"]],[]],[[[41,[30,2],[[[1,\"          \"],[10,0],[14,0,\"generated-summary cooked\"],[12],[1,\"\\n            \"],[8,[32,1],null,[[\"@rawText\"],[[30,2]]],null],[1,\"\\n          \"],[13],[1,\"\\n\"]],[]],[[[1,\"          TODO: No summary generated\\n\"]],[]]]],[]]],[1,\"    \"],[13],[1,\"\\n  \"]],[\"@summaryNotAvailable\",\"@summary\"],false,[\"if\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-global-communities/discourse/components/agc/ai-summary/content.js",
      "scope": () => [_discourseI18n.i18n, _cookText.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = Content;
});