define("discourse/plugins/discourse-global-communities/discourse/lib/helpers/admin/bulk-admin-actions/constants/bulk-admin-constants", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.USERNAME_COLUMN_IDX = _exports.MAX_FILE_SIZE = _exports.HEADER_LINE_IDX = _exports.BULK_ADMIN_USER_NOT_FOUND_ERROR = _exports.BULK_ADMIN_ACTION_PARSED_KEY_MAP = _exports.BULK_ADMIN_ACTIONS_IDS = _exports.BULK_ADMIN_ACTIONS = _exports.ATTRIBUTE_TO_UPDATE_COLUMN_IDX = _exports.ACTION_TO_COLUMNS_MAP = void 0;
  /**
   * Line that header is in (the first line in csv)
   */
  const HEADER_LINE_IDX = _exports.HEADER_LINE_IDX = 0;

  /**
   * Username index in the header (first column)
   */
  const USERNAME_COLUMN_IDX = _exports.USERNAME_COLUMN_IDX = 0;

  /**
   * Column number of the attribute
   */
  const ATTRIBUTE_TO_UPDATE_COLUMN_IDX = _exports.ATTRIBUTE_TO_UPDATE_COLUMN_IDX = 1;

  /**
   * Max file size for uploading 500kb
   */
  const MAX_FILE_SIZE = _exports.MAX_FILE_SIZE = 500000;
  const BULK_ADMIN_ACTIONS_IDS = _exports.BULK_ADMIN_ACTIONS_IDS = {
    SUSPEND_USER: "suspendUser",
    UPDATE_USER_GROUPS: "updateUserGroups",
    UPDATE_USER_ATTRIBUTES: "updateUserAttributes"
  };

  /**
   * Add additional admin actions here and be sure to follow the object interface
   *
   * interface AdminAction {
   *   id: string;
   *   value: string;
   *   label: string;
   *   link: string;
   * }
   */
  const BULK_ADMIN_ACTIONS = _exports.BULK_ADMIN_ACTIONS = [{
    id: BULK_ADMIN_ACTIONS_IDS.SUSPEND_USER,
    value: BULK_ADMIN_ACTIONS_IDS.SUSPEND_USER,
    label: "Suspend User",
    link: "https://amazon.awsapps.com/workdocs/index.html#/document/b0f8f2696c8db4891140205dfabfebddc52c26666ee91f8e9680ed03ce3c8634"
  }, {
    id: BULK_ADMIN_ACTIONS_IDS.UPDATE_USER_GROUPS,
    value: BULK_ADMIN_ACTIONS_IDS.UPDATE_USER_GROUPS,
    label: "Update user groups",
    link: "https://amazon.awsapps.com/workdocs/index.html#/document/054c0c5ede4d555225c35b55008d67d5b60dc5a703dfd0463a8a7c7888a0a5d7"
  }, {
    id: BULK_ADMIN_ACTIONS_IDS.UPDATE_USER_ATTRIBUTES,
    value: BULK_ADMIN_ACTIONS_IDS.UPDATE_USER_ATTRIBUTES,
    label: "Update user attributes",
    link: "https://amazon.awsapps.com/workdocs/index.html#/document/b33d2c9ad3298e12f57b0184c990bf069f913619c3536ba9a5601a55ada83b8c"
  }];

  /**
   * Key map for expected keys in every parsed user action
   */
  const BULK_ADMIN_ACTION_PARSED_KEY_MAP = _exports.BULK_ADMIN_ACTION_PARSED_KEY_MAP = {
    USERNAME: "username",
    RAW_CSV: "rawCSV",
    PARSED_SUCCESSFULLY: "parsedSuccessfully",
    ERROR_MESSAGE: "errorMessage"
  };

  /**
   * For transforming user action selected key to expected columns
   */
  const ACTION_TO_COLUMNS_MAP = _exports.ACTION_TO_COLUMNS_MAP = {
    suspendUser: ["username", "suspendDate", "suspensionReason"],
    updateUserGroups: ["username", "removeGroups", "addGroups"]
  };

  /**
   * to display and save in downloaded CSV file to indicate error
   */
  const BULK_ADMIN_USER_NOT_FOUND_ERROR = _exports.BULK_ADMIN_USER_NOT_FOUND_ERROR = "User not found";
});