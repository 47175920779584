define("discourse/plugins/discourse-global-communities/discourse/controllers/idf-index", ["exports", "@ember/controller", "@ember/object", "@ember/object/computed", "discourse/lib/decorators", "discourse/plugins/discourse-global-communities/discourse/lib/idf-events"], function (_exports, _controller, _object, _computed, _decorators, _idfEvents) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class IdfIndexController extends _controller.default {
    disabled = false;
    static #_ = (() => dt7948.g(this.prototype, "reminderSet", [(0, _computed.equal)("nextEvent.watching_invitee.status", "going")]))();
    #reminderSet = (() => (dt7948.i(this, "reminderSet"), void 0))();
    setEventStatus(event, status) {
      this.set("disabled", true);
      const promise = event.watching_invitee ? this.store.update("discourse-post-event-invitee", event.watching_invitee.id, {
        post_id: event.id,
        status
      }) : this.store.createRecord("discourse-post-event-invitee").save({
        post_id: event.id,
        status
      });
      return promise.then(result => event.set("watching_invitee", result.payload)).finally(() => this.set("disabled", false));
    }
    setReminder() {
      return this.setEventStatus(this.nextEvent, "going");
    }
    static #_2 = (() => dt7948.n(this.prototype, "setReminder", [_object.action]))();
    unsetReminder() {
      return this.setEventStatus(this.nextEvent, "not_going");
    }
    static #_3 = (() => dt7948.n(this.prototype, "unsetReminder", [_object.action]))();
    currentEvent(events) {
      return events.find(_idfEvents.isEventActive);
    }
    static #_4 = (() => dt7948.n(this.prototype, "currentEvent", [(0, _decorators.default)("model.events")]))();
    nextEvent(events) {
      return events.filter(_idfEvents.isEventNext).sort((a, b) => new Date(a.starts_at) - new Date(b.starts_at))[0];
    }
    static #_5 = (() => dt7948.n(this.prototype, "nextEvent", [(0, _decorators.default)("model.events")]))();
    endOfEventTopicUrl(currentEvent, nextEvent) {
      if (currentEvent || nextEvent) {
        return;
      }
      const topicId = parseInt(this.siteSettings.idf_end_topic_id, 10);
      if (topicId) {
        return `/t/${topicId}`;
      }
    }
    static #_6 = (() => dt7948.n(this.prototype, "endOfEventTopicUrl", [(0, _decorators.default)("currentEvent", "nextEvent")]))();
    tick() {
      if (this.currentEvent) {
        if (moment((0, _idfEvents.getEventEnd)(this.currentEvent)).diff(moment()) <= 0) {
          this.send("refreshModel");
        }
        return;
      }

      // Update the timer if waiting for the next event
      if (this.nextEvent) {
        this.notifyPropertyChange("remainingTime");
        if (this.remainingTime <= (0, _idfEvents.prePostPeriod)(this.nextEvent)) {
          this.send("refreshModel");
        }
        return;
      }

      // Refresh model if none of the current events are active and there are no
      // more events.
      if (this.model.has_more_events) {
        this.model.set("has_more_events", false);
        return this.send("refreshModel");
      }
    }
    static #_7 = (() => dt7948.n(this.prototype, "tick", [_object.action]))();
    remainingTime(nextStartsAt) {
      return moment(nextStartsAt).diff(moment());
    }
    static #_8 = (() => dt7948.n(this.prototype, "remainingTime", [(0, _decorators.default)("nextEvent.starts_at")]))();
    remainingTimeDigits(remainingTime) {
      if (remainingTime <= 0) {
        return [0, 0, 0, 0, 0, 0];
      }
      const duration = moment.duration(remainingTime);
      let result = [];
      let hours = duration.hours() + duration.days() * 24;
      [hours, duration.minutes(), duration.seconds()].forEach(i => {
        result = result.concat(i.toString().padStart(2, "0").split(""));
      });
      return result;
    }
    static #_9 = (() => dt7948.n(this.prototype, "remainingTimeDigits", [(0, _decorators.default)("remainingTime")]))();
    links() {
      return this.siteSettings.idf_links.split("|").filter(Boolean).map(link => {
        const parts = link.split(",");
        return {
          title: parts[0],
          href: parts[1],
          agcAnalyticsRef: parts[2],
          icon: parts[3]
        };
      });
    }
    static #_10 = (() => dt7948.n(this.prototype, "links", [(0, _decorators.default)()]))();
  }
  _exports.default = IdfIndexController;
});