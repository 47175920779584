define("discourse/plugins/discourse-global-communities/discourse/lib/helpers/groups/get-all-discourse-groups", ["exports", "discourse/lib/ajax"], function (_exports, _ajax) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.getAllDiscourseGroups = void 0;
  /**
   * Retrieves all groups on the discourse instance the current user can retrieve.
   *
   * @returns {Promise<*>}  List of all groups with individual group details.
   */
  const getAllDiscourseGroups = async () => {
    return await (0, _ajax.ajax)(`/groups/search.json`, {
      contentType: "application/json",
      dataType: "json",
      type: "GET"
    });
  };
  _exports.getAllDiscourseGroups = getAllDiscourseGroups;
});