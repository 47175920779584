define("discourse/plugins/discourse-global-communities/discourse/components/modal/escalation", ["exports", "@ember/component", "@glimmer/component", "@ember/object", "@ember/service", "discourse/plugins/discourse-global-communities/discourse/config/app-events-config", "@ember/template-factory"], function (_exports, _component, _component2, _object, _service, _appEventsConfig, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <DModal
    @dismissable={{false}}
    @closeModal={{@closeModal}}
    class="escalation-modal"
  >
    <:aboveHeader>
      <div class="modal-header">
        <h3 id="discourse-modal-title">Escalate Topic</h3>
        <button
          type="button"
          class="escalation-modal-close-button"
          {{action this.closeModal}}
        ></button>
      </div>
    </:aboveHeader>
    <:body>
      <EscalationModalContent />
    </:body>
  </DModal>
  */
  {
    "id": "P6sFf1rz",
    "block": "[[[8,[39,0],[[24,0,\"escalation-modal\"]],[[\"@dismissable\",\"@closeModal\"],[false,[30,1]]],[[\"aboveHeader\",\"body\"],[[[[1,\"\\n    \"],[10,0],[14,0,\"modal-header\"],[12],[1,\"\\n      \"],[10,\"h3\"],[14,1,\"discourse-modal-title\"],[12],[1,\"Escalate Topic\"],[13],[1,\"\\n      \"],[11,\"button\"],[24,0,\"escalation-modal-close-button\"],[24,4,\"button\"],[4,[38,5],[[30,0],[30,0,[\"closeModal\"]]],null],[12],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[]],[[[1,\"\\n    \"],[8,[39,7],null,null,null],[1,\"\\n  \"]],[]]]]]],[\"@closeModal\"],false,[\"d-modal\",\":aboveHeader\",\"div\",\"h3\",\"button\",\"action\",\":body\",\"escalation-modal-content\"]]",
    "moduleName": "discourse/plugins/discourse-global-communities/discourse/components/modal/escalation.hbs",
    "isStrictMode": false
  });
  class Escalation extends _component2.default {
    static #_ = (() => dt7948.g(this.prototype, "appEvents", [_service.service]))();
    #appEvents = (() => (dt7948.i(this, "appEvents"), void 0))();
    closeModal() {
      this.appEvents.trigger(_appEventsConfig.ESCALATION_MODAL_CLOSE);
    }
    static #_2 = (() => dt7948.n(this.prototype, "closeModal", [_object.action]))();
  }
  _exports.default = Escalation;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, Escalation);
});