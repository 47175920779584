define("discourse/plugins/discourse-global-communities/discourse/lib/helpers/users/get-user-by-username", ["exports", "discourse/lib/ajax"], function (_exports, _ajax) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.getUserByUsername = void 0;
  /**
   * Gets a Discourse user by username.
   *
   * @param username
   * @returns {Promise<*>}
   */
  const getUserByUsername = async username => {
    return await (0, _ajax.ajax)(`/u/${username}.json`, {
      contentType: "application/json",
      dataType: "json",
      type: "GET"
    });
  };
  _exports.getUserByUsername = getUserByUsername;
});