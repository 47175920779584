define("discourse/plugins/discourse-global-communities/discourse/components/agc-radio-group", ["exports", "@ember/component", "@ember-decorators/component", "@ember/template-factory"], function (_exports, _component, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{! template-lint-disable no-duplicate-id  }}
  {{#each @options as |option|}}
    <div class="agc-radio-button-group">
      <input
        type="radio"
        id={{option.id}}
        value={{option.value}}
        checked={{eq @choice option.value}}
        {{on "click" @updateChoice}}
      />
      <div
        class="agc-radio-button"
        role="button"
        id={{option.id}}
        value={{option.value}}
        checked={{eq @choice option.value}}
        {{on "click" @updateChoice}}
        selected={{eq @choice option.value}}
      ></div>
      <div>
        <label for={{option.id}}>{{option.label}}</label>
        <a href={{option.link}} target="_blank" rel="noopener noreferrer">View
          template</a>
      </div>
    </div>
  {{/each}}
  */
  {
    "id": "H2NbAJRp",
    "block": "[[[42,[28,[37,1],[[28,[37,1],[[30,1]],null]],null],null,[[[1,\"  \"],[10,0],[14,0,\"agc-radio-button-group\"],[12],[1,\"\\n    \"],[11,\"input\"],[16,1,[30,2,[\"id\"]]],[16,2,[30,2,[\"value\"]]],[16,\"checked\",[28,[37,4],[[30,3],[30,2,[\"value\"]]],null]],[24,4,\"radio\"],[4,[38,5],[\"click\",[30,4]],null],[12],[13],[1,\"\\n    \"],[11,0],[24,0,\"agc-radio-button\"],[24,\"role\",\"button\"],[16,1,[30,2,[\"id\"]]],[16,2,[30,2,[\"value\"]]],[16,\"checked\",[28,[37,4],[[30,3],[30,2,[\"value\"]]],null]],[16,\"selected\",[28,[37,4],[[30,3],[30,2,[\"value\"]]],null]],[4,[38,5],[\"click\",[30,4]],null],[12],[13],[1,\"\\n    \"],[10,0],[12],[1,\"\\n      \"],[10,\"label\"],[15,\"for\",[30,2,[\"id\"]]],[12],[1,[30,2,[\"label\"]]],[13],[1,\"\\n      \"],[10,3],[15,6,[30,2,[\"link\"]]],[14,\"target\",\"_blank\"],[14,\"rel\",\"noopener noreferrer\"],[12],[1,\"View\\n        template\"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[2]],null]],[\"@options\",\"option\",\"@choice\",\"@updateChoice\"],false,[\"each\",\"-track-array\",\"div\",\"input\",\"eq\",\"on\",\"label\",\"a\"]]",
    "moduleName": "discourse/plugins/discourse-global-communities/discourse/components/agc-radio-group.hbs",
    "isStrictMode": false
  });
  const AgcRadioGroup = dt7948.c(class AgcRadioGroup extends _component.default {}, [(0, _component2.classNames)("agc-radio-group")]);
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, AgcRadioGroup);
});