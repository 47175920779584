define("discourse/plugins/discourse-global-communities/discourse/connectors/composer-open/composer-fields", ["exports", "@glimmer/component", "@glimmer/tracking", "@ember/object", "@ember/service", "discourse-i18n"], function (_exports, _component, _tracking, _object, _service, _discourseI18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AGCComposerFields extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "agcToolbarDistributor", [_service.service]))();
    #agcToolbarDistributor = (() => (dt7948.i(this, "agcToolbarDistributor"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "composer", [_service.service]))();
    #composer = (() => (dt7948.i(this, "composer"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "currentUser", [_service.service]))();
    #currentUser = (() => (dt7948.i(this, "currentUser"), void 0))();
    static #_4 = (() => dt7948.g(this.prototype, "site", [_service.service]))();
    #site = (() => (dt7948.i(this, "site"), void 0))();
    static #_5 = (() => dt7948.g(this.prototype, "siteSettings", [_service.service]))();
    #siteSettings = (() => (dt7948.i(this, "siteSettings"), void 0))();
    static #_6 = (() => dt7948.g(this.prototype, "toolbar", [_tracking.tracked]))();
    #toolbar = (() => (dt7948.i(this, "toolbar"), void 0))();
    static #_7 = (() => dt7948.g(this.prototype, "extraToolbarButtons", [_tracking.tracked]))();
    #extraToolbarButtons = (() => (dt7948.i(this, "extraToolbarButtons"), void 0))();
    isUploading = this.composer.isUploading;
    constructor(owner, args) {
      super(owner, args);
      this.toolbar = this.agcToolbarDistributor?.toolbar;
    }
    get renderPostIntentField() {
      if (!this.composer.model.editingFirstPost && !this.composer.model.creatingTopic) {
        return false;
      }
      return this.currentUser.agcFeatureFlags.agc_post_intent_enabled;
    }
    get postIntentHeaderText() {
      let text = (0, _discourseI18n.i18n)("agc.composer.post_intent_header");
      if (this.siteSettings.agc_post_intent_required) {
        text += "*";
      }
      return text;
    }
    get categoryHeaderText() {
      let text = (0, _discourseI18n.i18n)("agc.composer.category_header");
      text += "*";
      return text;
    }
    get topicTitleHeaderText() {
      let text = (0, _discourseI18n.i18n)("agc.composer.topic_header");
      text += "*";
      return text;
    }
    get editorHeaderText() {
      let text = (0, _discourseI18n.i18n)("agc.composer.content_header");
      text += "*";
      return text;
    }
    setExtraToolbarButtons() {
      this.toolbar = this.agcToolbarDistributor?.toolbar;

      /**
       * There should only be a single button group set from the
       * _setStreamlinePostingToolbarButtons method in the composer initializer
       *
       * The extraToolbarButtons are supposed to be the last 4 buttons.
       * After editing the order, the last four are upload media, upload file,
       *   add links, and add polls.
       * Upload media and upload file have the same id, "upload", when I'm
       *   reordering In mobile, "upload" is normally kept outside as defined here.
       * So I only have to grab the last two from the toolbar class
       * */
      const toolbarButtons = this.toolbar?.groups[0]?.buttons;
      this.extraToolbarButtons = toolbarButtons?.slice(toolbarButtons.length - 2);
    }
    static #_8 = (() => dt7948.n(this.prototype, "setExtraToolbarButtons", [_object.action]))();
    get showCategoryExpertContainer() {
      const model = this.composer.model;
      if (!model.category?.allowingCategoryExpertQuestions) {
        return false;
      }
      return model.editingFirstPost || model.creatingTopic;
    }
    static #_9 = (() => dt7948.n(this.prototype, "showCategoryExpertContainer", [(0, _object.computed)("composer.model", "composer.model.category")]))();
  }
  _exports.default = AGCComposerFields;
});