define("discourse/plugins/discourse-global-communities/discourse/components/agc-combo-box-with-description-row", ["exports", "select-kit/components/select-kit/select-kit-row", "@ember/component", "@ember/template-factory"], function (_exports, _selectKitRow, _component, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AgcComboBoxWithDescriptionRow extends _selectKitRow.default {
    static #_ = (() => (0, _component.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <span class="name">
          {{this.item.title}}
        </span>
        {{#if this.item.description}}
          <span class="desc">{{this.item.description}}</span>
        {{/if}}
      
    */
    {
      "id": "eu2uuN2/",
      "block": "[[[1,\"\\n    \"],[10,1],[14,0,\"name\"],[12],[1,\"\\n      \"],[1,[30,0,[\"item\",\"title\"]]],[1,\"\\n    \"],[13],[1,\"\\n\"],[41,[30,0,[\"item\",\"description\"]],[[[1,\"      \"],[10,1],[14,0,\"desc\"],[12],[1,[30,0,[\"item\",\"description\"]]],[13],[1,\"\\n\"]],[]],null],[1,\"  \"]],[],false,[\"if\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-global-communities/discourse/components/agc-combo-box-with-description-row.js",
      "isStrictMode": true
    }), this))();
  }
  _exports.default = AgcComboBoxWithDescriptionRow;
});