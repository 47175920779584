define("discourse/plugins/discourse-global-communities/discourse/components/agc/ai-summary/sheet-wrapper", ["exports", "@glimmer/component", "@ember/service", "discourse/plugins/discourse-global-communities/discourse/components/agc/ai-summary/bottom-sheet", "discourse/plugins/discourse-global-communities/discourse/components/agc/ai-summary/sidebar", "@ember/component", "@ember/template-factory"], function (_exports, _component, _service, _bottomSheet, _sidebar, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class SheetWrapper extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "currentUser", [_service.service]))();
    #currentUser = (() => (dt7948.i(this, "currentUser"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "site", [_service.service]))();
    #site = (() => (dt7948.i(this, "site"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "agcAiSummary", [_service.service]))();
    #agcAiSummary = (() => (dt7948.i(this, "agcAiSummary"), void 0))();
    willDestroy() {
      super.willDestroy();
      this.agcAiSummary.closeSheet();
    }
    get sheetComponent() {
      return this.site.desktopView ? _sidebar.default : _bottomSheet.default;
    }
    get summary() {
      return this.args.topic.latest_topic_summary;
    }
    get summaryNotAvailable() {
      return this.args.topic.agc_hide_ai_topic_summary;
    }
    static #_4 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        {{#if this.agcAiSummary.open}}
          <this.sheetComponent
            @summary={{this.summary}}
            @summaryNotAvailable={{this.summaryNotAvailable}}
          />
        {{/if}}
      
    */
    {
      "id": "99yIMndX",
      "block": "[[[1,\"\\n\"],[41,[30,0,[\"agcAiSummary\",\"open\"]],[[[1,\"      \"],[8,[30,0,[\"sheetComponent\"]],null,[[\"@summary\",\"@summaryNotAvailable\"],[[30,0,[\"summary\"]],[30,0,[\"summaryNotAvailable\"]]]],null],[1,\"\\n\"]],[]],null],[1,\"  \"]],[],false,[\"if\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-global-communities/discourse/components/agc/ai-summary/sheet-wrapper.js",
      "isStrictMode": true
    }), this))();
  }
  _exports.default = SheetWrapper;
});