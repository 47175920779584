define("discourse/plugins/discourse-global-communities/discourse/components/agc-escalation-form", ["exports", "@glimmer/component", "@glimmer/tracking", "@ember/helper", "@ember/modifier", "discourse/components/d-button", "discourse/helpers/with-event-value", "@ember/component", "@ember/template-factory"], function (_exports, _component, _tracking, _helper, _modifier, _dButton, _withEventValue, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AgcEscalationForm extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "text", [_tracking.tracked], function () {
      return this.args.text;
    }))();
    #text = (() => (dt7948.i(this, "text"), void 0))();
    static #_2 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <div class="escalations__form">
          <textarea
            class="escalations__form__text"
            value={{this.text}}
            {{on "input" (withEventValue (fn (mut this.text)))}}
          />
    
          <div class="escalations__form__controls">
            <DButton
              class="btn-flat"
              @label="agc.escalations.cancel"
              @action={{@cancel}}
            />
            <DButton
              class="escalations-form-submit"
              @label="agc.escalations.submit"
              @action={{fn @submit this.text}}
            />
          </div>
        </div>
      
    */
    {
      "id": "5VbQxSCU",
      "block": "[[[1,\"\\n    \"],[10,0],[14,0,\"escalations__form\"],[12],[1,\"\\n      \"],[11,\"textarea\"],[24,0,\"escalations__form__text\"],[16,2,[30,0,[\"text\"]]],[4,[32,0],[\"input\",[28,[32,1],[[28,[32,2],[[28,[31,0],[[30,0,[\"text\"]]],null]],null]],null]],null],[12],[13],[1,\"\\n      \"],[10,0],[14,0,\"escalations__form__controls\"],[12],[1,\"\\n        \"],[8,[32,3],[[24,0,\"btn-flat\"]],[[\"@label\",\"@action\"],[\"agc.escalations.cancel\",[30,1]]],null],[1,\"\\n        \"],[8,[32,3],[[24,0,\"escalations-form-submit\"]],[[\"@label\",\"@action\"],[\"agc.escalations.submit\",[28,[32,2],[[30,2],[30,0,[\"text\"]]],null]]],null],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[\"@cancel\",\"@submit\"],false,[\"mut\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-global-communities/discourse/components/agc-escalation-form.js",
      "scope": () => [_modifier.on, _withEventValue.default, _helper.fn, _dButton.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = AgcEscalationForm;
});