define("discourse/plugins/discourse-global-communities/discourse/lib/helpers/bulk-admin-helpers", ["exports", "discourse/plugins/discourse-global-communities/discourse/lib/helpers/admin/bulk-admin-actions/constants/bulk-admin-constants", "discourse/plugins/discourse-global-communities/discourse/lib/helpers/groups/get-all-discourse-groups"], function (_exports, _bulkAdminConstants, _getAllDiscourseGroups) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.getRowMessageFromError = _exports.getRowIteratorOffset = _exports.getLabelForAction = _exports.getGroupNameToIdMap = _exports.getError = _exports.getCsvRowWithErrorColumn = _exports.buildResultsForAdminAction = _exports.buildPreprocessFailureForAdminAction = void 0;
  /**
   * Builds output for bulk admin action text.
   *
   * @param {*} action
   * @param {*} successCount
   * @param {*} failureCount
   * @param {*} failureList
   * @returns
   */
  const buildResultsForAdminAction = (action, apiResult) => {
    const {
      successCount,
      failureCount,
      failureList,
      failureListCsv
    } = apiResult;
    return {
      action,
      actionLabel: getLabelForAction(action),
      totalProcessed: successCount + failureCount,
      successCount,
      failureCount,
      failureList,
      failureListCsv
    };
  };

  /**
   * Builds output for a preprocess failure.
   *
   * @param {*} action
   * @param {*} reason
   * @returns
   */
  _exports.buildResultsForAdminAction = buildResultsForAdminAction;
  const buildPreprocessFailureForAdminAction = (action, reason) => {
    return {
      action,
      actionLabel: `${getLabelForAction(action)} Failed`,
      parseFail: `${reason}`
    };
  };

  /**
   * Retrieves action label from action value.
   *
   * @param {*} action
   * @returns Action label
   */
  _exports.buildPreprocessFailureForAdminAction = buildPreprocessFailureForAdminAction;
  const getLabelForAction = action => {
    let result = "";
    _bulkAdminConstants.BULK_ADMIN_ACTIONS.forEach(actionType => {
      if (action === actionType.value) {
        result = actionType.label;
      }
    });
    return result;
  };

  /**
   * @param {number} rowNumber - determines what row this error was from
   * @param {string} rawCsv - string that came from preprocessing a csv file
   * @param {Discourse JSON response errorType} error
   * @returns
   */
  _exports.getLabelForAction = getLabelForAction;
  const getRowMessageFromError = (rowNumber, rawCsv, error) => {
    let message = getError(error);
    // TODO: Return row message as well as new rawCsv with error messaging.
    // https://sim.amazon.com/issues/AGC-5150
    return `Row ${rowNumber}: ${rawCsv} - ${message}`;
  };

  /**
   * Generate an error message coming from Discourse's API calls.
   * If error is not a JSON response, utilize the message on the
   * error
   *
   * error.jqXHR.responseJSON is the path for Discourse errors
   *
   * We have seen two different error message types:
   * - .errors {String[]}
   * - .message {string}
   *
   * No documentation of any other so we default to 'An unknown error has occurred'
   *
   * See below links on how Discourse JSON response errors are utilized.
   * https://github.com/discourse/discourse/blob/64171730827c58df26a7ad75f0e58f17c2add118/lib/json_error.rb
   * https://github.com/search?q=org%3Adiscourse+%22failed_json%22&type=code
   *
   * @param {*} error
   * @returns String
   */
  _exports.getRowMessageFromError = getRowMessageFromError;
  const getError = error => {
    let message = "An unknown error has occurred";
    if (error instanceof Error || typeof error === "object") {
      if (error.jqXHR?.responseJSON) {
        message = error.jqXHR.responseJSON?.errors ? error.jqXHR.responseJSON.errors.join(",") : error.jqXHR.responseJSON.message;
      } else if (error.message) {
        message = error.message;
      }
    } else if (typeof error === "string" || error instanceof String) {
      message = error;
    }
    return message;
  };

  /**
   * This function is for creating each row of the CSV output. (the CSV with errors that gets downloaded)
   *
   * @param {string} rawCSV
   * @param {int} rowNumber
   * @param {string or list} error
   * @returns string
   */
  _exports.getError = getError;
  const getCsvRowWithErrorColumn = (rawCSV, rowNumber, error) => {
    return `${rawCSV},"Row: ${rowNumber}. ${getError(error)}"`;
  };

  /**
   * Returns +2 because of column headers row and iterator's 0 indexing
   */
  _exports.getCsvRowWithErrorColumn = getCsvRowWithErrorColumn;
  const getRowIteratorOffset = index => {
    return index + 2;
  };

  /**
   *
   * @returns {groupName: groupId}
   */
  _exports.getRowIteratorOffset = getRowIteratorOffset;
  const getGroupNameToIdMap = async () => {
    const allGroups = await (0, _getAllDiscourseGroups.getAllDiscourseGroups)();
    const groupNameToIdMap = {};
    allGroups.forEach(group => {
      groupNameToIdMap[group.name] = group.id;
    });
    return groupNameToIdMap;
  };
  _exports.getGroupNameToIdMap = getGroupNameToIdMap;
});