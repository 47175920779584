define("discourse/plugins/discourse-global-communities/discourse/controllers/members", ["exports", "@ember/controller", "@ember/object", "@ember/object/computed", "@ember/runloop", "@ember/utils", "@ember-decorators/object", "discourse/lib/ajax", "discourse/lib/decorators", "discourse-i18n"], function (_exports, _controller, _object, _computed, _runloop, _utils, _object2, _ajax, _decorators, _discourseI18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const MIN_SEARCH_LENGTH = 2;
  const ALL_DSPS = "all";
  class MembersController extends _controller.default {
    guideCategoryId = (() => ALL_DSPS)();
    searchTerm = null;
    loading = false;
    canLoadMore = true;
    stations = null;
    locations = null;
    filters = null;
    queryParams = ["station"];
    static #_ = (() => dt7948.g(this.prototype, "filterGroups", [(0, _computed.readOnly)("model.extras.filter_groups")]))();
    #filterGroups = (() => (dt7948.i(this, "filterGroups"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "selectedFilters", [(0, _computed.readOnly)("model.extras.selected_filters")]))();
    #selectedFilters = (() => (dt7948.i(this, "selectedFilters"), void 0))();
    categoryGuideFilterOptions(guideCategories) {
      if (!this.siteSettings.enable_category_experts || !guideCategories) {
        return false;
      }
      const options = [{
        value: ALL_DSPS,
        name: (0, _discourseI18n.i18n)("discourse_gc.no_category_guide")
      }];
      const skipAddGuidesToName = this.siteSettings.members_skip_adding_guides_to_categories.split("|").map(v => parseInt(v, 10));
      return options.concat(guideCategories.map(value => {
        return {
          value: value.id,
          name: skipAddGuidesToName.includes(value.id) ? value.name : (0, _discourseI18n.i18n)("discourse_gc.category_guide", {
            category_name: value.name
          })
        };
      }));
    }
    static #_3 = (() => dt7948.n(this.prototype, "categoryGuideFilterOptions", [(0, _decorators.default)("model.extras.guide_categories")]))();
    load() {
      let {
        resetPageNumber = false,
        search = false
      } = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      const params = {};
      if (this.siteSettings.enable_category_experts) {
        params.guideCategoryId = this.guideCategoryId;
      }
      const term = this.searchTerm || "";
      if (resetPageNumber) {
        this.set("page", 0);
      }
      params.page = this.page;
      if (search && term.length < MIN_SEARCH_LENGTH && term.length > 0) {
        return;
      }
      if (term.length >= MIN_SEARCH_LENGTH) {
        params.term = term;
      }
      if (!(0, _utils.isEmpty)(this.filterParams)) {
        params.filters = this.filterParams;
      }
      this.set("loading", true);
      (0, _ajax.ajax)("/members.json", {
        data: params
      }).then(json => {
        if (resetPageNumber) {
          this.set("model.members", json.members);
        } else {
          this.model.members.pushObjects(json.members);
        }
        this.set("canLoadMore", json.members.length === 24);
        this.set("loading", false);
      });
    }
    setLoadMore() {
      const members = this.get("model.members");
      this.set("canLoadMore", members.length === 24);
    }
    static #_4 = (() => dt7948.n(this.prototype, "setLoadMore", [(0, _object2.observes)("model.members"), (0, _object2.on)("didInsertElement")]))();
    onCategoryGuideFilterChange(val) {
      this.set("guideCategoryId", val);
      this.load({
        resetPageNumber: true
      });
    }
    static #_5 = (() => dt7948.n(this.prototype, "onCategoryGuideFilterChange", [_object.action]))();
    loadMore() {
      this.incrementProperty("page");
      this.load();
    }
    static #_6 = (() => dt7948.n(this.prototype, "loadMore", [_object.action]))();
    applyFilters(filters) {
      const filterParams = {};
      filters.forEach(filter => {
        if (filterParams[filter.type]) {
          filterParams[filter.type].push(filter.value);
        } else {
          filterParams[filter.type] = [filter.value];
        }
      });
      this.setProperties({
        filterParams
      });
      this.load({
        resetPageNumber: true
      });
    }
    static #_7 = (() => dt7948.n(this.prototype, "applyFilters", [_object.action]))();
    searchTermChanged() {
      (0, _runloop.debounce)(this, this.load, {
        resetPageNumber: true,
        search: true
      }, 250);
    }
    static #_8 = (() => dt7948.n(this.prototype, "searchTermChanged", [_object.action]))();
  }
  _exports.default = MembersController;
});