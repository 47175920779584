define("discourse/plugins/discourse-global-communities/discourse/components/agc/tv-episode", ["exports", "@glimmer/component", "@glimmer/tracking", "@ember/modifier", "@ember/object", "@ember/template", "discourse/helpers/concat-class", "discourse/helpers/d-icon", "discourse/lib/ajax", "discourse/lib/formatter", "discourse-i18n", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _tracking, _modifier, _object, _template, _concatClass, _dIcon, _ajax, _formatter, _discourseI18n, _component2, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const AgcTvEpisode = (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
    <div class="episode" data-topic-id={{@episode.id}}>
      <div class="tv-episode-container">
        <a href={{@episode.url}} class="episode-thumbnail">
          {{#if @episode.horizontal_image_url}}
            <img class="thumbnail-img" src={{@episode.horizontal_image_url}} />
          {{else}}
            <div class="placeholder" />
          {{/if}}
        </a>
        <div class="episode-details">
          <a href={{@episode.url}}>
            <h5 class="episode-title">
              {{@episode.title}}
            </h5>
          </a>
          {{#unless @episode.watched}}
            <div class="unwatched">
              {{i18n "agc.tv.unwatched"}}
            </div>
          {{/unless}}
          <div>
            <TvEpisodeActions @episode={{@episode}} />
          </div>
        </div>
        {{#if @renderExcerpt}}
          <a href={{@episode.url}}>
            <p class="episode-description">
              {{htmlSafe @episode.excerpt}}
            </p>
          </a>
        {{/if}}
      </div>
    </div>
  
  */
  {
    "id": "jrOoGWrB",
    "block": "[[[1,\"\\n  \"],[10,0],[14,0,\"episode\"],[15,\"data-topic-id\",[30,1,[\"id\"]]],[12],[1,\"\\n    \"],[10,0],[14,0,\"tv-episode-container\"],[12],[1,\"\\n      \"],[10,3],[15,6,[30,1,[\"url\"]]],[14,0,\"episode-thumbnail\"],[12],[1,\"\\n\"],[41,[30,1,[\"horizontal_image_url\"]],[[[1,\"          \"],[10,\"img\"],[14,0,\"thumbnail-img\"],[15,\"src\",[30,1,[\"horizontal_image_url\"]]],[12],[13],[1,\"\\n\"]],[]],[[[1,\"          \"],[10,0],[14,0,\"placeholder\"],[12],[13],[1,\"\\n\"]],[]]],[1,\"      \"],[13],[1,\"\\n      \"],[10,0],[14,0,\"episode-details\"],[12],[1,\"\\n        \"],[10,3],[15,6,[30,1,[\"url\"]]],[12],[1,\"\\n          \"],[10,\"h5\"],[14,0,\"episode-title\"],[12],[1,\"\\n            \"],[1,[30,1,[\"title\"]]],[1,\"\\n          \"],[13],[1,\"\\n        \"],[13],[1,\"\\n\"],[41,[51,[30,1,[\"watched\"]]],[[[1,\"          \"],[10,0],[14,0,\"unwatched\"],[12],[1,\"\\n            \"],[1,[28,[32,0],[\"agc.tv.unwatched\"],null]],[1,\"\\n          \"],[13],[1,\"\\n\"]],[]],null],[1,\"        \"],[10,0],[12],[1,\"\\n          \"],[8,[32,1],null,[[\"@episode\"],[[30,1]]],null],[1,\"\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n\"],[41,[30,2],[[[1,\"        \"],[10,3],[15,6,[30,1,[\"url\"]]],[12],[1,\"\\n          \"],[10,2],[14,0,\"episode-description\"],[12],[1,\"\\n            \"],[1,[28,[32,2],[[30,1,[\"excerpt\"]]],null]],[1,\"\\n          \"],[13],[1,\"\\n        \"],[13],[1,\"\\n\"]],[]],null],[1,\"    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[\"@episode\",\"@renderExcerpt\"],false,[\"if\",\"unless\"]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-global-communities/discourse/components/agc/tv-episode.js",
    "scope": () => [_discourseI18n.i18n, TvEpisodeActions, _template.htmlSafe],
    "isStrictMode": true
  }), (0, _templateOnly.default)(undefined, "tv-episode:AgcTvEpisode"));
  var _default = _exports.default = AgcTvEpisode;
  class TvEpisodeActions extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "loading", [_tracking.tracked], function () {
      return false;
    }))();
    #loading = (() => (dt7948.i(this, "loading"), void 0))();
    get likeIcon() {
      if (this.args.episode.topic_first_post_liked) {
        return "d-liked";
      } else {
        return this.args.episode.topic_post_can_like ? "d-unliked" : "heart-disabled-squid";
      }
    }
    static #_2 = (() => dt7948.n(this.prototype, "likeIcon", [(0, _object.computed)("args.episode.topic_first_post_liked", "args.episode.topic_post_can_like")]))();
    get likeCount() {
      return (0, _formatter.number)(this.args.episode.like_count);
    }
    static #_3 = (() => dt7948.n(this.prototype, "likeCount", [(0, _object.computed)("args.episode.like_count")]))();
    async likeClick() {
      if (this.loading) {
        return;
      }
      this.loading = true;
      const episode = this.args.episode;
      if (!episode.topic_first_post_liked && !episode.topic_post_can_like) {
        return;
      }
      const postId = episode.topic_first_post_id;
      const firstPostLiked = episode.topic_first_post_liked;
      const url = firstPostLiked ? `/post_actions/${postId}` : "/post_actions";
      const type = firstPostLiked ? "DELETE" : "POST";
      const data = firstPostLiked ? {
        post_action_type_id: 2
      } : {
        id: postId,
        post_action_type_id: 2
      };
      try {
        await (0, _ajax.ajax)(url, {
          type,
          data
        });
        const newLikeCount = firstPostLiked ? episode.like_count - 1 < 0 ? 0 : episode.like_count - 1 : episode.like_count + 1;
        episode.set("like_count", newLikeCount);
        episode.set("topic_first_post_liked", !firstPostLiked);
      } finally {
        this.loading = false;
      }
    }
    static #_4 = (() => dt7948.n(this.prototype, "likeClick", [_object.action]))();
    static #_5 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <div class="tv-episode-actions">
          {{! template-lint-disable no-invalid-interactive }}
          <span
            class={{concatClass
              "like"
              (if @episode.topic_post_can_like "can-click" "cant-click")
            }}
            {{on "click" this.likeClick}}
          >
            {{dIcon this.likeIcon}}
            <span class="like-count">
              {{this.likeCount}}
            </span>
          </span>
          <span class="views">
            {{dIcon "eye-squid"}}
            <span class="view-count">{{number @episode.views}}</span>
            <span class="views-label">{{i18n "agc.views"}}</span>
          </span>
        </div>
      
    */
    {
      "id": "cWElcX7D",
      "block": "[[[1,\"\\n    \"],[10,0],[14,0,\"tv-episode-actions\"],[12],[1,\"\\n\"],[1,\"      \"],[11,1],[16,0,[28,[32,0],[\"like\",[52,[30,1,[\"topic_post_can_like\"]],\"can-click\",\"cant-click\"]],null]],[4,[32,1],[\"click\",[30,0,[\"likeClick\"]]],null],[12],[1,\"\\n        \"],[1,[28,[32,2],[[30,0,[\"likeIcon\"]]],null]],[1,\"\\n        \"],[10,1],[14,0,\"like-count\"],[12],[1,\"\\n          \"],[1,[30,0,[\"likeCount\"]]],[1,\"\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n      \"],[10,1],[14,0,\"views\"],[12],[1,\"\\n        \"],[1,[28,[32,2],[\"eye-squid\"],null]],[1,\"\\n        \"],[10,1],[14,0,\"view-count\"],[12],[1,[28,[32,3],[[30,1,[\"views\"]]],null]],[13],[1,\"\\n        \"],[10,1],[14,0,\"views-label\"],[12],[1,[28,[32,4],[\"agc.views\"],null]],[13],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[\"@episode\"],false,[\"if\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-global-communities/discourse/components/agc/tv-episode.js",
      "scope": () => [_concatClass.default, _modifier.on, _dIcon.default, _formatter.number, _discourseI18n.i18n],
      "isStrictMode": true
    }), this))();
  }
});