define("discourse/plugins/discourse-global-communities/discourse/lib/helpers/admin/users/add-user-to-group", ["exports", "discourse/lib/ajax"], function (_exports, _ajax) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.addUserToGroup = void 0;
  /**
   * Add user to group
   *
   * @param {String} userId
   * @param {String} groupId
   * @returns Promise
   */

  const addUserToGroup = async (userId, groupId) => {
    if (!userId || !groupId) {
      throw new Error("userId and groupId are required.");
    }
    return await (0, _ajax.ajax)(`/admin/users/${userId}/groups`, {
      type: "POST",
      data: {
        group_id: groupId
      }
    });
  };
  _exports.addUserToGroup = addUserToGroup;
});