define("discourse/plugins/discourse-global-communities/discourse/connectors/edit-topic/post-intent", ["exports", "@glimmer/component", "@ember/object", "@ember/service", "discourse/plugins/discourse-global-communities/discourse/components/post-intent-chooser", "@ember/component", "@ember/template-factory"], function (_exports, _component, _object, _service, _postIntentChooser, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class EditTopicPostIntent extends _component.default {
    static shouldRender(args, context) {
      return context.currentUser.agcFeatureFlags.agc_post_intent_enabled;
    }
    static #_ = (() => dt7948.g(this.prototype, "siteSettings", [_service.service]))();
    #siteSettings = (() => (dt7948.i(this, "siteSettings"), void 0))();
    setPostIntent(value) {
      // Set the post intent on the composer model so that it can be accessed in the PostIntent PluginInitializer
      // to set/update the DiscourseGlobalCommunity::POST_INTENT custom field value
      this.args.outletArgs.buffered.set("agc_post_intent", value);
    }
    static #_2 = (() => dt7948.n(this.prototype, "setPostIntent", [_object.action]))();
    static #_3 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <PostIntentChooser
          @value={{@outletArgs.model.agc_post_intent}}
          @setPostIntent={{this.setPostIntent}}
        />
      
    */
    {
      "id": "LZwkABrV",
      "block": "[[[1,\"\\n    \"],[8,[32,0],null,[[\"@value\",\"@setPostIntent\"],[[30,1,[\"model\",\"agc_post_intent\"]],[30,0,[\"setPostIntent\"]]]],null],[1,\"\\n  \"]],[\"@outletArgs\"],false,[]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-global-communities/discourse/connectors/edit-topic/post-intent.js",
      "scope": () => [_postIntentChooser.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = EditTopicPostIntent;
});