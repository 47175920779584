define("discourse/plugins/discourse-global-communities/discourse/components/suggested-posts", ["exports", "@ember/component", "@glimmer/component", "@glimmer/tracking", "@ember/object", "rsvp", "discourse/plugins/discourse-global-communities/discourse/lib/topics/fetch-topic-by-id", "@ember/template-factory"], function (_exports, _component, _component2, _tracking, _object, _rsvp, _fetchTopicById, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="suggested-posts-container">
    {{#if this.loadingComponent}}
      <div class="spinner" />
    {{else}}
      <h2 class="suggested-posts-container-title">{{this.title}}</h2>
      {{#each this.suggestedPosts as |post|}}
        <div class="suggested-post">
          <div class="suggested-post-main-content">
            <a
              class="suggested-post-title"
              href={{post.title.link}}
            >{{post.title.displayTitle}}</a>
            {{#if post.isNew}}
              <div class="suggested-post-title-new-pill">new</div>
            {{/if}}
          </div>
          <div class="suggested-post-footer">
            <a
              class="suggested-post-author"
              href={{post.author.link}}
            >{{post.author.name}}</a>
            <p> • </p>
            <p class="suggested-post-views">{{post.views}} views</p>
          </div>
        </div>
      {{/each}}
  
      {{#if this.loadingMorePosts}}
        <div class="spinner" />
      {{else}}
        <p
          class="suggested-post-show-more-button"
          role="button"
          {{on "click" this.showMore}}
        >
          Show more
        </p>
      {{/if}}
    {{/if}}
  </div>
  */
  {
    "id": "OY84QcIB",
    "block": "[[[10,0],[14,0,\"suggested-posts-container\"],[12],[1,\"\\n\"],[41,[30,0,[\"loadingComponent\"]],[[[1,\"    \"],[10,0],[14,0,\"spinner\"],[12],[13],[1,\"\\n\"]],[]],[[[1,\"    \"],[10,\"h2\"],[14,0,\"suggested-posts-container-title\"],[12],[1,[30,0,[\"title\"]]],[13],[1,\"\\n\"],[42,[28,[37,4],[[28,[37,4],[[30,0,[\"suggestedPosts\"]]],null]],null],null,[[[1,\"      \"],[10,0],[14,0,\"suggested-post\"],[12],[1,\"\\n        \"],[10,0],[14,0,\"suggested-post-main-content\"],[12],[1,\"\\n          \"],[10,3],[14,0,\"suggested-post-title\"],[15,6,[30,1,[\"title\",\"link\"]]],[12],[1,[30,1,[\"title\",\"displayTitle\"]]],[13],[1,\"\\n\"],[41,[30,1,[\"isNew\"]],[[[1,\"            \"],[10,0],[14,0,\"suggested-post-title-new-pill\"],[12],[1,\"new\"],[13],[1,\"\\n\"]],[]],null],[1,\"        \"],[13],[1,\"\\n        \"],[10,0],[14,0,\"suggested-post-footer\"],[12],[1,\"\\n          \"],[10,3],[14,0,\"suggested-post-author\"],[15,6,[30,1,[\"author\",\"link\"]]],[12],[1,[30,1,[\"author\",\"name\"]]],[13],[1,\"\\n          \"],[10,2],[12],[1,\" • \"],[13],[1,\"\\n          \"],[10,2],[14,0,\"suggested-post-views\"],[12],[1,[30,1,[\"views\"]]],[1,\" views\"],[13],[1,\"\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n\"]],[1]],null],[1,\"\\n\"],[41,[30,0,[\"loadingMorePosts\"]],[[[1,\"      \"],[10,0],[14,0,\"spinner\"],[12],[13],[1,\"\\n\"]],[]],[[[1,\"      \"],[11,2],[24,0,\"suggested-post-show-more-button\"],[24,\"role\",\"button\"],[4,[38,7],[\"click\",[30,0,[\"showMore\"]]],null],[12],[1,\"\\n        Show more\\n      \"],[13],[1,\"\\n\"]],[]]]],[]]],[13]],[\"post\"],false,[\"div\",\"if\",\"h2\",\"each\",\"-track-array\",\"a\",\"p\",\"on\"]]",
    "moduleName": "discourse/plugins/discourse-global-communities/discourse/components/suggested-posts.hbs",
    "isStrictMode": false
  });
  class SuggestedPosts extends _component2.default {
    static #_ = (() => dt7948.g(this.prototype, "title", [_tracking.tracked], function () {
      return "";
    }))();
    #title = (() => (dt7948.i(this, "title"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "subtitle", [_tracking.tracked], function () {
      return "";
    }))();
    #subtitle = (() => (dt7948.i(this, "subtitle"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "suggestedPosts", [_tracking.tracked], function () {
      return [];
    }))();
    #suggestedPosts = (() => (dt7948.i(this, "suggestedPosts"), void 0))();
    static #_4 = (() => dt7948.g(this.prototype, "loadingComponent", [_tracking.tracked], function () {
      return true;
    }))();
    #loadingComponent = (() => (dt7948.i(this, "loadingComponent"), void 0))();
    static #_5 = (() => dt7948.g(this.prototype, "loadingMorePosts", [_tracking.tracked], function () {
      return false;
    }))();
    #loadingMorePosts = (() => (dt7948.i(this, "loadingMorePosts"), void 0))();
    constructor(owner, args) {
      super(owner, args);
      this.getSuggestedPosts();
    }
    async getSuggestedPosts() {
      // TODO: get suggested posts - https://sim.amazon.com/issues/AGC-5862
      const testTopicIdList = [3494, 3516, 3985, 3984, 8635];
      this.suggestedPosts = await _rsvp.Promise.all(testTopicIdList.map(async topicId => {
        try {
          const topic = await (0, _fetchTopicById.fetchTopicById)(topicId);
          const currentDate = new Date();
          const topicCreatedAtDate = new Date(topic.created_at);
          const timeDiff = currentDate.getTime() - topicCreatedAtDate.getTime();
          return {
            title: {
              displayTitle: topic.fancy_title,
              link: "/t/" + topic.slug
            },
            author: {
              name: topic.details.created_by.name,
              link: "/u/" + topic.details.created_by.username
            },
            views: topic.views,
            isNew: timeDiff / (1000 * 60 * 60 * 24) < 5 // calculating if timeDiff is less than 5 days
          };
        } catch (e) {
          throw new Error("Failed to get topic data for suggested posts", e);
        }
      }));
      this.suggestedPosts[4].author.name = "This is a really long name that should truncate because it should be longer than two lines but if it's not then that's really big sad";
      this.title = "Test results";
      this.loadingComponent = false;
    }
    async showMore() {
      // TODO: implement get more suggested posts - https://sim.amazon.com/issues/AGC-5864
      this.suggestedPosts = [...this.suggestedPosts, {
        title: {
          displayTitle: "CHECK OUT THIS TOTALLY AWESOME POST",
          link: "/"
        },
        author: {
          name: "Ignite team",
          link: "/"
        },
        views: 123,
        isNew: false
      }];
    }
    static #_6 = (() => dt7948.n(this.prototype, "showMore", [_object.action]))();
  }
  _exports.default = SuggestedPosts;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, SuggestedPosts);
});