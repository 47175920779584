define("discourse/plugins/discourse-global-communities/discourse/lib/helpers/config/feed-toggle-config", ["exports", "discourse-i18n"], function (_exports, _discourseI18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.FEED_TOGGLE_CONFIG = void 0;
  const FEED_TOGGLE_OPTIONS_IDS = {
    TOP: "top",
    LATEST: "latest"
  };

  /**
   * Config values for Feed Toggle.
   *
   * Follow the object interface for feed toggle options:
   *
   * interface FeedToggleOptions {
   *   id: string;
   *   label: string;
   * }
   *
   * Note that AgcToggle is meant for use with only two options.
   */
  const FEED_TOGGLE_CONFIG = _exports.FEED_TOGGLE_CONFIG = {
    ids: FEED_TOGGLE_OPTIONS_IDS,
    options: [{
      id: FEED_TOGGLE_OPTIONS_IDS.TOP,
      label: (0, _discourseI18n.i18n)("agc.feed-toggle.top-label"),
      setting: "categories_and_top_topics"
    }, {
      id: FEED_TOGGLE_OPTIONS_IDS.LATEST,
      label: (0, _discourseI18n.i18n)("agc.feed-toggle.latest-label"),
      setting: "categories_and_latest_topics_created_date"
    }]
  };
});