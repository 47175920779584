define("discourse/plugins/discourse-global-communities/discourse/components/escalation-modal-content", ["exports", "@glimmer/component", "@glimmer/tracking", "@ember/object", "@ember/service", "discourse/lib/ajax", "discourse/models/user", "discourse-i18n", "discourse/plugins/discourse-global-communities/discourse/config/app-events-config", "discourse/plugins/discourse-global-communities/discourse/helpers/config/communityStage", "discourse/plugins/discourse-global-communities/discourse/lib/topics/fetch-topic-by-id", "discourse/plugins/discourse-global-communities/discourse/lib/escalations/create-escalation", "discourse/plugins/discourse-global-communities/discourse/lib/helpers/api-helpers", "discourse/plugins/discourse-global-communities/discourse/lib/helpers/config/errors", "discourse/plugins/discourse-global-communities/discourse/lib/helpers/url-helpers", "discourse/plugins/discourse-global-communities/discourse/lib/topics/update-topic", "discourse/plugins/discourse-global-communities/discourse/config/escalation-form-config"], function (_exports, _component, _tracking, _object, _service, _ajax, _user, _discourseI18n, _appEventsConfig, _communityStage, _fetchTopicById, _createEscalation, _apiHelpers, _errors, _urlHelpers, _updateTopic, _escalationFormConfig) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /* global cwr */

  class EscalationModalContent extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "appEvents", [_service.service]))();
    #appEvents = (() => (dt7948.i(this, "appEvents"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "modal", [_service.service]))();
    #modal = (() => (dt7948.i(this, "modal"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "attemptingCancel", [_tracking.tracked], function () {
      return false;
    }))();
    #attemptingCancel = (() => (dt7948.i(this, "attemptingCancel"), void 0))();
    static #_4 = (() => dt7948.g(this.prototype, "isLoading", [_tracking.tracked], function () {
      return false;
    }))();
    #isLoading = (() => (dt7948.i(this, "isLoading"), void 0))();
    static #_5 = (() => dt7948.g(this.prototype, "displayResults", [_tracking.tracked], function () {
      return false;
    }))();
    #displayResults = (() => (dt7948.i(this, "displayResults"), void 0))();
    static #_6 = (() => dt7948.g(this.prototype, "isSuccess", [_tracking.tracked], function () {
      return false;
    }))();
    #isSuccess = (() => (dt7948.i(this, "isSuccess"), void 0))();
    static #_7 = (() => dt7948.g(this.prototype, "createdApprovals", [_tracking.tracked], function () {
      return [];
    }))();
    #createdApprovals = (() => (dt7948.i(this, "createdApprovals"), void 0))();
    static #_8 = (() => dt7948.g(this.prototype, "errorMessage", [_tracking.tracked], function () {
      return "";
    }))();
    #errorMessage = (() => (dt7948.i(this, "errorMessage"), void 0))();
    static #_9 = (() => dt7948.g(this.prototype, "isPartialSuccess", [_tracking.tracked], function () {
      return false;
    }))();
    #isPartialSuccess = (() => (dt7948.i(this, "isPartialSuccess"), void 0))();
    static #_10 = (() => dt7948.g(this.prototype, "partialSuccessMessage", [_tracking.tracked]))();
    #partialSuccessMessage = (() => (dt7948.i(this, "partialSuccessMessage"), void 0))();
    static #_11 = (() => dt7948.g(this.prototype, "formData", [_tracking.tracked]))();
    #formData = (() => (dt7948.i(this, "formData"), void 0))();
    static #_12 = (() => dt7948.g(this.prototype, "showingInitialModalView", [_tracking.tracked], function () {
      return true;
    }))();
    #showingInitialModalView = (() => (dt7948.i(this, "showingInitialModalView"), void 0))();
    constructor() {
      super(...arguments);
      this.appEvents.on(_appEventsConfig.ESCALATION_MODAL_CLOSE, this, this.onModalButtonCloseClick);
    }
    willDestroy() {
      super.willDestroy(...arguments);
      this.appEvents.off(_appEventsConfig.ESCALATION_MODAL_CLOSE, this, this.onModalButtonCloseClick);
    }
    get latestCreatedApproval() {
      return this.createdApprovals[this.createdApprovals.length - 1] ?? "";
    }
    async onModalButtonCloseClick() {
      if (!this.showingInitialModalView) {
        this.modal.close();
      } else {
        this.showingInitialModalView = false;
      }
    }
    static #_13 = (() => dt7948.n(this.prototype, "onModalButtonCloseClick", [_object.action]))();
    async onSubmit(inputs) {
      let clearForm = false;
      this.displayResults = false;
      this.isLoading = true;
      const {
        topicId
      } = (0, _urlHelpers.getTopicInfoFromPath)();
      try {
        const {
          tags: currentTags
        } = await (0, _fetchTopicById.fetchTopicById)(topicId);
        const response = await (0, _createEscalation.createEscalation)(topicId, inputs);
        const approvalId = response?.approvalId;
        if (approvalId && currentTags) {
          const escalationTag = _escalationFormConfig.ESCALATION_CONFIG[(0, _communityStage.getCommunityName)()].escalationTag;
          await (0, _updateTopic.updateTopic)(topicId, {
            tags: [...currentTags, escalationTag]
          }).catch(() => {
            cwr("recordEvent", {
              type: "escalation_failed_topic_tag_update",
              data: {
                user_id: _user.default?.current()?.id,
                topicId
              }
            });
            this.handleUpdateTopicError();
          });
          const approvalLink = this.getAmazonApprovalLink(approvalId);
          this.createEscalationNote(approvalLink, topicId);
          this.createdApprovals = [...this.createdApprovals, approvalLink];
          this.isSuccess = true;
          clearForm = true;
        } else {
          this.handleEscalationError(response);
        }
      } catch (e) {
        this.handleEscalationError(e);
      } finally {
        this.isLoading = false;
        this.displayResults = true;
      }
      return clearForm;
    }
    static #_14 = (() => dt7948.n(this.prototype, "onSubmit", [_object.action]))();
    onCancel(inputs) {
      this.attemptingCancel = true;
      this.formData = inputs;
    }
    static #_15 = (() => dt7948.n(this.prototype, "onCancel", [_object.action]))();
    continueEditButtonHandler() {
      this.attemptingCancel = false;
      this.showingInitialModalView = true;
    }
    static #_16 = (() => dt7948.n(this.prototype, "continueEditButtonHandler", [_object.action]))();
    getAmazonApprovalLink(approvalId) {
      return _escalationFormConfig.AA_LINKS[(0, _communityStage.getStageName)()] + approvalId;
    }
    async createEscalationNote(link, topicId) {
      const data = {
        escalation_note: {
          text: link,
          automated: true,
          topic_id: topicId
        }
      };
      const response = await (0, _ajax.ajax)("/admin/plugins/agc/escalations.json", {
        type: "POST",
        data
      });
      const note = response.escalation_note;
      this.appEvents.trigger("escalations:added", note);
    }
    handleEscalationError(error) {
      const errorCode = (0, _apiHelpers.getHttpStatusErrorCode)(error);
      this.isSuccess = false;
      switch (errorCode) {
        case 400:
          this.errorMessage = this.handle400Error(error);
          break;
        case 0:
          if (error?.jqXHR?.statusText === "timeout") {
            this.errorMessage = (0, _discourseI18n.i18n)("agc.escalation-modal-content.timeout-error");
          } else {
            this.errorMessage = (0, _discourseI18n.i18n)("agc.escalation-modal-content.default-error");
          }
          break;
        default:
          this.errorMessage = (0, _discourseI18n.i18n)("agc.escalation-modal-content.default-error");
          break;
      }
    }
    handle400Error(error) {
      if ((0, _apiHelpers.getErrorType)(error) === _errors.ErrorType.InvalidAlias) {
        const aliases = error?.jqXHR?.responseJSON?.aliases;
        if (aliases) {
          return (0, _discourseI18n.i18n)("agc.escalation-modal-content.alias-error", {
            aliases
          });
        }
      }
      return (0, _discourseI18n.i18n)("agc.escalation-modal-content.client-error");
    }
    handleUpdateTopicError() {
      this.isPartialSuccess = true;
      this.partialSuccessMessage = (0, _discourseI18n.i18n)("agc.escalation-modal-content.tag-error");
    }
  }
  _exports.default = EscalationModalContent;
});