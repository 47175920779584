define("discourse/plugins/discourse-global-communities/discourse/templates/components/user-summary-topic", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="summary-title">
    <a href={{this.url}}>{{html-safe this.topic.fancyTitle}}</a>
    {{#if this.likes}}
      <span class="like-count">{{d-icon "heart"}}&nbsp;
        {{number this.likes}}</span>
    {{/if}}
  </div>
  
  {{#if this.topic.agc_post_intent}}
    <div class="summary-post-intent-pill">
      <PostIntentPill @postIntent={{this.topic.agc_post_intent}} />
    </div>
  {{/if}}
  
  <p>{{html-safe this.topic.escapedExcerpt}}</p>
  
  <span class="topic-info">
    {{format-date this.createdAt format="medium-with-ago" noTitle="true"}}
  </span>
  */
  {
    "id": "3y2EIIHH",
    "block": "[[[10,0],[14,0,\"summary-title\"],[12],[1,\"\\n  \"],[10,3],[15,6,[30,0,[\"url\"]]],[12],[1,[28,[35,2],[[30,0,[\"topic\",\"fancyTitle\"]]],null]],[13],[1,\"\\n\"],[41,[30,0,[\"likes\"]],[[[1,\"    \"],[10,1],[14,0,\"like-count\"],[12],[1,[28,[35,5],[\"heart\"],null]],[1,\" \\n      \"],[1,[28,[35,6],[[30,0,[\"likes\"]]],null]],[13],[1,\"\\n\"]],[]],null],[13],[1,\"\\n\\n\"],[41,[30,0,[\"topic\",\"agc_post_intent\"]],[[[1,\"  \"],[10,0],[14,0,\"summary-post-intent-pill\"],[12],[1,\"\\n    \"],[8,[39,7],null,[[\"@postIntent\"],[[30,0,[\"topic\",\"agc_post_intent\"]]]],null],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[10,2],[12],[1,[28,[35,2],[[30,0,[\"topic\",\"escapedExcerpt\"]]],null]],[13],[1,\"\\n\\n\"],[10,1],[14,0,\"topic-info\"],[12],[1,\"\\n  \"],[1,[28,[35,9],[[30,0,[\"createdAt\"]]],[[\"format\",\"noTitle\"],[\"medium-with-ago\",\"true\"]]]],[1,\"\\n\"],[13]],[],false,[\"div\",\"a\",\"html-safe\",\"if\",\"span\",\"d-icon\",\"number\",\"post-intent-pill\",\"p\",\"format-date\"]]",
    "moduleName": "discourse/plugins/discourse-global-communities/discourse/templates/components/user-summary-topic.hbs",
    "isStrictMode": false
  });
});