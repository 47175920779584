define("discourse/plugins/discourse-global-communities/discourse/initializers/improved-search-analytics", ["exports", "@ember/runloop", "discourse/lib/plugin-api"], function (_exports, _runloop, _pluginApi) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const RESULT_ROUTES = ["topic.", "circle.activity.topics",
  // depending on if user summary is enabled, the default user route can be "user." or "userActivity."
  "user.", "userActivity.", "discovery.category", "tag.show"];
  const NEW_SEARCH = "NEW_SEARCH";
  const BACK_TO_SEARCH = "BACK_TO_SEARCH";
  const EXIT = "EXIT";
  const FULL_PAGE_RESULT_TYPES = [{
    name: "AI",
    class: ".search-results .ai-result .fps-topic",
    lookup: "data-topic-id",
    tab: "#posts-tab"
  }, {
    name: "POST",
    class: ".search-results .fps-topic",
    lookup: "data-topic-id",
    tab: "#posts-tab",
    aiSelector: ".search-results .ai-result .fps-topic"
  }, {
    name: "VIDEO",
    class: ".agc-search-results .episode",
    lookup: "data-topic-id",
    tab: "#videos-tab"
  }, {
    name: "GROUP",
    class: ".agc-search-results .circles-card",
    lookup: "data-category-id",
    tab: "#groups-tab"
  }, {
    name: "MEMBER",
    class: ".agc-search-results .member-link",
    lookup: "data-username",
    tab: "#members-tab"
  }, {
    name: "CATEGORY",
    class: ".agc-search-results .category-result",
    lookup: "data-category-id",
    tab: "#categories-tab"
  }, {
    name: "TAG",
    class: ".agc-search-results .tag-item",
    lookup: "data-tag-name",
    tab: "#tags-tab"
  }];
  const SEARCH_MENU_RESULT_TYPES = [{
    name: "POST",
    class: ".results .search-result-topic .search-link"
  }, {
    name: "VIDEO",
    class: ".results .search-result-video .search-link"
  }, {
    name: "GROUP",
    class: ".results .search-result-circle .search-link"
  }, {
    name: "MEMBER",
    class: ".results .search-result-user .search-link"
  }, {
    name: "CATEGORY",
    class: ".results .search-result-category .search-link"
  }, {
    name: "TAG",
    class: ".results .search-result-tag .search-link"
  }];
  const SEARCH = "Search";
  const BACK_TO_THE_RESULT_PAGE = "BACK_TO_THE_RESULT_PAGE";
  var _default = _exports.default = {
    name: "improved-search-analytics",
    initialize(container) {
      var _this = this;
      this.appEvents = container.lookup("service:app-events");
      this.searchPageAnalytics = container.lookup("service:agc-search-page-analytics");
      this.currentUser = container.lookup("service:current-user");
      this.search = container.lookup("service:search");
      const router = container.lookup("service:router");
      let currentSearchUrl = null;
      let previousSearchUrl = null;
      let previousRoute = null;
      let postId = null;
      let entryTimestamp = null;
      let exitTimestamp = null;
      let exitAction = null;
      (0, _pluginApi.withPluginApi)("2.0.1", api => {
        // Handle search result view events
        this.appEvents.on("search:search_result_view", function () {
          let data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
          _this.storeSearchResultsViewedAnalytics(false, data.searchMenu ? null : data.page, data.searchMenu);
        });

        // Handle AI results toggle events
        this.appEvents.on("full-page-search:ai-results-toggled", _ref => {
          let {
            enabled
          } = _ref;
          if (enabled) {
            this.storeSearchResultsViewedAnalytics(enabled, 1);
          }
          this.searchPageAnalytics.aiResultToggled = enabled;
        });
        this.appEvents.on("search-menu:search_menu_opened", () => {
          if (this.search.activeGlobalSearchTerm) {
            this.storeSearchResultsViewedAnalytics(false, null, true);
            exitTimestamp = moment().format("YYYY-MM-DDTHH:mm:ssZ");
            exitAction = BACK_TO_SEARCH;
            window.localStorage.lastAgcAnalyticsRef = BACK_TO_SEARCH;
            this.storeBackToSearchResultsAnalytics(postId, entryTimestamp, exitTimestamp, exitAction);
          }
        });
        const agcAnalyticsApi = api.agcAnalytics;
        if (agcAnalyticsApi) {
          // Helper function to build common event data
          const buildEventData = (_postType, _postId, context, _ref2) => {
            let {
              resultCount = null,
              aiResultCount = null
            } = _ref2;
            context.searchPageAnalytics.incrementViewSequence();
            context.entryTimestamp = moment().format("YYYY-MM-DDTHH:mm:ssZ");
            return {
              search_session_id: context.searchPageAnalytics.searchSessionValue,
              search_query: context.search.activeGlobalSearchTerm,
              ai_results_enabled: context.searchPageAnalytics.aiResultToggled,
              post_type: _postType,
              result_type: this.searchPageAnalytics.lastClickedResultType,
              result_position: context.searchPageAnalytics.lastClickedIndex,
              post_id: _postId,
              entry_timestamp: context.entryTimestamp,
              referrer: "SEARCH_RESULTS_PAGE",
              view_sequence: context.searchPageAnalytics.viewSequence,
              total_results_count: resultCount,
              // total number of results
              regular_results_count: resultCount - aiResultCount // total number of results - AI results
            };
          };

          // Register click analytics for result types
          const registerResultTypeAnalytics = (resultTypes, getPostId, context) => {
            resultTypes.forEach(params => {
              agcAnalyticsApi.registerClickAnalytics(params.class, {
                eventType: "agc-custom:search_result_click",
                eventData: element => {
                  let _postType;
                  let _postId;
                  let selector;
                  if (element?.classList) {
                    for (const resultType of resultTypes) {
                      const matchingElement = element.closest(resultType.class);
                      if (matchingElement) {
                        _postType = resultType.name;
                        this.searchPageAnalytics.resetWhenClickRecord();
                        if (resultType.name === "AI") {
                          _postType = "POST";
                          this.searchPageAnalytics.recordAiResultWhenClick();
                        }
                        _postId = getPostId(matchingElement, resultType);
                        selector = resultType.class;
                        break;
                      }
                    }
                  }
                  const aiResultCount = document.querySelectorAll(".search-results .ai-result .fps-topic" // AI result selector, hardcoded from FULL_PAGE_RESULT_TYPES
                  ).length;
                  const resultCount = document.querySelectorAll(selector).length + aiResultCount;
                  return buildEventData(_postType, _postId, context, {
                    resultCount,
                    aiResultCount
                  });
                }
              });
            });
          };

          // full page search and search menu have different result types and selectors
          // register both
          registerResultTypeAnalytics(FULL_PAGE_RESULT_TYPES, (el, type) => el.getAttribute(type.lookup), this);
          registerResultTypeAnalytics(SEARCH_MENU_RESULT_TYPES, el => el.getAttribute("href")?.split("/").pop(), this);
        }

        // Core navigation tracking logic
        api.onPageChange(url => {
          const currentRoute = router.currentRouteName;
          const isSearchPage = currentRoute === "full-page-search";
          const isPreviousRouteResultPage = RESULT_ROUTES.find(route => previousRoute?.startsWith(route));
          if (isSearchPage) {
            currentSearchUrl = url;
            if (previousRoute) {
              let isNewSearchPage = previousSearchUrl && previousSearchUrl !== currentSearchUrl;

              // Reset search session if coming from non-result page or starting new search
              if (!isPreviousRouteResultPage || isPreviousRouteResultPage && isNewSearchPage) {
                this.searchPageAnalytics.resetSearchSession();
              }

              // Handle return to search scenarios
              if (isPreviousRouteResultPage) {
                exitTimestamp = moment().format("YYYY-MM-DDTHH:mm:ssZ");
                if (isNewSearchPage) {
                  exitAction = NEW_SEARCH;
                  this.storeBackToSearchResultsAnalytics(postId, entryTimestamp, exitTimestamp, exitAction);
                } else {
                  exitAction = BACK_TO_SEARCH;
                  this.storeBackToSearchResultsAnalytics(postId, entryTimestamp, exitTimestamp, exitAction);
                }
              }
            }
            previousSearchUrl = currentSearchUrl;
          } else if (isPreviousRouteResultPage) {
            // Track exit from result pages
            exitTimestamp = moment().format("YYYY-MM-DDTHH:mm:ssZ");
            exitAction = EXIT;
            this.storeBackToSearchResultsAnalytics(postId, entryTimestamp, exitTimestamp, exitAction);
          }
          previousRoute = currentRoute;
        });
      });
    },
    // Analytics storage methods
    storeSearchResultsViewedAnalytics(enabled, page, fromSearchMenu) {
      (0, _runloop.schedule)("afterRender", () => {
        // Set the click_ref for the data log
        window.localStorage.lastAgcAnalyticsRef = "search_result_view";
        let selector;
        let resultCount = 0;
        let aiResultCount = 0;

        // only count results if not from search menu
        if (!fromSearchMenu) {
          const activeTab = FULL_PAGE_RESULT_TYPES.find(type => type.name !== "AI" && document.querySelector(type.tab));
          if (activeTab) {
            selector = activeTab.class;
            resultCount = document.querySelectorAll(selector).length;

            // Check for AI results if the active tab has an aiSelector
            if (activeTab.aiSelector) {
              aiResultCount = document.querySelectorAll(activeTab.aiSelector).length;
            } else {
              aiResultCount = 0;
            }
          }
        }
        this.appEvents.trigger("agc-custom:search_result_view", {
          search_session_id: this.searchPageAnalytics.searchSessionValue,
          search_query: this.search.activeGlobalSearchTerm,
          ai_results_enabled: this.searchPageAnalytics.aiResultToggled,
          toggle_displayed: fromSearchMenu ? false // if from search menu we never show the AI results toggle
          : this.currentUser.siteSettings.agc_ai_search_enabled,
          page_number: page,
          is_return_visit: this.searchPageAnalytics.viewSequence > 0,
          total_results_count: resultCount,
          // total number of results
          regular_results_count: resultCount - aiResultCount // total number of results - AI results
        });
      });
    },
    // Analytics storage methods
    // We are using `_` to avoid conflict with the top level variables in this file
    storeBackToSearchResultsAnalytics(_postId, _entryTimestamp, _exitTimestamp, _exitAction) {
      (0, _runloop.schedule)("afterRender", () => {
        let agcAnalyticsRef = null;
        if (_exitAction === NEW_SEARCH) {
          agcAnalyticsRef = SEARCH;
        }
        if (_exitAction === BACK_TO_SEARCH) {
          agcAnalyticsRef = BACK_TO_THE_RESULT_PAGE;
        }
        if (_exitAction === EXIT) {
          agcAnalyticsRef = EXIT;
        }

        // Set the click_ref for the data log
        window.localStorage.lastAgcAnalyticsRef = agcAnalyticsRef;
        this.appEvents.trigger("agc-custom:POST_EXIT_FROM_SEARCH", {
          search_session_id: this.searchPageAnalytics.searchSessionValue,
          search_query: this.search.activeGlobalSearchTerm,
          ai_results_enabled: this.searchPageAnalytics.aiResultToggled,
          result_type: this.searchPageAnalytics.lastClickedResultType,
          result_position: this.searchPageAnalytics.lastClickedIndex,
          post_id: _postId,
          entry_timestamp: _entryTimestamp,
          exit_timestamp: _exitTimestamp,
          time_spent_on_page: moment(_exitTimestamp).diff(moment(_entryTimestamp), "seconds"),
          view_sequence: this.searchPageAnalytics.viewSequence,
          exit_action: _exitAction
        });
      });
    }
  };
});