define("discourse/plugins/discourse-global-communities/discourse/lib/helpers/admin/agc-admin-action-helpers", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.getLocalDateISOFileName = _exports.getCsvUriFromArray = _exports.getCsvFailuresFileLink = void 0;
  const getCsvUriFromArray = (action, members, columnHeaders) => {
    let csvContent = "data:text/csv;charset=utf-8," + columnHeaders + "\r\n";
    members.forEach(element => csvContent += element + "\r\n");
    return encodeURI(csvContent);
  };
  _exports.getCsvUriFromArray = getCsvUriFromArray;
  const getCsvFailuresFileLink = (action, members, CSVInputColumnHeaders) => {
    CSVInputColumnHeaders.push("Error Message");
    CSVInputColumnHeaders.join(",");
    let encodedUri = getCsvUriFromArray(action, members, CSVInputColumnHeaders);
    let link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", `${action} failures ${getLocalDateISOFileName()}.csv`);
    document.body.appendChild(link);
    return link;
  };
  _exports.getCsvFailuresFileLink = getCsvFailuresFileLink;
  const getLocalDateISOFileName = () => {
    let tempDate = new Date();
    let shiftedDate = tempDate - tempDate.getTimezoneOffset() * 60 * 1000;
    let localDate = new Date(shiftedDate);
    return localDate.toISOString().split(".")[0].replace(new RegExp(`[:]`, `g`), `_`);
  };
  _exports.getLocalDateISOFileName = getLocalDateISOFileName;
});