define("discourse/plugins/discourse-global-communities/discourse/components/agc/ai-summary/summarize-button", ["exports", "@glimmer/component", "@ember/modifier", "@ember/object", "@ember/service", "discourse/helpers/d-icon", "discourse-i18n", "@ember/component", "@ember/template-factory"], function (_exports, _component, _modifier, _object, _service, _dIcon, _discourseI18n, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class SummarizeButton extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "appEvents", [_service.service]))();
    #appEvents = (() => (dt7948.i(this, "appEvents"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "currentUser", [_service.service]))();
    #currentUser = (() => (dt7948.i(this, "currentUser"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "agcAiSummary", [_service.service]))();
    #agcAiSummary = (() => (dt7948.i(this, "agcAiSummary"), void 0))();
    get topic() {
      return this.args.topic || this.args.post.topic;
    }
    get position() {
      return this.args.position || "on-post";
    }
    openAiSummarySheet() {
      this.appEvents.trigger("agc-custom:ai_summarization_button_clicked", {
        topic_id: this.topic.id,
        user_id: this.currentUser.id,
        position: this.position
      });
      this.agcAiSummary.openAiSummarySheet(this.topic.id);
    }
    static #_4 = (() => dt7948.n(this.prototype, "openAiSummarySheet", [_object.action]))();
    static #_5 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <a
          ...attributes
          role="button"
          class="agc-ai-summarization-button"
          {{on "click" this.openAiSummarySheet}}
        >
          {{icon "discourse-sparkles"}}
          <span>{{i18n "agc.ai.generate_ai_summary"}}</span>
        </a>
      
    */
    {
      "id": "nFItnRxE",
      "block": "[[[1,\"\\n    \"],[11,3],[17,1],[24,\"role\",\"button\"],[24,0,\"agc-ai-summarization-button\"],[4,[32,0],[\"click\",[30,0,[\"openAiSummarySheet\"]]],null],[12],[1,\"\\n      \"],[1,[28,[32,1],[\"discourse-sparkles\"],null]],[1,\"\\n      \"],[10,1],[12],[1,[28,[32,2],[\"agc.ai.generate_ai_summary\"],null]],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[\"&attrs\"],false,[]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-global-communities/discourse/components/agc/ai-summary/summarize-button.js",
      "scope": () => [_modifier.on, _dIcon.default, _discourseI18n.i18n],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = SummarizeButton;
});