define("discourse/plugins/discourse-global-communities/discourse/components/agc/ai-summary/disclaimer-modal", ["exports", "@glimmer/component", "@ember/object", "discourse/components/d-button", "discourse/components/d-modal", "discourse-i18n", "@ember/component", "@ember/template-factory"], function (_exports, _component, _object, _dButton, _dModal, _discourseI18n, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const AI_SUMMARY_DISCLAIMER_CONFIRMED = "agc-ai-summary-disclaimer-confirmed"; // if you change this, change the ai-summary service too
  class DisclaimerModal extends _component.default {
    confirm() {
      localStorage.setItem(AI_SUMMARY_DISCLAIMER_CONFIRMED, true);
      this.args.closeModal();
    }
    static #_ = (() => dt7948.n(this.prototype, "confirm", [_object.action]))();
    static #_2 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <DModal
          @title={{i18n "agc.ai.disclaimer.title"}}
          class="agc-ai-summary-disclaimer-modal"
        >
          <:body>
            <div class="agc-ai-summary-disclaimer-modal__body">
              {{i18n "agc.ai.disclaimer.body"}}
            </div>
          </:body>
    
          <:footer>
            <div class="modal-footer">
              <DButton
                class="btn-primary agc-ai-summary-disclaimer-modal__confirm"
                @action={{this.confirm}}
                @label="agc.ai.disclaimer.confirm"
              />
            </div>
          </:footer>
        </DModal>
      
    */
    {
      "id": "t2WwD1eU",
      "block": "[[[1,\"\\n    \"],[8,[32,0],[[24,0,\"agc-ai-summary-disclaimer-modal\"]],[[\"@title\"],[[28,[32,1],[\"agc.ai.disclaimer.title\"],null]]],[[\"body\",\"footer\"],[[[[1,\"\\n        \"],[10,0],[14,0,\"agc-ai-summary-disclaimer-modal__body\"],[12],[1,\"\\n          \"],[1,[28,[32,1],[\"agc.ai.disclaimer.body\"],null]],[1,\"\\n        \"],[13],[1,\"\\n      \"]],[]],[[[1,\"\\n        \"],[10,0],[14,0,\"modal-footer\"],[12],[1,\"\\n          \"],[8,[32,2],[[24,0,\"btn-primary agc-ai-summary-disclaimer-modal__confirm\"]],[[\"@action\",\"@label\"],[[30,0,[\"confirm\"]],\"agc.ai.disclaimer.confirm\"]],null],[1,\"\\n        \"],[13],[1,\"\\n      \"]],[]]]]],[1,\"\\n  \"]],[],false,[]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-global-communities/discourse/components/agc/ai-summary/disclaimer-modal.js",
      "scope": () => [_dModal.default, _discourseI18n.i18n, _dButton.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = DisclaimerModal;
});