define("discourse/plugins/discourse-global-communities/discourse/feature-flags/agc-ai-summary-hide-button", ["exports", "discourse/lib/ajax", "discourse/models/composer"], function (_exports, _ajax, _composer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AgcAiSummaryHideButtonFeatureFlag {
    static enabledKey = "agc_ai_summary_enabled";
    static className = "agc-ai-summary-enabled";
    static afterEnabled(api) {
      const currentUser = api.getCurrentUser();
      if (!currentUser?.admin) {
        return;
      }
      _composer.default.serializeOnUpdate("agc_hide_ai_topic_summary", "agc_hide_ai_topic_summary");
      api.addTopicAdminMenuButton(topic => {
        return {
          action: () => {
            // toggle hide ai topic summary
            (0, _ajax.ajax)("/toggle-ai-summary-visibility.json", {
              type: "POST",
              data: {
                topic_id: topic.id,
                value: !topic.agc_hide_ai_topic_summary
              }
            }).then(() => {
              topic.set("agc_hide_ai_topic_summary", !topic.agc_hide_ai_topic_summary);
            });
          },
          icon: topic.get("agc_hide_ai_topic_summary") ? "far-eye" : "far-eye-slash",
          className: "btn-default agc-hide-ai-summary",
          label: `agc.ai.${topic.get("agc_hide_ai_topic_summary") ? "show" : "hide"}_ai_summary`
        };
      });
    }
  }
  _exports.default = AgcAiSummaryHideButtonFeatureFlag;
});