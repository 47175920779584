define("discourse/plugins/discourse-global-communities/discourse/lib/topics/update-topic", ["exports", "discourse/lib/ajax"], function (_exports, _ajax) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.updateTopic = void 0;
  /**
   * Update a topic
   *
   * interface Options {
   *  string: any;
   * }
   *
   * ex. { tags: ['much tag, much nice'], title: 'much title, much wow' }
   *
   * There unfortunately isn't an exhaustive list from Discourse
   * You would need to reverse engineer the possible options through
   *   playing around in the network tab
   *
   * @param {string} topicId
   * @param {Options} options
   * @returns {Promise<*>} Updated topic information
   */
  const updateTopic = async (topicId, options) => {
    if (!topicId || !options) {
      throw new Error("topicId and options are required.");
    }
    const body = {
      keep_existing_draft: true,
      ...options
    };
    return (0, _ajax.ajax)(`/t/-/${topicId}.json`, {
      contentType: "application/json",
      data: JSON.stringify(body),
      dataType: "json",
      type: "PUT"
    });
  };
  _exports.updateTopic = updateTopic;
});