define("discourse/plugins/discourse-global-communities/discourse/components/no-notifications-empty-state", ["exports", "@ember/component", "@glimmer/component", "@ember/object", "@ember/service", "@ember/template-factory"], function (_exports, _component, _component2, _object, _service, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="no-notifications">
    <div class="no-notifications-icon"></div>
    <h2>{{i18n "enhanced-notifications.no-notifications-page.title"}}</h2>
    <p>{{i18n "enhanced-notifications.no-notifications-page.body"}}</p>
    {{agc-custom-button
      type="primary"
      label=(i18n "enhanced-notifications.no-notifications-page.button")
      class="no-notifications-button"
      onClick=(action "onButtonClicked")
    }}
  </div>
  */
  {
    "id": "rAGmLh2H",
    "block": "[[[10,0],[14,0,\"no-notifications\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"no-notifications-icon\"],[12],[13],[1,\"\\n  \"],[10,\"h2\"],[12],[1,[28,[35,2],[\"enhanced-notifications.no-notifications-page.title\"],null]],[13],[1,\"\\n  \"],[10,2],[12],[1,[28,[35,2],[\"enhanced-notifications.no-notifications-page.body\"],null]],[13],[1,\"\\n  \"],[1,[28,[35,4],null,[[\"type\",\"label\",\"class\",\"onClick\"],[\"primary\",[28,[37,2],[\"enhanced-notifications.no-notifications-page.button\"],null],\"no-notifications-button\",[28,[37,5],[[30,0],\"onButtonClicked\"],null]]]]],[1,\"\\n\"],[13]],[],false,[\"div\",\"h2\",\"i18n\",\"p\",\"agc-custom-button\",\"action\"]]",
    "moduleName": "discourse/plugins/discourse-global-communities/discourse/components/no-notifications-empty-state.hbs",
    "isStrictMode": false
  });
  class NoNotificationsEmptyState extends _component2.default {
    static #_ = (() => dt7948.g(this.prototype, "router", [_service.service]))();
    #router = (() => (dt7948.i(this, "router"), void 0))();
    onButtonClicked() {
      this.router.transitionTo("/");
    }
    static #_2 = (() => dt7948.n(this.prototype, "onButtonClicked", [_object.action]))();
  }
  _exports.default = NoNotificationsEmptyState;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, NoNotificationsEmptyState);
});