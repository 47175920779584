define("discourse/plugins/discourse-global-communities/discourse/connectors/topic-above-footer-buttons/topic-above-footer-buttons-connector", ["exports", "discourse/plugins/discourse-global-communities/discourse/components/see-all-comments-button", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _seeAllCommentsButton, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const TopicAboveFooterButtonsConnnector = (0, _component.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
    <SeeAllCommentsButton @topic={{@outletArgs.model}} />
  
  */
  {
    "id": "o+syKRuQ",
    "block": "[[[1,\"\\n  \"],[8,[32,0],null,[[\"@topic\"],[[30,1,[\"model\"]]]],null],[1,\"\\n\"]],[\"@outletArgs\"],false,[]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-global-communities/discourse/connectors/topic-above-footer-buttons/topic-above-footer-buttons-connector.js",
    "scope": () => [_seeAllCommentsButton.default],
    "isStrictMode": true
  }), (0, _templateOnly.default)(undefined, "topic-above-footer-buttons-connector:TopicAboveFooterButtonsConnnector"));
  var _default = _exports.default = TopicAboveFooterButtonsConnnector;
});