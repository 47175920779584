define("discourse/plugins/discourse-global-communities/discourse/connectors/admin-users-list-show-before/bulk-admin-actions", ["exports", "@glimmer/tracking", "discourse/models/user", "discourse/plugins/discourse-global-communities/discourse/lib/helpers/admin/bulk-admin-actions/conduct-suspend-user", "discourse/plugins/discourse-global-communities/discourse/lib/helpers/admin/bulk-admin-actions/conduct-update-user-attributes", "discourse/plugins/discourse-global-communities/discourse/lib/helpers/admin/bulk-admin-actions/conduct-update-user-group", "discourse/plugins/discourse-global-communities/discourse/lib/helpers/admin/bulk-admin-actions/constants/bulk-admin-constants", "discourse/plugins/discourse-global-communities/discourse/lib/helpers/bulk-admin-helpers"], function (_exports, _tracking, _user, _conductSuspendUser, _conductUpdateUserAttributes, _conductUpdateUserGroup, _bulkAdminConstants, _bulkAdminHelpers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /* global cwr */
  var _default = _exports.default = {
    actions: {
      updateChoice(event) {
        this.set("currentAdminAction", event.target.getAttribute("value"));
      },
      toggleShowBulkAdminActions() {
        this.toggleProperty("showBulkAdminActions");
        this.toggleProperty("shouldRotateSvg");
      },
      closeBulkAdminResults() {
        this.set("showBulkAdminResults", false);
      }
    },
    setupComponent() {
      this.reopen(dt7948.p({
        options: _bulkAdminConstants.BULK_ADMIN_ACTIONS,
        actionInProgress: false,
        actionSucceed: false,
        parseSucceed: false,
        currentAdminAction: "",
        formSubmitted: false,
        showBulkAdminActions: false,
        showBulkAdminResults: false,
        shouldRotateSvg: false,
        async _render() {
          this.set("shouldRender", this.siteSettings.render_bulk_admin_actions && !this.site.mobileView);
          this.appEvents.on("bulk-admin:action", this, this._handleBulkAdminActions.bind(this));
          this.appEvents.on("bulk-admin:parsedCSVInputHeaders", this, this._setCSVInputHeaders.bind(this));
        },
        async _applyActionToParsingResult(content) {
          this.set("actionInProgress", true);
          this.set("parseSucceed", true);
          this.set("showBulkAdminResults", false);
          const currentUser = _user.default.current();
          if (!currentUser.admin) {
            throw new Error("Only admins allowed. How did you get here?!");
          }
          const start = performance.now();
          let apiResult;
          switch (this.get("currentAdminAction")) {
            case _bulkAdminConstants.BULK_ADMIN_ACTIONS_IDS.SUSPEND_USER:
              apiResult = await (0, _conductSuspendUser.conductSuspendUser)(content);
              break;
            case _bulkAdminConstants.BULK_ADMIN_ACTIONS_IDS.UPDATE_USER_GROUPS:
              apiResult = await (0, _conductUpdateUserGroup.conductUpdateUserGroup)(content, this.appEvents);
              break;
            case _bulkAdminConstants.BULK_ADMIN_ACTIONS_IDS.UPDATE_USER_ATTRIBUTES:
              apiResult = await (0, _conductUpdateUserAttributes.conductUpdateUserAttributes)(content);
              break;
          }
          cwr("recordEvent", {
            type: "bulk_admin_timing",
            data: {
              action: this.get("currentAdminAction"),
              row_count: content.length,
              duration_per_row: Math.floor((performance.now() - start) / content.length)
            }
          });
          if (apiResult) {
            this.set("bulkAdminResults", (0, _bulkAdminHelpers.buildResultsForAdminAction)(this.currentAdminAction, apiResult));
            this.set("actionSucceed", !apiResult.failureCount);
            this.set("showBulkAdminResults", true);
            this.set("actionInProgress", false);
            this.set("currentAdminAction", "");
          }
        },
        _handleParsingError(error, action) {
          this.set("parseSucceed", false);
          this.set("actionSucceed", false);
          this.set("showBulkAdminResults", !this.get("actionInProgress"));
          this.set("bulkAdminResults", (0, _bulkAdminHelpers.buildPreprocessFailureForAdminAction)(action, error));
        },
        _handleAPIError(error, action) {
          this.set("parseSucceed", false);
          this.set("actionSucceed", false);
          this.set("showBulkAdminResults", true);
          this.set("bulkAdminResults", (0, _bulkAdminHelpers.buildPreprocessFailureForAdminAction)(action, error));
          this.set("actionInProgress", false);
        },
        _handleBulkAdminActions(args) {
          switch (args?.action) {
            case "parsingResults":
              this._applyActionToParsingResult(args.content);
              break;
            case "parsingError":
              this._handleParsingError(args.content, args.userAction);
              break;
            case "apiError":
              this._handleAPIError(args.content, args.userAction);
              break;
            case "hideResults":
              this.closeBulkAdminResults();
              break;
          }
        },
        _setCSVInputHeaders(args) {
          if (args?.headers) {
            this.set("CSVInputHeaders", args.headers);
          }
        }
      }, [["field", "actionInProgress", [_tracking.tracked]], ["field", "actionSucceed", [_tracking.tracked]], ["field", "parseSucceed", [_tracking.tracked]], ["field", "currentAdminAction", [_tracking.tracked]], ["field", "formSubmitted", [_tracking.tracked]], ["field", "showBulkAdminActions", [_tracking.tracked]], ["field", "showBulkAdminResults", [_tracking.tracked]], ["field", "shouldRotateSvg", [_tracking.tracked]]]));
      this._render();
    }
  };
});