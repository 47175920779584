define("discourse/plugins/discourse-global-communities/discourse/components/homepage-admin-menu", ["exports", "@glimmer/component", "@ember/object", "@ember/service", "discourse/components/d-button", "discourse/components/dropdown-menu", "discourse/components/modal/reorder-categories", "discourse/helpers/d-icon", "discourse/lib/url", "float-kit/components/d-menu", "@ember/component", "@ember/template-factory"], function (_exports, _component, _object, _service, _dButton, _dropdownMenu, _reorderCategories2, _dIcon, _url, _dMenu, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class HomepageAdminMenu extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "currentUser", [_service.service]))();
    #currentUser = (() => (dt7948.i(this, "currentUser"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "modal", [_service.service]))();
    #modal = (() => (dt7948.i(this, "modal"), void 0))();
    onRegisterApi(api) {
      this.dMenu = api;
    }
    static #_3 = (() => dt7948.n(this.prototype, "onRegisterApi", [_object.action]))();
    async newCategory() {
      await this.dMenu.close();
      _url.default.routeTo(`/new-category`);
    }
    static #_4 = (() => dt7948.n(this.prototype, "newCategory", [_object.action]))();
    async reorderCategories() {
      await this.dMenu.close();
      this.modal.show(_reorderCategories2.default);
    }
    static #_5 = (() => dt7948.n(this.prototype, "reorderCategories", [_object.action]))();
    get showAdminButton() {
      return this.currentUser?.admin;
    }
    static #_6 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        {{#if this.showAdminButton}}
          <DMenu
            @identifier="homepage-admin-menu"
            @onRegisterApi={{this.onRegisterApi}}
            @modalForMobile={{true}}
            @triggerClass="btn-default btn-icon toggle-homepage-admin-menu {{@buttonClasses}}"
            class="btn-flat"
          >
            <:trigger>
              {{icon "wrench"}}
            </:trigger>
            <:content>
              <DropdownMenu as |dropdown|>
                <dropdown.item class="homepage-admin-new-category">
                  <DButton
                    @label="admin.homepage.actions.new_category"
                    @action={{this.newCategory}}
                    @icon="plus"
                  />
                </dropdown.item>
                <dropdown.item class="homepage-admin-reorder-categories">
                  <DButton
                    @label="admin.homepage.actions.reorder_categories"
                    @action={{this.reorderCategories}}
                    @icon="shuffle"
                  />
                </dropdown.item>
              </DropdownMenu>
            </:content>
          </DMenu>
        {{/if}}
      
    */
    {
      "id": "zqgfGxA2",
      "block": "[[[1,\"\\n\"],[41,[30,0,[\"showAdminButton\"]],[[[1,\"      \"],[8,[32,0],[[24,0,\"btn-flat\"]],[[\"@identifier\",\"@onRegisterApi\",\"@modalForMobile\",\"@triggerClass\"],[\"homepage-admin-menu\",[30,0,[\"onRegisterApi\"]],true,[29,[\"btn-default btn-icon toggle-homepage-admin-menu \",[30,1]]]]],[[\"trigger\",\"content\"],[[[[1,\"\\n          \"],[1,[28,[32,1],[\"wrench\"],null]],[1,\"\\n        \"]],[]],[[[1,\"\\n          \"],[8,[32,2],null,null,[[\"default\"],[[[[1,\"\\n            \"],[8,[30,2,[\"item\"]],[[24,0,\"homepage-admin-new-category\"]],null,[[\"default\"],[[[[1,\"\\n              \"],[8,[32,3],null,[[\"@label\",\"@action\",\"@icon\"],[\"admin.homepage.actions.new_category\",[30,0,[\"newCategory\"]],\"plus\"]],null],[1,\"\\n            \"]],[]]]]],[1,\"\\n            \"],[8,[30,2,[\"item\"]],[[24,0,\"homepage-admin-reorder-categories\"]],null,[[\"default\"],[[[[1,\"\\n              \"],[8,[32,3],null,[[\"@label\",\"@action\",\"@icon\"],[\"admin.homepage.actions.reorder_categories\",[30,0,[\"reorderCategories\"]],\"shuffle\"]],null],[1,\"\\n            \"]],[]]]]],[1,\"\\n          \"]],[2]]]]],[1,\"\\n        \"]],[]]]]],[1,\"\\n\"]],[]],null],[1,\"  \"]],[\"@buttonClasses\",\"dropdown\"],false,[\"if\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-global-communities/discourse/components/homepage-admin-menu.js",
      "scope": () => [_dMenu.default, _dIcon.default, _dropdownMenu.default, _dButton.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = HomepageAdminMenu;
});