define("discourse/plugins/discourse-global-communities/discourse/lib/helpers/admin/bulk-admin-actions/conduct-update-user-group", ["exports", "rsvp", "discourse/plugins/discourse-global-communities/discourse/lib/helpers/bulk-admin-helpers", "discourse/plugins/discourse-global-communities/discourse/lib/helpers/users/get-user-by-username", "discourse/plugins/discourse-global-communities/discourse/lib/helpers/admin/users/add-user-to-group", "discourse/plugins/discourse-global-communities/discourse/lib/helpers/admin/users/remove-user-from-group", "discourse/plugins/discourse-global-communities/discourse/lib/helpers/admin/bulk-admin-actions/constants/bulk-admin-constants"], function (_exports, _rsvp, _bulkAdminHelpers, _getUserByUsername, _addUserToGroup, _removeUserFromGroup, _bulkAdminConstants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.conductUpdateUserGroup = void 0;
  /**
   * Method to bulk call addUserToGroup and removeUserFromGroup from a given CSV file
   *
   * interface Args {
   *   errorMessage: string;
   *   parsedSuccessfully: boolean;
   *   username: string;
   *   removeGroups: string;
   *   addGroups: string;
   *   rawCSV: string;
   * }
   *
   * interface Response {
   *   successCount: number;
   *   failureCount: number;
   *   failureList: String[];
   *   failureListCsv: String[]
   * }
   * @param {Args} parsedCsvFile
   * @returns {Response}
   */

  const conductUpdateUserGroup = async (parsedCsvFile, appEvents) => {
    let groupNameToIdMap;
    try {
      groupNameToIdMap = await (0, _bulkAdminHelpers.getGroupNameToIdMap)();
    } catch (error) {
      appEvents?.trigger("bulk-admin:action", {
        action: "apiError",
        userAction: _bulkAdminConstants.BULK_ADMIN_ACTIONS_IDS.UPDATE_USER_GROUPS,
        content: error
      });
      return;
    }
    const useUpdateUserGroupResults = await parsedCsvFile.reduce(async (prev, entry, index) => {
      const rowNumber = (0, _bulkAdminHelpers.getRowIteratorOffset)(index);
      const result = await prev;
      if (!entry.parsedSuccessfully) {
        result.failureCount++;
        result.failureList.push((0, _bulkAdminHelpers.getRowMessageFromError)(rowNumber, entry.rawCSV, entry.errorMessage));
        result.failureListCsv.push((0, _bulkAdminHelpers.getCsvRowWithErrorColumn)(entry.rawCSV, rowNumber, entry.errorMessage));
        return result;
      }
      let userToUpdateGroup;
      try {
        userToUpdateGroup = await (0, _getUserByUsername.getUserByUsername)(entry.username);
      } catch {
        result.failureCount++;
        result.failureList.push((0, _bulkAdminHelpers.getRowMessageFromError)(rowNumber, entry.rawCSV, _bulkAdminConstants.BULK_ADMIN_USER_NOT_FOUND_ERROR));
        result.failureListCsv.push((0, _bulkAdminHelpers.getCsvRowWithErrorColumn)(entry.rawCSV, rowNumber, _bulkAdminConstants.BULK_ADMIN_USER_NOT_FOUND_ERROR));
        return result;
      }
      return await updateUserGroups(userToUpdateGroup, groupNameToIdMap, entry, rowNumber, result);
    }, _rsvp.Promise.resolve({
      successCount: 0,
      failureCount: 0,
      failureList: [],
      failureListCsv: []
    }));
    return useUpdateUserGroupResults;
  };
  _exports.conductUpdateUserGroup = conductUpdateUserGroup;
  const updateUserGroups = async (userToUpdateGroup, groupNameToIdMap, entry, rowNumber, result) => {
    const updateUserGroupFailureList = [];
    const failureAddGroupListCsv = [];
    const failureRemoveGroupListCsv = [];
    const removeGroupList = entry.removeGroups.split(",");
    const addGroupList = entry.addGroups.split(",");
    for (let groupName of removeGroupList) {
      try {
        groupName = groupName.trim();
        const groupId = groupNameToIdMap[groupName];
        if (groupName === "") {
          continue;
        } else if (!groupId) {
          throw new Error(`Invalid GroupName ${groupName}`);
        }
        await (0, _removeUserFromGroup.removeUserFromGroup)(userToUpdateGroup.user.id, groupId);
      } catch (error) {
        updateUserGroupFailureList.push(`${groupName} (${(0, _bulkAdminHelpers.getError)(error)})`);
        failureRemoveGroupListCsv.push(groupName);
      }
    }
    for (let groupName of addGroupList) {
      try {
        groupName = groupName.trim();
        const groupId = groupNameToIdMap[groupName];
        if (groupName === "") {
          continue;
        } else if (!groupId) {
          throw new Error(`Invalid GroupName ${groupName}`);
        }
        await (0, _addUserToGroup.addUserToGroup)(userToUpdateGroup.user.id, groupId);
      } catch (error) {
        updateUserGroupFailureList.push(`${groupName} (${(0, _bulkAdminHelpers.getError)(error)})`);
        failureAddGroupListCsv.push(groupName);
      }
    }
    if (updateUserGroupFailureList.length > 0) {
      result.failureCount++;
      result.failureList.push((0, _bulkAdminHelpers.getRowMessageFromError)(rowNumber, entry.rawCSV, `Failed groups: "${updateUserGroupFailureList}".`));
      result.failureListCsv.push((0, _bulkAdminHelpers.getCsvRowWithErrorColumn)(entry.rawCSV, rowNumber, `${updateUserGroupFailureList}`));
    } else {
      result.successCount++;
    }
    return result;
  };
});