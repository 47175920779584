define("discourse/plugins/discourse-global-communities/discourse/lib/helpers/api-helpers", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.getHttpStatusErrorCode = _exports.getErrorType = void 0;
  /**
   * This file contains API utils for parsing responses.
   *
   * Example Error Response (from CreateEscalation):
   * {
   * 	"jqXHR": {
   * 		"readyState": 4,
   * 		"responseText": "{\"aliases\":[\"invalidalias1\",\"invalidalias2\"],\"errorCode\":\"TPX_CLIENT_A006\"}",
   * 		"responseJSON": {
   * 			"aliases": [
   * 				"invalidalias1",
   * 				"invalidalias2"
   * 			],
   * 			"errorCode": "TPX_CLIENT_A006"
   * 		},
   * 		"status": 400,
   * 		"statusText": "error",
   * 		"jqTextStatus": "error",
   * 		"requestedUrl": "/proxy/media/escalations.json"
   * 	},
   * 	"textStatus": "error",
   * 	"errorThrown": ""
   * }
   *
   */

  /**
   * Gets an HTTP Status Code from a response error.
   *
   * @param {string} response
   * @returns string | undefined if error during parsing
   */
  const getHttpStatusErrorCode = response => {
    try {
      return response?.jqXHR?.status;
    } catch {
      return undefined;
    }
  };

  /**
   * Gets an AGC|TPX error code from a response error if there is one.
   *
   * @param {string} response
   * @returns string | undefined if errorCode is not present
   */
  _exports.getHttpStatusErrorCode = getHttpStatusErrorCode;
  const getErrorType = response => {
    try {
      return response?.jqXHR?.responseJSON?.errorCode;
    } catch {
      return undefined;
    }
  };
  _exports.getErrorType = getErrorType;
});