define("discourse/plugins/discourse-global-communities/discourse/routes/user-activity-helpful", ["exports", "discourse/routes/user-activity-stream"], function (_exports, _userActivityStream) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class UserActivityHelpful extends _userActivityStream.default {
    userActionType = 101;
    noContentHelpKey = "discourse_gc.helpful.no_helpful";
  }
  _exports.default = UserActivityHelpful;
});