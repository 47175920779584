define("discourse/plugins/discourse-global-communities/discourse/components/post-intent-pill", ["exports", "@glimmer/component", "discourse-i18n", "@ember/component", "@ember/template-factory"], function (_exports, _component, _discourseI18n, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class PostIntentPill extends _component.default {
    get linkHref() {
      return `/post-intent/${this.args.postIntent}`;
    }
    get translated() {
      return (0, _discourseI18n.i18n)(`${this.args.postIntent}_title`);
    }
    get classNames() {
      return `post-intent-pill post-intent-${this.args.postIntent.replace(/\s+/g, "-").toLowerCase()}`;
    }
    static #_ = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <a class={{this.classNames}} href={{this.linkHref}}>
          {{this.translated}}
        </a>
      
    */
    {
      "id": "+ueKMPMi",
      "block": "[[[1,\"\\n    \"],[10,3],[15,0,[30,0,[\"classNames\"]]],[15,6,[30,0,[\"linkHref\"]]],[12],[1,\"\\n      \"],[1,[30,0,[\"translated\"]]],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[],false,[]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-global-communities/discourse/components/post-intent-pill.js",
      "isStrictMode": true
    }), this))();
  }
  _exports.default = PostIntentPill;
});