define("discourse/plugins/discourse-global-communities/discourse/lib/helpers/csv-parse", ["exports", "discourse/plugins/discourse-global-communities/discourse/lib/helpers/admin/bulk-admin-actions/constants/bulk-admin-constants"], function (_exports, _bulkAdminConstants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.bulkAdminListToJSON = void 0;
  _exports.cleanCSVData = cleanCSVData;
  _exports.stringToList = stringToList;
  _exports.trimQuotes = trimQuotes;
  /**
   *
   * This function takes in 2d array of csvEntries of csv, parses out the header then converts each entry/csventry into json
   *
   * @param {2d array of string} csvEntries
   * @param {ember appEvent object} appEvent
   *
   * @returns {[{
      rawCsv: "tranwil,01-01-2024",
      username: "tranwil",
      suspendUntilDate: '',
      ErrorMessage: '01-01-2024 date format yyyy-mm-dd',
      parsedSuccessfully: true,
    }]}
   */
  const bulkAdminListToJSON = (csvEntries, appEvents) => {
    const result = [];
    const headers = csvEntries[_bulkAdminConstants.HEADER_LINE_IDX].split(",");
    csvEntries.slice(_bulkAdminConstants.HEADER_LINE_IDX + 1).forEach((entry, entryIdx) => {
      const userAction = {};
      if (!entry || entry.trim() === "") {
        return;
      }
      _bulkAdminParseEntry(entry, userAction, headers, appEvents, entryIdx);
      result.push(userAction);
    });
    return result;
  };

  /**
   *
   * @param {string} entry
   * @param {string} userAction
   * @param {list} headers
   * @param {appEvent} appEvents
   * @returns {true false} on success/failure
   */
  _exports.bulkAdminListToJSON = bulkAdminListToJSON;
  function _bulkAdminParseEntry(entry, userAction, headers, appEvents, entryIdx) {
    const entryWords = stringToList(entry);
    userAction[_bulkAdminConstants.BULK_ADMIN_ACTION_PARSED_KEY_MAP.ERROR_MESSAGE] = "";
    userAction[_bulkAdminConstants.BULK_ADMIN_ACTION_PARSED_KEY_MAP.PARSED_SUCCESSFULLY] = true;
    entryWords.forEach((word, columnIdx) => {
      userAction[_bulkAdminConstants.BULK_ADMIN_ACTION_PARSED_KEY_MAP.RAW_CSV] = entry;
      userAction[headers[columnIdx].trim()] = word;
      if (entryWords.length !== headers.length) {
        userAction[_bulkAdminConstants.BULK_ADMIN_ACTION_PARSED_KEY_MAP.ERROR_MESSAGE] += `Mismatch column count on row ${entryIdx}. Expected ${headers.length}, got ${word.length}`;
        userAction[_bulkAdminConstants.BULK_ADMIN_ACTION_PARSED_KEY_MAP.PARSED_SUCCESSFULLY] = false;
        throw new Error(`incorrect columns in row ${entryIdx + 1}. Check for extra quotes.`);
      }
    });
  }
  function cleanCSVData(csv) {
    if (csv.includes("\r\n")) {
      return csv.split("\r\n");
    } else {
      return csv.split("\n");
    }
  }

  // Splits string by commas except ones in quotes and removes quotes
  function stringToList(line) {
    return trimQuotes(line.split(/,(?=(?:(?:[^"]*"){2})*[^"]*$)/));
  }
  function trimQuotes(list) {
    return list.map(element => {
      return element.replace(/^"+|"+$/g, "");
    });
  }
});