define("discourse/plugins/discourse-global-communities/discourse/routes/tv-tag", ["exports", "discourse/lib/ajax", "discourse/routes/discourse"], function (_exports, _ajax, _discourse) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class TvTag extends _discourse.default {
    model(params) {
      return (0, _ajax.ajax)(`/tv/${params.tag}.json`);
    }
    titleToken() {
      return this.controller.title;
    }
    setupController(controller, model) {
      super.setupController(controller, model);
      window.scrollTo(0, 0);
    }
  }
  _exports.default = TvTag;
});