define("discourse/plugins/discourse-global-communities/discourse/components/sidebar-custom-widget", ["exports", "discourse/components/cook-text", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _cookText, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const SidebarCustomWidget = (0, _component.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
    <div class="homepage-hero-cell sidebar-custom-widget">
      <h3 class="cell-title">{{@widgetData.title}}</h3>
  
      <div class="cell-content">
        <CookText @rawText={{@widgetData.content}} />
      </div>
    </div>
  
  */
  {
    "id": "h981f8ir",
    "block": "[[[1,\"\\n  \"],[10,0],[14,0,\"homepage-hero-cell sidebar-custom-widget\"],[12],[1,\"\\n    \"],[10,\"h3\"],[14,0,\"cell-title\"],[12],[1,[30,1,[\"title\"]]],[13],[1,\"\\n\\n    \"],[10,0],[14,0,\"cell-content\"],[12],[1,\"\\n      \"],[8,[32,0],null,[[\"@rawText\"],[[30,1,[\"content\"]]]],null],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[\"@widgetData\"],false,[]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-global-communities/discourse/components/sidebar-custom-widget.js",
    "scope": () => [_cookText.default],
    "isStrictMode": true
  }), (0, _templateOnly.default)(undefined, "sidebar-custom-widget:SidebarCustomWidget"));
  var _default = _exports.default = SidebarCustomWidget;
});