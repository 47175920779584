define("discourse/plugins/discourse-global-communities/discourse/lib/helpers/admin/bulk-admin-actions/conduct-suspend-user", ["exports", "rsvp", "discourse/plugins/discourse-global-communities/discourse/lib/helpers/bulk-admin-helpers", "discourse/plugins/discourse-global-communities/discourse/lib/helpers/users/get-user-by-username", "discourse/plugins/discourse-global-communities/discourse/lib/helpers/admin/users/suspend-user", "discourse/plugins/discourse-global-communities/discourse/lib/helpers/admin/bulk-admin-actions/constants/bulk-admin-constants"], function (_exports, _rsvp, _bulkAdminHelpers, _getUserByUsername, _suspendUser, _bulkAdminConstants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.conductSuspendUser = void 0;
  /**
   * Method to bulk call suspendUser API from a given CSV file
   *
   * interface Args {
   *   errorMessage: string;
   *   parsedSuccessfully: boolean;
   *   username: string;
   *   suspendDate: string;
   *   suspensionReason: string;
   *   rawCSV: string;
   * }
   *
   * interface Response {
   *   successCount: number;
   *   failureCount: number;
   *   failureList: String[]; - for rendering on screen
   *   failureListCsv: String[]; - for creating failure file
   * }
   * @param {Args} parsedCsvFile
   * @returns {Response}
   */
  const conductSuspendUser = async parsedCsvFile => {
    const bulkSuspendUserResults = await parsedCsvFile.reduce(async (prev, entry, index) => {
      const rowNumber = (0, _bulkAdminHelpers.getRowIteratorOffset)(index);
      const result = await prev;
      if (!entry.parsedSuccessfully) {
        result.failureCount++;
        result.failureList.push((0, _bulkAdminHelpers.getRowMessageFromError)(rowNumber, entry.rawCSV, entry.errorMessage));
        result.failureListCsv.push((0, _bulkAdminHelpers.getCsvRowWithErrorColumn)(entry.rawCSV, rowNumber, entry.errorMessage));
        return result;
      }

      // Validate username and get user model
      let userToSuspend;
      try {
        userToSuspend = await (0, _getUserByUsername.getUserByUsername)(entry.username);
      } catch {
        result.failureCount++;
        result.failureList.push((0, _bulkAdminHelpers.getRowMessageFromError)(rowNumber, entry.rawCSV, _bulkAdminConstants.BULK_ADMIN_USER_NOT_FOUND_ERROR));
        result.failureListCsv.push((0, _bulkAdminHelpers.getCsvRowWithErrorColumn)(entry.rawCSV, rowNumber, _bulkAdminConstants.BULK_ADMIN_USER_NOT_FOUND_ERROR));
        return result;
      }
      try {
        const suspendUserResult = await (0, _suspendUser.suspendUser)(userToSuspend.user.id, entry.suspendDate, entry.suspensionReason);
        if (suspendUserResult) {
          result.successCount++;
        }
      } catch (error) {
        result.failureCount++;
        result.failureList.push((0, _bulkAdminHelpers.getRowMessageFromError)(rowNumber, entry.rawCSV, error));
        result.failureListCsv.push((0, _bulkAdminHelpers.getCsvRowWithErrorColumn)(entry.rawCSV, rowNumber, error));
        return result;
      }
      return result;
    }, _rsvp.Promise.resolve({
      successCount: 0,
      failureCount: 0,
      failureList: [],
      failureListCsv: []
    }));
    return bulkSuspendUserResults;
  };
  _exports.conductSuspendUser = conductSuspendUser;
});