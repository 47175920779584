define("discourse/plugins/discourse-global-communities/discourse/connectors/discovery-list-controls-above/post-intent-heading", ["exports", "@glimmer/component", "@ember/service", "discourse-i18n", "@ember/component", "@ember/template-factory"], function (_exports, _component, _service, _discourseI18n, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class PostIntentHeading extends _component.default {
    static shouldRender(args, context, owner) {
      const router = owner.lookup("service:router");
      const routeName = router.currentRouteName;
      return routeName.includes("post-intent");
    }
    static #_ = (() => dt7948.g(this.prototype, "router", [_service.service]))();
    #router = (() => (dt7948.i(this, "router"), void 0))();
    get postIntentTranslation() {
      const currentPath = this.router.currentURL;
      const postIntent = currentPath.split("/post-intent/")[1]; // get the post-intent from the URL
      return `${postIntent}_title`;
    }
    static #_2 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <div class="custom-post-intent-wrapper">
          <h1>
            {{i18n "agc.post_intent_header"}}:
            {{i18n this.postIntentTranslation}}
          </h1>
        </div>
      
    */
    {
      "id": "VPagUUHA",
      "block": "[[[1,\"\\n    \"],[10,0],[14,0,\"custom-post-intent-wrapper\"],[12],[1,\"\\n      \"],[10,\"h1\"],[12],[1,\"\\n        \"],[1,[28,[32,0],[\"agc.post_intent_header\"],null]],[1,\":\\n        \"],[1,[28,[32,0],[[30,0,[\"postIntentTranslation\"]]],null]],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[],false,[]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-global-communities/discourse/connectors/discovery-list-controls-above/post-intent-heading.js",
      "scope": () => [_discourseI18n.i18n],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = PostIntentHeading;
});