define("discourse/plugins/discourse-global-communities/discourse/connectors/homepage-topic-list-navigation/default-feed-dropdown", ["exports", "discourse/models/user", "discourse/plugins/discourse-global-communities/discourse/lib/helpers/is-homepage"], function (_exports, _user, _isHomepage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    setupComponent(args, component) {
      component.reopen({
        async _render() {
          if (_shouldRender()) {
            this.set("shouldRender", true);
          }
        }
      });
      function _shouldRender() {
        const router = component.store.register.lookup("service:router");
        const siteSettings = component.store.register.lookup("service:site-settings");
        const isFeatureEnabled = siteSettings.enable_default_feed_sort;
        const defaultFeedSortGroups = siteSettings.enable_default_feed_sort_groups?.split("|") || [];
        const isUserAuthorizedToView = _user.default.current().groups?.some(group => defaultFeedSortGroups.includes(group.name));
        return (0, _isHomepage.isHomepage)(router) && (isFeatureEnabled || isUserAuthorizedToView);
      }
      this._render();
    }
  };
});