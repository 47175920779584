define("discourse/plugins/discourse-global-communities/discourse/lib/helpers/admin/users/update-user-attributes", ["exports", "discourse/lib/ajax", "discourse/plugins/discourse-global-communities/discourse/helpers/config/communityStage", "discourse/plugins/discourse-global-communities/discourse/lib/helpers/users/get-user-by-username", "discourse/plugins/discourse-global-communities/discourse/lib/helpers/admin/bulk-admin-actions/constants/bulk-admin-constants", "discourse/plugins/discourse-global-communities/discourse/lib/helpers/admin/users/constants/user-attributes"], function (_exports, _ajax, _communityStage, _getUserByUsername, _bulkAdminConstants, _userAttributes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.updateUserAttributes = void 0;
  /**
   * Updates a user's attribute(s). If after the update, any of the affected attributes have been nulled, the update will
   * be reverted.
   *
   * An attribute will be nulled if it has a limited set of valid values (i.e. drop down fields e.g. location based fields).
   *
   * @param community supports {@link IGNITE_COMMUNITY} or {@link UPSHIFT_COMMUNITY}.
   * @param stage supports {@link STAGING_STAGE} or {@link PROD_STAGE}. 'gamma' will fallback to {@link STAGING_STAGE}.
   * @param username
   * @param userAttributes
   * @returns {Promise<*>}
   */
  const updateUserAttributes = async (community, stage, username, userAttributes) => {
    if (!community || !stage || !username || !userAttributes) {
      throw new Error("Community, stage, username and userAttributes are required.");
    }

    // TODO: remove this stage fallback once Upshift migration has completed
    if (stage === _communityStage.GAMMA_STAGE) {
      stage = _communityStage.STAGING_STAGE;
    }
    const communityUserFields = _userAttributes.communityToUserFieldsMap[community]?.[stage];
    if (!communityUserFields) {
      throw new Error(`Community stage [${community} ${stage}] is not supported.`);
    }
    let currentUser;
    try {
      currentUser = await (0, _getUserByUsername.getUserByUsername)(username);
    } catch {
      throw new Error(`${_bulkAdminConstants.BULK_ADMIN_USER_NOT_FOUND_ERROR}`);
    }
    const discourseFields = mapUserAttributesToDiscourseFields(userAttributes, communityUserFields);
    const currentUserFields = currentUser.user.user_fields;
    const updatedUser = await updateUser(username, discourseFields);
    const updatedUserFields = updatedUser.user.user_fields;
    const erroneousFields = checkForErroneousFields(discourseFields, currentUserFields, updatedUserFields);
    if (Object.keys(erroneousFields).length > 0) {
      await updateUser(username, currentUserFields);
      throw new Error(`User [${username}] was not updated, the following values are erroneous:${erroneousFields.map(field => ` ${getKeyByValue(communityUserFields, parseInt(field, 10))}: "${discourseFields[field]}"`)}`);
    }
    return updatedUser;
  };

  /**
   * Maps userAttribute names (e.g. businessName) to the position of that userAttribute in Discourse's user_fields array (e.g. 4).
   *
   * @param userAttributes
   * @param communityUserFields
   * @returns {{}}
   */
  _exports.updateUserAttributes = updateUserAttributes;
  const mapUserAttributesToDiscourseFields = (userAttributes, communityUserFields) => {
    const discourseFields = {};
    Object.keys(userAttributes).forEach(userAttributeName => {
      const discourseField = communityUserFields[userAttributeName];
      if (discourseField === undefined) {
        throw new Error(`User attribute [${userAttributeName}] is not supported.`);
      }
      discourseFields[discourseField] = userAttributes[userAttributeName];
    });
    return discourseFields;
  };

  /**
   * Calls Discourse's PUT /u/USERNAME.json endpoint to update a user's profile fields.
   *
   * @param username
   * @param discourseFields
   * @returns {Promise<*>}
   */
  const updateUser = async (username, discourseFields) => {
    const userFieldsQueryString = new URLSearchParams(updateUserFieldKeys(discourseFields)).toString();
    return await (0, _ajax.ajax)(`/u/${username}.json?${userFieldsQueryString}`, {
      contentType: "application/json",
      dataType: "json",
      type: "PUT"
    });
  };

  /**
   * Updates every key in the userFields map "X" to "user_fields[X]", which is the format needed by Discourse's API.
   *
   * @param userFields
   * @returns {{[p: string]: unknown}}
   */
  const updateUserFieldKeys = userFields => {
    return Object.fromEntries(Object.entries(userFields).map(_ref => {
      let [k, v] = _ref;
      return [`user_fields[${k}]`, v];
    }));
  };

  /**
   * Checks updatedUserFields to see if any existing field in oldUserFields was nulled or mismatch, and returns any erroneous fields.
   * A field will be nulled if the field has a limited set of valid values (i.e. drop down fields e.g. location based fields).
   *
   * @param discourseFields
   * @param oldUserFields
   * @param updatedUserFields
   * @returns {*[]}
   */
  const checkForErroneousFields = (discourseFields, oldUserFields, updatedUserFields) => {
    const erroneousFields = [];
    const affectedFields = Object.keys(discourseFields);
    affectedFields.forEach(affectedField => {
      if (oldUserFields[affectedField] !== null && updatedUserFields[affectedField] === null || discourseFields[affectedField] !== updatedUserFields[affectedField]) {
        erroneousFields.push(affectedField);
      }
    });
    return erroneousFields;
  };

  /**
   * Returns the key of a value in an object.
   *
   * @param object
   * @param value
   * @returns {string}
   */
  const getKeyByValue = (object, value) => {
    return Object.keys(object).find(key => object[key] === value);
  };
});