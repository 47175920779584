define("discourse/plugins/discourse-global-communities/discourse/lib/helpers/admin/users/constants/user-attributes", ["exports", "discourse/plugins/discourse-global-communities/discourse/helpers/config/communityStage"], function (_exports, _communityStage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.communityToUserFieldsMap = void 0;
  /**
   * To find the user fields for any Discourse instance, you can visit the Data Explorer on the instance at
   * `/admin/plugins/explorer` and run the query:
   *
   * select * from user_fields
   *
   */

  const igniteUserFields = {
    [_communityStage.STAGING_STAGE]: {
      dspSince: 1,
      stationLocation: 2,
      station: 3,
      businessName: 4,
      firstName: 6,
      lastName: 7,
      shortCode: 8,
      stationRegion: 9,
      country: 10
    },
    [_communityStage.PROD_STAGE]: {
      dspSince: 1,
      stationLocation: 2,
      station: 3,
      businessName: 4,
      firstName: 6,
      lastName: 7,
      shortCode: 8,
      stationRegion: 9,
      country: 10
    }
  };
  const upshiftUserFields = {
    [_communityStage.STAGING_STAGE]: {
      preferredName: 3,
      businessName: 4,
      cityState: 5,
      domicile: 6,
      scac: 7,
      afpSince: 8,
      pronouns: 9
      // happyToHelpWith: 10, // this is a "multiselect" (array) field, which is not supported yet
    },
    [_communityStage.PROD_STAGE]: {
      preferredName: 1,
      businessName: 2,
      cityState: 3,
      domicile: 4,
      scac: 5,
      afpSince: 6,
      pronouns: 7
      // happyToHelpWith: 8, // this is a "multiselect" (array) field, which is not supported yet
    }
  };
  const communityToUserFieldsMap = _exports.communityToUserFieldsMap = {
    [_communityStage.IGNITE_COMMUNITY]: igniteUserFields,
    [_communityStage.UPSHIFT_COMMUNITY]: upshiftUserFields
  };
});