define("discourse/plugins/discourse-global-communities/discourse/components/idf-timer", ["exports", "@ember/component", "@ember/runloop", "@ember-decorators/component", "@ember/template-factory"], function (_exports, _component, _runloop, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    
  */
  {
    "id": "hH7IBOu9",
    "block": "[[],[],false,[]]",
    "moduleName": "discourse/plugins/discourse-global-communities/discourse/components/idf-timer.hbs",
    "isStrictMode": false
  });
  const IdfTimer = dt7948.c(class IdfTimer extends _component.default {
    delay = 950;
    didInsertElement() {
      super.didInsertElement(...arguments);
      this.tick();
    }
    tick() {
      this.set("nextTick", (0, _runloop.later)(this, () => {
        this.tick();
        this.onTick();
      }, this.delay));
    }
    willDestroyElement() {
      super.willDestroyElement(...arguments);
      (0, _runloop.cancel)(this.nextTick);
    }
  }, [(0, _component2.tagName)("")]);
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, IdfTimer);
});