define("discourse/plugins/discourse-global-communities/discourse/config/escalation-form-config", ["exports", "discourse/plugins/discourse-global-communities/discourse/helpers/config/communityStage"], function (_exports, _communityStage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.ESCALATION_CONFIG = _exports.AA_LINKS = void 0;
  /*
   * Config values for AGC Escalations
   */

  const ESCALATION_TAG = "escalated";
  const SEVERITY_OPTIONS = [{
    id: "WATCHING",
    name: "Watching"
  }, {
    id: "DEEP_DIVE",
    name: "Deep dive"
  }, {
    id: "RESPONSE_REQUIRED",
    name: "Response required"
  }];
  const SUMMARY_TEXTAREA_CHAR_MIN_LIMIT = 1;
  const SUMMARY_TEXTAREA_CHAR_MAX_LIMIT = 10000;
  const IGNITE_ESCALATION_FORM_CONFIG = {
    smeLabel: "SME owner",
    leaderLabel: "L8 leader",
    severityOptions: SEVERITY_OPTIONS,
    escalationTag: ESCALATION_TAG,
    summaryMinLength: SUMMARY_TEXTAREA_CHAR_MIN_LIMIT,
    summaryMaxLength: SUMMARY_TEXTAREA_CHAR_MAX_LIMIT
  };
  const UPSHIFT_ESCALATION_FORM_CONFIG = {
    smeLabel: "SME POC",
    leaderLabel: "AFP leader",
    severityOptions: SEVERITY_OPTIONS,
    escalationTag: ESCALATION_TAG,
    summaryMinLength: SUMMARY_TEXTAREA_CHAR_MIN_LIMIT,
    summaryMaxLength: SUMMARY_TEXTAREA_CHAR_MAX_LIMIT
  };
  const ESCALATION_CONFIG = _exports.ESCALATION_CONFIG = {
    [_communityStage.IGNITE_COMMUNITY]: IGNITE_ESCALATION_FORM_CONFIG,
    [_communityStage.UPSHIFT_COMMUNITY]: UPSHIFT_ESCALATION_FORM_CONFIG
  };
  const STAGING_AA_LINK = "https://approvalstage.amazon.com/Approval/Edit/";
  const PROD_AA_LINK = "https://approvals.amazon.com/Approval/Edit/";
  const AA_LINKS = _exports.AA_LINKS = {
    staging: STAGING_AA_LINK,
    prod: PROD_AA_LINK
  };
});