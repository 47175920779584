define("discourse/plugins/discourse-global-communities/discourse/feature-flags/agc-change-ownership", ["exports", "discourse/models/composer"], function (_exports, _composer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AgcChangeOwnershipFeatureFlag {
    static enabledKey = "agc_change_ownership_enabled";
    static className = "agc-change-ownership-enabled";
    static afterEnabled() {
      _composer.default.serializeOnCreate("agc_change_ownership_user_id", "agcChangeOwnershipUserId");
      _composer.default.serializeOnCreate("agc_change_ownership_admin_id", "agcChangeOwnershipAdminId");
    }
  }
  _exports.default = AgcChangeOwnershipFeatureFlag;
});