define("discourse/plugins/discourse-global-communities/discourse/routes/idf-library", ["exports", "@ember/runloop", "discourse/lib/ajax", "discourse/routes/discourse"], function (_exports, _runloop, _ajax, _discourse) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class IdfLibrary extends _discourse.default {
    queryParams = {
      category: {
        refreshModel: true
      },
      type: {
        refreshModel: true
      },
      topic: {
        refreshModel: true
      }
    };
    model(params) {
      return (0, _ajax.ajax)("/idf/library.json", {
        data: params
      });
    }
    setupController(controller, model) {
      super.setupController(controller, model);
      controller.setProperties({
        searchTerm: controller.term,
        loadingModel: false
      });
    }
    activate() {
      (0, _runloop.schedule)("afterRender", () => document.documentElement.classList.add("idf", "events-library"));
    }
    deactivate() {
      document.documentElement.classList.remove("idf", "events-library");
    }
  }
  _exports.default = IdfLibrary;
});