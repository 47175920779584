define("discourse/plugins/discourse-global-communities/discourse/lib/helpers/admin/users/suspend-user", ["exports", "discourse/lib/ajax"], function (_exports, _ajax) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.suspendUser = void 0;
  /**
   * Suspends a user.
   *
   * @param userId
   * @param suspendUntil date of the format YYYY-MM-DD.
   * @param reason
   * @param message
   * @returns {Promise<*>} Suspension details, including information on who executed the suspension.
   */
  const suspendUser = async (userId, suspendUntil, reason, message) => {
    if (!userId || !suspendUntil || !reason) {
      throw new Error("UserId, suspendUntil, and reason are required.");
    }
    if (!/^\d{4}-\d{2}-\d{2}$/.test(suspendUntil)) {
      throw new Error(`SuspendUntil must be of the format YYYY-MM-DD.`);
    }
    const body = {
      suspend_until: suspendUntil,
      reason,
      post_action: "delete"
    };
    if (message) {
      body.message = message;
    }
    return await (0, _ajax.ajax)(`/admin/users/${userId}/suspend.json`, {
      contentType: "application/json",
      dataType: "json",
      type: "PUT",
      data: JSON.stringify(body)
    });
  };
  _exports.suspendUser = suspendUser;
});