define("discourse/plugins/discourse-global-communities/discourse/components/agc-escalation-note", ["exports", "@glimmer/component", "@glimmer/tracking", "@ember/helper", "@ember/object", "@ember/service", "@ember/template", "discourse/components/cook-text", "discourse/components/d-button", "discourse/components/user-link", "discourse/helpers/avatar", "discourse/helpers/concat-class", "discourse/lib/ajax", "discourse-i18n", "discourse/plugins/discourse-global-communities/discourse/components/agc-escalation-form", "@ember/component", "@ember/template-factory"], function (_exports, _component, _tracking, _helper, _object, _service, _template, _cookText, _dButton, _userLink, _avatar, _concatClass, _ajax, _discourseI18n, _agcEscalationForm, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AgcEscalationNote extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "currentUser", [_service.service]))();
    #currentUser = (() => (dt7948.i(this, "currentUser"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "editing", [_tracking.tracked], function () {
      return false;
    }))();
    #editing = (() => (dt7948.i(this, "editing"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "text", [_tracking.tracked], function () {
      return this.args.note.text;
    }))();
    #text = (() => (dt7948.i(this, "text"), void 0))();
    get showControls() {
      return this.currentUser.admin && !this.editing && !this.args.note.automated;
    }
    startEditing() {
      this.editing = true;
    }
    static #_4 = (() => dt7948.n(this.prototype, "startEditing", [_object.action]))();
    cancelEdit() {
      this.editing = false;
    }
    static #_5 = (() => dt7948.n(this.prototype, "cancelEdit", [_object.action]))();
    async saveEdit(text) {
      const data = {
        escalation_note: {
          text
        }
      };
      const response = await (0, _ajax.ajax)(`/admin/plugins/agc/escalations/${this.args.note.id}.json`, {
        type: "POST",
        data
      });
      this.editing = false;
      this.text = response.escalation_note.text;
    }
    static #_6 = (() => dt7948.n(this.prototype, "saveEdit", [_object.action]))();
    static #_7 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <div
          data-id={{@note.id}}
          class={{concatClass "escalations__note" (if this.editing "-editing")}}
        >
          <UserLink @user={{@note.user}}>{{avatar
              @note.user
              imageSize="small"
            }}</UserLink>
          <div class="escalations__note__text">
            {{#if this.editing}}
              <AgcEscalationForm
                @text={{this.text}}
                @cancel={{this.cancelEdit}}
                @submit={{this.saveEdit}}
              />
            {{else}}
              <div class="escalations__note__text__html">
                {{#if @note.automated}}
                  {{htmlSafe
                    (i18n
                      "agc.escalations.automated"
                      name=@note.user.name
                      href=@note.text
                    )
                  }}
                {{else}}
                  <CookText @rawText={{this.text}} />
                {{/if}}
                <div class="escalations__note__text__date">
                  {{@note.updated_at}}
                </div>
              </div>
            {{/if}}
          </div>
    
          {{#if this.showControls}}
            <div class="escalations__note__controls">
              <DButton
                class="btn-flat escalations__note__edit-btn"
                @icon="pencil"
                @action={{this.startEditing}}
              />
    
              <DButton
                class="btn-flat escalations__note__delete-btn"
                @icon="trash-can"
                @action={{fn @deleteNote @note}}
              />
            </div>
          {{/if}}
        </div>
      
    */
    {
      "id": "49zmvQg+",
      "block": "[[[1,\"\\n    \"],[10,0],[15,\"data-id\",[30,1,[\"id\"]]],[15,0,[28,[32,0],[\"escalations__note\",[52,[30,0,[\"editing\"]],\"-editing\"]],null]],[12],[1,\"\\n      \"],[8,[32,1],null,[[\"@user\"],[[30,1,[\"user\"]]]],[[\"default\"],[[[[1,[28,[32,2],[[30,1,[\"user\"]]],[[\"imageSize\"],[\"small\"]]]]],[]]]]],[1,\"\\n      \"],[10,0],[14,0,\"escalations__note__text\"],[12],[1,\"\\n\"],[41,[30,0,[\"editing\"]],[[[1,\"          \"],[8,[32,3],null,[[\"@text\",\"@cancel\",\"@submit\"],[[30,0,[\"text\"]],[30,0,[\"cancelEdit\"]],[30,0,[\"saveEdit\"]]]],null],[1,\"\\n\"]],[]],[[[1,\"          \"],[10,0],[14,0,\"escalations__note__text__html\"],[12],[1,\"\\n\"],[41,[30,1,[\"automated\"]],[[[1,\"              \"],[1,[28,[32,4],[[28,[32,5],[\"agc.escalations.automated\"],[[\"name\",\"href\"],[[30,1,[\"user\",\"name\"]],[30,1,[\"text\"]]]]]],null]],[1,\"\\n\"]],[]],[[[1,\"              \"],[8,[32,6],null,[[\"@rawText\"],[[30,0,[\"text\"]]]],null],[1,\"\\n\"]],[]]],[1,\"            \"],[10,0],[14,0,\"escalations__note__text__date\"],[12],[1,\"\\n              \"],[1,[30,1,[\"updated_at\"]]],[1,\"\\n            \"],[13],[1,\"\\n          \"],[13],[1,\"\\n\"]],[]]],[1,\"      \"],[13],[1,\"\\n\\n\"],[41,[30,0,[\"showControls\"]],[[[1,\"        \"],[10,0],[14,0,\"escalations__note__controls\"],[12],[1,\"\\n          \"],[8,[32,7],[[24,0,\"btn-flat escalations__note__edit-btn\"]],[[\"@icon\",\"@action\"],[\"pencil\",[30,0,[\"startEditing\"]]]],null],[1,\"\\n\\n          \"],[8,[32,7],[[24,0,\"btn-flat escalations__note__delete-btn\"]],[[\"@icon\",\"@action\"],[\"trash-can\",[28,[32,8],[[30,2],[30,1]],null]]],null],[1,\"\\n        \"],[13],[1,\"\\n\"]],[]],null],[1,\"    \"],[13],[1,\"\\n  \"]],[\"@note\",\"@deleteNote\"],false,[\"if\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-global-communities/discourse/components/agc-escalation-note.js",
      "scope": () => [_concatClass.default, _userLink.default, _avatar.default, _agcEscalationForm.default, _template.htmlSafe, _discourseI18n.i18n, _cookText.default, _dButton.default, _helper.fn],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = AgcEscalationNote;
});