define("discourse/plugins/discourse-global-communities/discourse/lib/escalations/create-escalation", ["exports", "discourse/lib/ajax"], function (_exports, _ajax) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.createEscalation = void 0;
  /**
   * POST to the OMS CreateEscalation endpoint through the Discourse proxy to create an Amazon Approval escalation.
   * https://code.amazon.com/packages/OceanMediaServiceModel/blobs/mainline/--/model/operations/v1/escalations/createEscalation.smithy
   *
   * @param topicId
   * @param severity
   * @param focusArea
   * @param sme
   * @param leader
   * @param summary
   * @returns {Promise<{approvalId: (number|null)}>}
   */
  const createEscalation = async (topicId, _ref) => {
    let {
      severity,
      focusArea,
      sme,
      leader,
      summary
    } = _ref;
    if (!topicId || !severity || !focusArea || !sme || !leader || !summary) {
      throw new Error("Escalation data topicId, severity, focusArea, sme, leader and summary are required.");
    }
    const body = {
      escalationData: {
        topicId: parseInt(topicId, 10),
        severity,
        focusArea,
        // TODO: Update API Model request params to the more generic 'sme' and 'leader'
        //       https://sim.amazon.com/issues/AGC-5734
        smeOwner: sme,
        l8Leader: leader,
        summary
      }
    };
    return (0, _ajax.ajax)("/proxy/media/escalations.json", {
      contentType: "application/json",
      dataType: "json",
      type: "POST",
      data: JSON.stringify(body),
      timeout: 30000,
      // This prevents Discourse's ajax utility from swallowing the timeout exception
      ignoreUnsent: false
    });
  };
  _exports.createEscalation = createEscalation;
});