define("discourse/plugins/discourse-global-communities/discourse/lib/analytics-collector", ["exports", "jquery", "rsvp", "discourse/lib/ajax", "discourse/lib/decorators", "discourse/lib/get-owner", "discourse/lib/url", "discourse/models/composer", "discourse/plugins/discourse-global-communities/discourse/lib/agc-user-session"], function (_exports, _jquery, _rsvp, _ajax, _decorators, _getOwner, _url, _composer, _agcUserSession) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.registerClickAnalyticsDefinition = registerClickAnalyticsDefinition;
  _exports.resetClickAnalyticsDefinitions = resetClickAnalyticsDefinitions;
  // we do not want to be holding on to a lot of events locally -- too volatile
  const QUEUED_EVENT_THRESHOLD = 50;
  const MEDIA_EVENTS = ["discourse-media:video:play", "discourse-media:video:pause", "discourse-media:video:ended", "discourse-media:video:timeupdate", "discourse-media:video:seeked", "discourse-media:video:enterpictureinpicture", "discourse-media:video:leavepictureinpicture", "discourse-media:video:fullscreenchange", "discourse-media:audio:play", "discourse-media:audio:pause", "discourse-media:audio:ended", "discourse-media:audio:timeupdate"];
  const COMPOSER_EVENTS = ["composer:opened", "composer:closed", "composer:created-post", "composer:edited-post", "composer:saved"];
  const CUSTOM_AGC_EVENTS = ["agc-custom:enhanced_notifications_btn_click", "agc-custom:improved_search_active_tab", "agc-custom:improved_search_active_filters", "agc-custom:improved_search_filter_click", "agc-custom:improved_search_pagination", "agc-custom:improved_search_no_results", "agc-custom:improved_search_error", "agc-custom:go_to_dsptv_plus", "agc-custom:agc-recommended-topics-loaded", "agc-custom:agc-wywa-topics-loaded", "agc-custom:wywa_carousel_loaded", "agc-custom:wywa_carousel_next", "agc-custom:wywa_carousel_previous", "agc-custom:wywa_carousel_topic_liked", "agc-custom:wywa_carousel_topic_followed", "agc-custom:default_feed_dropdown_open", "agc-custom:feed_sort_click", "agc-custom:homepage_loaded_topics", "agc-custom:push-notification-opened", "agc-custom:browser-notification-opened", "agc-custom:notification-card-clicked", "agc-custom:user_notify_toggle_click", "agc-custom:browser_notification_parent_toggled", "agc-custom:composer_toolbar_button_clicked", "agc-custom:composer_question_for_dsp_toggled", "agc-custom:composer_post_creation_timer", "agc-custom:filter-select", "agc-custom:search_result_view", "agc-custom:search_result_click", "agc-custom:POST_EXIT_FROM_SEARCH", "agc-custom:ai_summarization_button_clicked"];
  const INTERSTITIALS_EVENTS = ["discourse-interstitial:close_by_close_button", "discourse-interstitial:close_by_cancel_button", "discourse-interstitial:close_by_click_out", "discourse-interstitial:close_by_esc_key", "discourse-interstitial:primary_cta_click", "discourse-interstitial:secondary_cta_click", "discourse-interstitial:seen"];

  // unix timestamp (seconds since epoch)
  const getTime = () => Math.round(new Date().getTime() / 1000);
  let clickAnalyticsDefinitions = [];
  function registerClickAnalyticsDefinition(definition) {
    clickAnalyticsDefinitions.push(definition);
  }
  function resetClickAnalyticsDefinitions() {
    clickAnalyticsDefinitions = [];
  }
  class AnalyticsCollector {
    #appRootElement;
    _interceptClick = this.#interceptClick.bind(this);
    constructor(opts) {
      this._pluginApi = opts.pluginApi;
      this._site = opts.site;
      this._siteSettings = opts.siteSettings;
      this._currentUrl = null;
      this._previousUrl = null;
      this._router = opts.router;
      this.#appRootElement = opts.appRootElement;
      this._agcSeeMoreTracking = opts.agcSeeMoreTracking;
      if (!this._queuedEvents()) {
        this._flushQueuedEvents();
      }
      let now = getTime();
      this._lastFlush = now;
      window.addEventListener("flushAnalytics", this._flush);
    }
    startTracking() {
      return new _rsvp.Promise(resolve => {
        this._bindAppEvents();
        this._bindBeacon();
        (0, _jquery.default)(document).ready(() => {
          this._bindClickEvents();
          resolve(this);
        });
      });
    }
    stopTracking() {
      this._unbindClickEvents();
    }

    // If the user logs in or refreshes the page we want to send a POST
    // request immediately to capture the event
    logPageLoad() {
      // A page view should have already been queued up via _bindAppEvents(),
      // but just in case log one here.
      if (this._queuedEvents().length === 0) {
        let referrer = document.referrer;

        // If referrer is not set look in the query params for `?referrer_url=X` and set!
        if (!referrer && window.location.search.length) {
          const searchParams = new URLSearchParams(window.location.search);
          referrer = searchParams.get("referrer_url");
        }
        this._logPageViewEvent(`${window.location.pathname}${window.location.search}`, referrer);
      }
      this._flush();
    }
    _logPageViewEvent(url, referrer) {
      this._previousUrl = referrer || this._currentUrl;
      this._currentUrl = url;
      const eventData = localStorage.lastAgcAnalyticsEventData ? JSON.parse(localStorage.lastAgcAnalyticsEventData) : null;
      this._logDitchedMedia();
      this._logEvent("page_view", {
        clickRef: localStorage.lastAgcAnalyticsRef || this._entryPointFromUrl(url),
        pageType: localStorage.lastAgcAnalyticsPageType
      }, eventData);

      // important to clean this up, otherwise other page_view events
      // that are not related to a [data-agc-analytics-ref] click event
      // will be misattributed
      delete localStorage.lastAgcAnalyticsRef;
      delete localStorage.lastAgcAnalyticsPageType;
      delete localStorage.lastAgcAnalyticsEventData;
    }
    _prepareVisibilityOrUnloadEventData() {
      const isComposerOpened = (0, _getOwner.getOwnerWithFallback)(this).lookup("service:composer")?.get("model.composeState") === _composer.default.OPEN;
      const isMediaPlaying = ["audio", "video"].some(mediaType => {
        const mediaElements = document.querySelectorAll(mediaType);
        return Array.from(mediaElements).some(v => !v.paused);
      });
      return {
        isComposerOpened,
        isMediaPlaying
      };
    }
    _logPageBeforeUnloadEvent() {
      const eventData = this._prepareVisibilityOrUnloadEventData();
      this._logEvent("page_unloading", {
        clickRef: localStorage.lastAgcAnalyticsRef || this._entryPointFromUrl(this._currentUrl),
        pageType: localStorage.lastAgcAnalyticsPageType
      }, eventData);
    }
    _logPageVisibilityChangeEvent(visibilityState) {
      if (visibilityState === "hidden" && this._queuedEvents().find(v => v.event_type === "page_unloading")) {
        // if the visibilityState is hidden and we have already logged a page_unloading event
        // we can skip logging visibilitychange event
        return;
      }
      const eventData = this._prepareVisibilityOrUnloadEventData();
      this._logEvent("page_visibilitychange", {}, {
        ...eventData,
        visibilityState
      });
    }
    _logAppStateChangedEvent(data) {
      this._logEvent("app_state_changed", {}, data);
    }
    _trackDitchedMedia() {
      const data = [];
      ["audio", "video"].forEach(mediaType => {
        const mediaElements = document.querySelectorAll(mediaType);
        const ditchedMedia = Array.from(mediaElements).filter(v => !v.paused);
        ditchedMedia.forEach(mediaElement => {
          // from discourse-media-events
          const src = mediaElement.currentSrc;
          const filename = src.substring(src.lastIndexOf("/") + 1);
          const currentTime = mediaElement.currentTime;
          const postElement = mediaElement.closest("article");
          let postId = null;
          let topicId = null;
          if (postElement) {
            if (postElement.dataset.postId) {
              postId = Number(postElement.dataset.postId);
            }
            if (postElement.dataset.topicId) {
              topicId = Number(postElement.dataset.topicId);
            }
          }
          data.push({
            currentUrl: this._currentUrl,
            previousUrl: this._previousUrl,
            mediaType,
            filename,
            src,
            currentTime,
            postId,
            topicId
          });
        });
      });
      if (data.length > 0) {
        sessionStorage.setItem("lastAgcDitchedMedia", JSON.stringify(data));
      } else {
        sessionStorage.removeItem("lastAgcDitchedMedia");
      }
    }
    _logDitchedMedia() {
      const trackedData = sessionStorage.getItem("lastAgcDitchedMedia");
      if (trackedData) {
        JSON.parse(trackedData).forEach(_ref => {
          let {
            mediaType,
            currentUrl,
            previousUrl,
            ...eventData
          } = _ref;
          this._logEvent(`${mediaType}_ditched`, {}, eventData, false, {
            currentUrl,
            previousUrl
          });
        });

        // clean the ditched media data after the event is logged
        sessionStorage.removeItem("lastAgcDitchedMedia");
      }
    }
    _bindAppEvents() {
      var _this = this;
      this._pluginApi.onPageChange(url => {
        this._logPageViewEvent(url);
        this._agcSeeMoreTracking.flush();
      });
      this._pluginApi.onAppEvent("agc-analytics:log-page-view", url => {
        this._logPageViewEvent(url || `${window.location.pathname}${window.location.search}`);
      });

      // we need to track the media elements that are playing when the user navigates away from the page
      // before the current page is changed and the new page rendered, otherwise the media elements will be lost
      // by the time the page:changed event is fired
      this._router.on("routeWillChange", () => {
        this._trackDitchedMedia();
      });
      ["agc-analytics:log-event", "enhanced_user_analytics:event"].forEach(appEventName => {
        this._pluginApi.onAppEvent(appEventName, function (eventType, eventData) {
          let clickData = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
          if (!eventType || typeof eventType !== "string") {
            throw new Error(`${appEventName} requires an eventType`);
          }
          _this._logEvent(eventType, {
            clickRef: localStorage.lastAgcAnalyticsRef || _this._entryPointFromUrl(_this._currentUrl),
            pageType: localStorage.lastAgcAnalyticsPageType,
            ...clickData
          }, eventData || (localStorage.lastAgcAnalyticsEventData ? JSON.parse(localStorage.lastAgcAnalyticsEventData) : null));

          // important to clean this up, otherwise other events that are not related to a
          // [data-agc-analytics-ref] click event will be misattributed
          delete localStorage.lastAgcAnalyticsRef;
          delete localStorage.lastAgcAnalyticsPageType;
          delete localStorage.lastAgcAnalyticsEventData;
        });
      });
      MEDIA_EVENTS.forEach(mediaAppEvent => {
        const analyticsEvent = mediaAppEvent.replace("discourse-media:", "").replace(":", "_");
        this._pluginApi.onAppEvent(mediaAppEvent, data => this._logEvent(analyticsEvent, {}, data, false));
      });
      COMPOSER_EVENTS.forEach(composerEvent => {
        let analyticsEvent = composerEvent.replace(":", "_").replace("-", "_");
        this._pluginApi.onAppEvent(composerEvent, () => {
          const clickRef = localStorage.lastAgcAnalyticsRef;
          const clickData = {};
          if (clickRef) {
            clickData.clickRef = clickRef;
            delete localStorage.lastAgcAnalyticsRef;
          }
          this._logEvent(analyticsEvent, clickData);
        });
      });
      CUSTOM_AGC_EVENTS.forEach(eventType => {
        this._pluginApi.onAppEvent(eventType, data => {
          const clickRef = localStorage.lastAgcAnalyticsRef;
          const clickData = {};
          if (clickRef) {
            clickData.clickRef = clickRef;
            delete localStorage.lastAgcAnalyticsRef;
          }
          this._logEvent(eventType, clickData, data);
        });
      });
      INTERSTITIALS_EVENTS.forEach(eventType => {
        this._pluginApi.onAppEvent(eventType, data => {
          window.localStorage.lastAgcAnalyticsRef = data.link;
          const clickRef = localStorage.lastAgcAnalyticsRef;
          const clickData = {};
          if (clickRef) {
            clickData.clickRef = clickRef;
            delete localStorage.lastAgcAnalyticsRef;
          }
          this._logEvent(eventType, clickData, data);
        });
      });
      this._pluginApi.onAppEvent("draft:destroyed", draftKey => {
        this._logEvent("composer_draft_destroyed", {}, {
          draft_key: draftKey
        });
      });
      this._pluginApi.onAppEvent("page:compose-reply", topic => {
        this._logEvent("composer_replying", {}, {
          topic_id: topic.id
        });
      });
      this._pluginApi.onAppEvent("post-stream:filter-replies", data => {
        this._logEvent("filtered_replies", {}, data);
      });
      this._pluginApi.onAppEvent("post-stream:filter-upwards", data => {
        this._logEvent("filtered_upwards", {}, data);
      });
      this._pluginApi.onAppEvent("post-stream:filter-show-all", data => {
        this._logEvent("filtered_show_all", {}, data);
      });
      this._pluginApi.onAppEvent("post-stream:gap-expanded", data => {
        this._logEvent("filtered_gap_expanded", {}, data);
      });
      this._pluginApi.onAppEvent("category-experts:endorse-clicked", data => {
        this._logEvent("endorse_clicked", {}, data);
      });
      this._pluginApi.onAppEvent("category-experts:endorsement-given", data => {
        this._logEvent("endorse_given", {}, data);
      });
      this._pluginApi.onAppEvent("topic:timings-sent", data => {
        const agcUserSession = _agcUserSession.default.current();
        const timings = {};
        timings[data.topic_id] = data.timings;
        (0, _ajax.ajax)("/agc-timings.json", {
          data: {
            agc_transit_id: agcUserSession._transitId(),
            agc_session_id: agcUserSession._sessionId(),
            timings
          },
          type: "POST",
          cache: false
        }).then(response => {
          _agcUserSession.default.current().updateIds(response.session_id, response.transit_id);
        });
      });
      this._pluginApi.onAppEvent("page:changed", data => {
        const currentUser = this._pluginApi.getCurrentUser();
        if (typeof window.ReactNativeWebView !== "undefined") {
          window.ReactNativeWebView.postMessage(JSON.stringify({
            discourse_event: "page_changed",
            user_id: currentUser?.id,
            url: data.url,
            title: data.title
          }));
        }
      });
      document.addEventListener("fullscreenchange", () => {
        if (typeof window.ReactNativeWebView !== "undefined") {
          let eventName;
          if (document.fullscreenElement) {
            eventName = "fullscreen_entered";
          } else {
            eventName = "fullscreen_left";
          }
          window.ReactNativeWebView.postMessage(JSON.stringify({
            discourse_event: eventName
          }));
        }
      });

      // Tell react native that we are loaded, since all appEvents are bound
      if (typeof window.ReactNativeWebView !== "undefined") {
        window.ReactNativeWebView.postMessage(JSON.stringify({
          discourseLoaded: true
        }));
      }
    }
    _sendBeacon() {
      if (this._queuedEvents().length > 0) {
        const agcUserSession = _agcUserSession.default.current();
        navigator.sendBeacon("/analytics-beacon.json", JSON.stringify({
          stream: this._queuedEvents(),
          agc_transit_id: agcUserSession._transitId(),
          agc_session_id: agcUserSession._sessionId()
        }));
        this._flushQueuedEvents();
      }
    }
    _bindBeacon() {
      if (!navigator.sendBeacon) {
        return;
      }
      window.addEventListener("visibilitychange", () => {
        const visibilityState = document.visibilityState;
        this._logPageVisibilityChangeEvent(visibilityState);
        if (visibilityState === "hidden") {
          this._trackDitchedMedia();
          this._logDitchedMedia();
          this._sendBeacon();
        }
      });
      window.addEventListener("pagehide", () => {
        this._trackDitchedMedia();
        this._logDitchedMedia();
        this._sendBeacon();
      });
      window.addEventListener("popstate", () => {
        this._logPageViewEvent(`${window.location.pathname}${window.location.search}`);
        this._sendBeacon();
      });
      window.addEventListener("beforeunload", () => {
        this._logPageBeforeUnloadEvent();
        this._sendBeacon();
        this._agcSeeMoreTracking.flush();
      });
      window.analyticsAppStateChange = data => {
        this._logAppStateChangedEvent(data);
        this._sendBeacon();
      };
    }
    _pageTypeFromUrl(url, previousUrl) {
      if (url === "" || url === null) {
        return "unknown";
      }

      // remove query string
      url = url.replace(/\?.+$/, "");
      if (url === "/") {
        return "home";
      }
      if (url.startsWith("/u/")) {
        return "user";
      } else if (url.startsWith("/c/")) {
        return "category";
      } else if (url.startsWith("/tag/")) {
        return "tag";
      } else if (url.startsWith("/t/")) {
        const topicController = (0, _getOwner.getOwnerWithFallback)(this).lookup("controller:topic");
        if (topicController?.get("model.isPrivateMessage")) {
          return "private-message";
        } else {
          return "topic";
        }
      } else if (url.startsWith("/admin/")) {
        return "admin";
      } else if (url.startsWith("/search")) {
        return "search";
      } else if (url.startsWith("/g") || url.startsWith("/groups")) {
        return "group";
      } else if (url.startsWith("/circles") || url.startsWith("/connect")) {
        return "circle";
      } else if (url.startsWith("/chat")) {
        return "chat";
      } else if (url.startsWith("/badges")) {
        if (url === "/badges" || url === "/badges/") {
          return "badges";
        } else if (previousUrl.startsWith("/u/")) {
          return "user";
        } else {
          return "badge";
        }
      } else if (url.split("/").length === 2) {
        return url.replace("/", "");
      }
      return "unknown";
    }
    _entryPointFromUrl(url) {
      const result = url?.match(/(\?.+)$/);
      if (result) {
        const key = this._siteSettings.agc_marketing_tool_query_string_key;
        const urlParams = new URLSearchParams(result[1]);
        if (urlParams.has(key) && urlParams.get(key)) {
          return urlParams.get(key).toLowerCase();
        }
      }
      return null;
    }
    _modifyEventData(pageType, eventData, url) {
      switch (pageType) {
        case "group":
          {
            if (!eventData || !eventData.group_name) {
              const group = this._site.groups.find(g => this._currentUrl.startsWith(`/g/${g.name}`));
              if (group) {
                eventData = eventData || {};
                eventData.group_name = group.name;
              }
            }
            break;
          }
        case "topic":
          {
            if (!eventData || !eventData.topic_id) {
              const match = _url.TOPIC_URL_REGEXP.exec(url);
              const topicId = match ? match[2] : null;
              if (topicId) {
                eventData = eventData || {};
                eventData.topic_id = parseInt(topicId, 10);
              }
            }
            break;
          }
      }
      return eventData;
    }
    #interceptClick(event) {
      const dataAgcAnalyticsEventCollector = {
        selector: "[data-agc-analytics-event]",
        analyticsDef: this._logDataAgcAnalyticsEvent.bind(this)
      };
      const dataAgcAnalyticsRefTracker = {
        selector: "[data-agc-analytics-ref]",
        analyticsDef: this._trackDataAgcAnalyticsRef.bind(this)
      };
      const eventTarget = event.target || event.currentTarget;
      [...clickAnalyticsDefinitions, dataAgcAnalyticsEventCollector, dataAgcAnalyticsRefTracker].forEach(_ref2 => {
        let {
          selector,
          analyticsDef
        } = _ref2;
        if (!eventTarget || !selector) {
          return;
        }
        const analyticsTarget = eventTarget.closest(selector);
        if (analyticsTarget) {
          let eventType, eventData, clickData, clickRef, pageType;
          if (typeof analyticsDef === "function") {
            analyticsDef(analyticsTarget);
            return;
          }
          if (typeof analyticsDef.eventType === "function") {
            eventType = analyticsDef.eventType(analyticsTarget);
          } else if (typeof analyticsDef.eventType === "string") {
            eventType = analyticsDef.eventType;
          }
          if (!eventType) {
            return;
          }
          if (typeof analyticsDef.ref === "function") {
            clickRef = analyticsDef.ref(analyticsTarget);
          } else if (typeof analyticsDef.ref === "string") {
            clickRef = analyticsDef.ref;
          }
          if (typeof analyticsDef.pageType === "function") {
            pageType = analyticsDef.pageType(analyticsTarget);
          } else if (typeof analyticsDef.pageType === "string") {
            pageType = analyticsDef.pageType;
          }
          if (typeof analyticsDef.eventData === "function") {
            eventData = analyticsDef.eventData(analyticsTarget);
          } else if (typeof analyticsDef.eventData === "object") {
            eventData = analyticsDef.eventData;
          }
          if (typeof analyticsDef.clickData === "function") {
            clickData = analyticsDef.clickData(analyticsTarget);
          } else if (typeof analyticsDef.clickData === "object") {
            clickData = analyticsDef.clickData;
          } else {
            clickData = {};
          }
          this._logEvent(eventType, {
            clickRef,
            pageType,
            ...clickData
          }, eventData);
        }
      });
    }
    _logDataAgcAnalyticsEvent(target) {
      const eventType = target.dataset["agcAnalyticsEvent"];
      if (eventType) {
        const ref = target.dataset["agcAnalyticsRef"];
        const pageType = target.dataset["agcAnalyticsPageType"];
        const eventData = target.dataset["agcAnalyticsEventData"];
        this._logEvent(eventType, ref ? {
          clickRef: ref,
          pageType
        } : {}, eventData ? JSON.parse(eventData) : null);
      }
    }
    _trackDataAgcAnalyticsRef(target) {
      const eventType = target.dataset["agcAnalyticsEvent"];
      const ref = target.dataset["agcAnalyticsRef"];
      if (!eventType && ref) {
        localStorage.lastAgcAnalyticsRef = ref.replace(/-/g, "_");
        const pageType = target.dataset["agcAnalyticsPageType"];
        if (pageType) {
          localStorage.lastAgcAnalyticsPageType = pageType;
        } else {
          delete localStorage.lastAgcAnalyticsPageType;
        }
        const eventData = target.dataset["agcAnalyticsEventData"];
        if (eventData) {
          localStorage.lastAgcAnalyticsEventData = eventData;
        } else {
          delete localStorage.lastAgcAnalyticsEventData;
        }
      }
    }
    _bindClickEvents() {
      this._decorateLinksWithRefs();
      document.querySelector(this.#appRootElement).addEventListener("click", this._interceptClick);
    }
    _unbindClickEvents() {
      document.querySelector(this.#appRootElement).removeEventListener("click", this._interceptClick);
    }
    _decorateLinksWithRefs() {
      (0, _jquery.default)("#site-logo").parent("a").attr("data-agc-analytics-ref", "nav_site_logo");
    }
    _queuedEvents() {
      return JSON.parse(sessionStorage.getItem("agcAnalyticsEventQueue")) || [];
    }
    _queueEvent(event) {
      let queuedEvents = this._queuedEvents();
      queuedEvents.push(event);
      sessionStorage.setItem("agcAnalyticsEventQueue", JSON.stringify(queuedEvents));
    }
    _filterQueuedEvents(condition) {
      const queuedEvents = this._queuedEvents().filter(condition);
      sessionStorage.setItem("agcAnalyticsEventQueue", JSON.stringify(queuedEvents));
    }
    _flushQueuedEvents(time) {
      let events = [];
      if (time) {
        events = this._queuedEvents().filter(e => e.timestamp > time);
      }
      sessionStorage.setItem("agcAnalyticsEventQueue", JSON.stringify(events));
    }
    _logEvent(eventType) {
      let clickData = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      let eventData = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : null;
      let modifyEventData = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : true;
      let opts = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : {};
      const previousUrl = opts.previousUrl || this._previousUrl || "";
      const currentUrl = opts.currentUrl || this._currentUrl || window.location.pathname;
      const pageType = clickData.pageType || this._pageTypeFromUrl(currentUrl, previousUrl);
      if (modifyEventData) {
        eventData = this._modifyEventData(pageType, eventData, currentUrl);
      }
      const coreData = {
        url: currentUrl,
        referrer_url: previousUrl,
        event_type: eventType,
        event_data: eventData,
        timestamp: getTime(),
        click_ref: clickData.clickRef,
        page_type: pageType
      };
      this._queueEvent(coreData);

      // may want to flush every X seconds and not only
      // when events are received, unless we have already
      // collected a lot of events on the client
      const tooManyEvents = this._queuedEvents().length >= QUEUED_EVENT_THRESHOLD;
      const tooLongSinceLastEvent = getTime() - this._lastFlush >= this._siteSettings.agc_analytics_event_ship_interval;
      if (tooLongSinceLastEvent || tooManyEvents) {
        this._flush();
      }
    }
    _flush() {
      let queuedEvents = this._queuedEvents();
      if (!queuedEvents) {
        return;
      }
      const beginFlush = getTime();
      const agcUserSession = _agcUserSession.default.current();
      this._lastFlush = beginFlush;
      agcUserSession.sessionIds().then(data => {
        (0, _ajax.ajax)("/analytics.json", {
          type: "POST",
          dataType: "json",
          contentType: "application/json",
          data: JSON.stringify({
            stream: queuedEvents,
            agc_transit_id: data.transitId,
            agc_session_id: data.sessionId
          })
        }).then(response => {
          agcUserSession.updateIds(response.session_id, response.transit_id);
          this._flushQueuedEvents(beginFlush);
        });
      });
    }
    static #_ = (() => dt7948.n(this.prototype, "_flush", [_decorators.bind]))();
  }
  _exports.default = AnalyticsCollector;
});