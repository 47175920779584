define("discourse/plugins/discourse-global-communities/discourse/connectors/user-menu-items-list-empty-state/no-notifications-empty-state", ["exports", "discourse/components/user-menu/notifications-list"], function (_exports, _notificationsList) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    shouldRender(outletArgs, container) {
      const isNotificationsList = outletArgs.model.constructor === _notificationsList.default;
      const isFeatureEnabled = container.currentUser.agcFeatureFlags["enhanced_notifications_enabled"];
      return isNotificationsList && isFeatureEnabled;
    }
  };
});