define("discourse/plugins/discourse-global-communities/discourse/services/agc-post-intent", ["exports", "@glimmer/tracking", "@ember/service"], function (_exports, _tracking, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AgcPostIntentService extends _service.default {
    static #_ = (() => dt7948.g(this.prototype, "postIntentValidation", [_tracking.tracked]))();
    #postIntentValidation = (() => (dt7948.i(this, "postIntentValidation"), void 0))();
  }
  _exports.default = AgcPostIntentService;
});