define("discourse/plugins/discourse-global-communities/discourse/templates/tv/index", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="tv-index">
    {{#if this.model.featured_topic}}
      {{tv-hero model=this.model.featured_topic}}
    {{/if}}
  
    {{#each this.sections as |section|}}
      <div class="section">
        <div class="section-header">
          <h2 class="section-title">{{section.label}}</h2>
          {{#if section.showSeeAll}}
            <a class="see-all" href={{section.seeAllHref}}>{{i18n
                "agc.tv.see_all"
              }}</a>
          {{/if}}
        </div>
  
        <Agc::Carousel
          @items={{section.topics}}
          @tileType="tv"
          @tilesPerPage={{4}}
          @registerAnalytics={{false}}
        />
      </div>
    {{/each}}
  </div>
  */
  {
    "id": "8Ijz0PV0",
    "block": "[[[10,0],[14,0,\"tv-index\"],[12],[1,\"\\n\"],[41,[30,0,[\"model\",\"featured_topic\"]],[[[1,\"    \"],[1,[28,[35,2],null,[[\"model\"],[[30,0,[\"model\",\"featured_topic\"]]]]]],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[42,[28,[37,4],[[28,[37,4],[[30,0,[\"sections\"]]],null]],null],null,[[[1,\"    \"],[10,0],[14,0,\"section\"],[12],[1,\"\\n      \"],[10,0],[14,0,\"section-header\"],[12],[1,\"\\n        \"],[10,\"h2\"],[14,0,\"section-title\"],[12],[1,[30,1,[\"label\"]]],[13],[1,\"\\n\"],[41,[30,1,[\"showSeeAll\"]],[[[1,\"          \"],[10,3],[14,0,\"see-all\"],[15,6,[30,1,[\"seeAllHref\"]]],[12],[1,[28,[35,7],[\"agc.tv.see_all\"],null]],[13],[1,\"\\n\"]],[]],null],[1,\"      \"],[13],[1,\"\\n\\n      \"],[8,[39,8],null,[[\"@items\",\"@tileType\",\"@tilesPerPage\",\"@registerAnalytics\"],[[30,1,[\"topics\"]],\"tv\",4,false]],null],[1,\"\\n    \"],[13],[1,\"\\n\"]],[1]],null],[13]],[\"section\"],false,[\"div\",\"if\",\"tv-hero\",\"each\",\"-track-array\",\"h2\",\"a\",\"i18n\",\"agc/carousel\"]]",
    "moduleName": "discourse/plugins/discourse-global-communities/discourse/templates/tv/index.hbs",
    "isStrictMode": false
  });
});