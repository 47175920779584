define("discourse/plugins/discourse-global-communities/discourse/connectors/topic-navigation-bottom/desktop-suggested-posts", ["exports", "discourse/models/user"], function (_exports, _user) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    setupComponent() {
      this.reopen({
        async _render() {
          const currentUser = _user.default.current();
          const suggestedPostsGroups = this.siteSettings.suggested_posts_groups?.split("|") || [];
          const isUserInSuggestedPostsGroups = currentUser.groups.some(group => suggestedPostsGroups.includes(group.name));
          this.set("shouldRender", !this.site.mobileView && isUserInSuggestedPostsGroups);
        }
      });
      this._render();
    }
  };
});