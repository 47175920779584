define("discourse/plugins/discourse-global-communities/discourse/templates/connectors/admin-users-list-show-before/bulk-admin-actions", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if this.shouldRender}}
    <div
      class="bulk-admin-toggler"
      role="button"
      {{action "toggleShowBulkAdminActions"}}
    >
      <div class="chevron-svg {{if this.shouldRotateSvg 'rotate'}}"></div>
      {{#if this.showBulkAdminActions}}
        <h2>Hide bulk admin actions</h2>
      {{else}}
        <h2>Show bulk admin actions</h2>
      {{/if}}
    </div>
  
    <div
      class="bulk-admin-actions-container
        {{if this.showBulkAdminActions 'visible'}}"
    >
      <h3>Select an action *</h3>
      {{agc-radio-group
        options=this.options
        choice=this.currentAdminAction
        updateChoice=this.updateChoice
      }}
  
      {{#if this.currentAdminAction}}
        {{bulk-admin-csv
          actionType=this.currentAdminAction
          parseSucceed=this.parseSucceed
          clearFile=this.clearFile
        }}
      {{/if}}
      {{#if this.actionInProgress}}
        <div class="spinner"></div>
      {{/if}}
    </div>
    {{#if this.showBulkAdminResults}}
      {{agc-admin-result-content
        actionSucceed=this.actionSucceed
        parseSucceed=this.parseSucceed
        bulkAdminResults=this.bulkAdminResults
        closeBulkAdminResults=this.closeBulkAdminResults
        currentAdminAction=this.bulkAdminResults.action
        showBulkAdminResults=this.showBulkAdminResults
        CSVInputHeaders=this.CSVInputHeaders
      }}
    {{/if}}
  {{/if}}
  */
  {
    "id": "Ur98lhgp",
    "block": "[[[41,[30,0,[\"shouldRender\"]],[[[1,\"  \"],[11,0],[24,0,\"bulk-admin-toggler\"],[24,\"role\",\"button\"],[4,[38,2],[[30,0],\"toggleShowBulkAdminActions\"],null],[12],[1,\"\\n    \"],[10,0],[15,0,[29,[\"chevron-svg \",[52,[30,0,[\"shouldRotateSvg\"]],\"rotate\"]]]],[12],[13],[1,\"\\n\"],[41,[30,0,[\"showBulkAdminActions\"]],[[[1,\"      \"],[10,\"h2\"],[12],[1,\"Hide bulk admin actions\"],[13],[1,\"\\n\"]],[]],[[[1,\"      \"],[10,\"h2\"],[12],[1,\"Show bulk admin actions\"],[13],[1,\"\\n\"]],[]]],[1,\"  \"],[13],[1,\"\\n\\n  \"],[10,0],[15,0,[29,[\"bulk-admin-actions-container\\n      \",[52,[30,0,[\"showBulkAdminActions\"]],\"visible\"]]]],[12],[1,\"\\n    \"],[10,\"h3\"],[12],[1,\"Select an action *\"],[13],[1,\"\\n    \"],[1,[28,[35,5],null,[[\"options\",\"choice\",\"updateChoice\"],[[30,0,[\"options\"]],[30,0,[\"currentAdminAction\"]],[30,0,[\"updateChoice\"]]]]]],[1,\"\\n\\n\"],[41,[30,0,[\"currentAdminAction\"]],[[[1,\"      \"],[1,[28,[35,6],null,[[\"actionType\",\"parseSucceed\",\"clearFile\"],[[30,0,[\"currentAdminAction\"]],[30,0,[\"parseSucceed\"]],[30,0,[\"clearFile\"]]]]]],[1,\"\\n\"]],[]],null],[41,[30,0,[\"actionInProgress\"]],[[[1,\"      \"],[10,0],[14,0,\"spinner\"],[12],[13],[1,\"\\n\"]],[]],null],[1,\"  \"],[13],[1,\"\\n\"],[41,[30,0,[\"showBulkAdminResults\"]],[[[1,\"    \"],[1,[28,[35,7],null,[[\"actionSucceed\",\"parseSucceed\",\"bulkAdminResults\",\"closeBulkAdminResults\",\"currentAdminAction\",\"showBulkAdminResults\",\"CSVInputHeaders\"],[[30,0,[\"actionSucceed\"]],[30,0,[\"parseSucceed\"]],[30,0,[\"bulkAdminResults\"]],[30,0,[\"closeBulkAdminResults\"]],[30,0,[\"bulkAdminResults\",\"action\"]],[30,0,[\"showBulkAdminResults\"]],[30,0,[\"CSVInputHeaders\"]]]]]],[1,\"\\n\"]],[]],null]],[]],null]],[],false,[\"if\",\"div\",\"action\",\"h2\",\"h3\",\"agc-radio-group\",\"bulk-admin-csv\",\"agc-admin-result-content\"]]",
    "moduleName": "discourse/plugins/discourse-global-communities/discourse/templates/connectors/admin-users-list-show-before/bulk-admin-actions.hbs",
    "isStrictMode": false
  });
});